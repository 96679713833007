export default function GetWeekNumber(date) {
    // Kopiere das Datum und setze es auf den nächsten Donnerstag (da die ISO-Woche am Montag beginnt und Donnerstag in der Mitte liegt)
    let target = new Date(date.valueOf());
    let dayNr = (date.getDay() + 6) % 7; // Sonntage sind der 7. Tag der Woche nach ISO, daher müssen wir +6 % 7 hinzufügen
    target.setDate(target.getDate() - dayNr + 3);

    // Berechne den ersten Donnerstag des Jahres
    let firstThursday = new Date(target.getFullYear(), 0, 4);
    dayNr = (firstThursday.getDay() + 6) % 7;
    firstThursday.setDate(firstThursday.getDate() - dayNr + 3);

    // Berechne die Kalenderwoche
    let weekNumber = 1 + Math.round(((target.getTime() - firstThursday.getTime()) / 86400000 - 3 + (firstThursday.getDay() + 6) % 7) / 7);

    return weekNumber;
}