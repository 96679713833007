import { Alert, Button, ButtonGroup, InputLabel, Snackbar, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState } from "react";
import "./SelectedShiftSummary.css";
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";
import axios from "axios";

function SelectedShiftSummary(props) {

    const [isFirstRender, setIsFirstRender] = useState(true);

    const [isManualPauseEditingActivated, setIsManualPauseEditingActivated] = useState(props.shiftInformation.contains_this_many_minutes_of_manual_pause_time !== 0);
    const [manualPauseTime, setManualPauseTime] = useState(Number(props.shiftInformation.contains_this_many_minutes_of_manual_pause_time));

    const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

    const [workTime] = useState(props.shiftInformation.duration_working);
    const [pauseTime] = useState(props.shiftInformation.duration_in_pause);

    useEffect(() => {
        if (!isFirstRender)
            setIsSaveButtonVisible(true);
        else
            setIsFirstRender(false);
    }, [manualPauseTime]);

    useEffect(() => {
        if (localStorage.getItem('updateSuccess') === 'true') {
            setIsSuccessMessageVisible(true);
            localStorage.removeItem('updateSuccess');
        }
    }, []);

    function createWorkingTime() {
        if (workTime !== null)
            return workTime.hours + ":" + timeFormatCheck(workTime.minutes) + "h";
        else
            return "??:??";
    }

    function createPauseTime() {
        if (pauseTime !== null)
            return pauseTime.hours + ":" + timeFormatCheck(pauseTime.minutes) + "h";
        else
            return "??:??";
    }

    function timeFormatCheck(number) {
        if (number < 10)
            return "0" + number;
        else
            return number;
    }

    const decreasePauseTime = () => {
        if (manualPauseTime > 0) {
            setManualPauseTime(prev => prev - 1);
        }
    };

    const increasePauseTime = () => {
        setManualPauseTime(prev => prev + 1);
    };

    const saveManualPauseChanging = (event) => {
        event.preventDefault();
        
        let formattedManualPauseTime = "PT" + manualPauseTime + "m";

        axios.put('shift/informationAttachments/additionalPauseDuration/update', {
            "shift_begin_activity_id" : new URLSearchParams(window.location.search).get("beginId"),
            "additional_pause_duration" : formattedManualPauseTime,
        }).then(() => {
            localStorage.setItem('updateSuccess', 'true');
            window.location.reload();
        }).catch(() => {
            setIsErrorMessageVisible(true);
        })
    }

    function createSnackBarFeedback() {
        return (
            <>
                <Snackbar open={isErrorMessageVisible} autoHideDuration={6000} onClose={() => setIsErrorMessageVisible(false)}>
                    <Alert onClose={() => setIsErrorMessageVisible(false)} severity="error" sx={{ width: '100%' }}>
                        Deine Änderungen konnten nicht gespeichert werden!
                    </Alert>
                </Snackbar>
                <Snackbar open={isSuccessMessageVisible} autoHideDuration={6000} onClose={() => setIsSuccessMessageVisible(false)}>
                    <Alert onClose={() => setIsSuccessMessageVisible(false)} severity="success" sx={{ width: '100%' }}>
                        Deine Änderungen wurden erfolgreich gespeichert!
                    </Alert>
                </Snackbar>
            </>
        )
    }

    return (
        <div className="shift-summary-container">
            <div className="card text-center">
            <div className="card-header">
                Zusammenfassung
            </div>
            <div className="card-body">
                <div className="summary-information-container">
                    <img className="icon" src={process.env.PUBLIC_URL + '/icons/timer-green.png'} alt="Arbeitszeit"/>
                    <p>Arbeitszeit: {createWorkingTime()}</p>
                </div>
                <div className="summary-information-container">
                    <img className="icon" src={process.env.PUBLIC_URL + '/icons/timer-orange.png'} alt="Pausenzeit"/>
                    <p>Pausenzeit: {createPauseTime()}</p>
                </div>
                {isManualPauseEditingActivated && <div>
                    <InputLabel>Davon manuelle Pausenzeit (in Minuten)</InputLabel>
                        <ButtonGroup>
                            <Button
                                onClick={decreasePauseTime}
                                disabled={manualPauseTime === 0}
                            >
                                <RemoveIcon fontSize="small" />
                            </Button>
                            <TextField size="small" onChange={(event) => setManualPauseTime(event.target.value)} value={manualPauseTime} sx={{width: "60px"}}/>
                            <Button onClick={increasePauseTime}>
                                <AddIcon fontSize="small" />
                            </Button>
                        </ButtonGroup>
                </div>}
                {!isManualPauseEditingActivated && <PrimaryButton disabled={props.shiftInformation.shift_status === "not_completed" || props.shiftInformation.shift_status === "edited_not_completed"} text="Manuelle Pausenzeit nachtragen" onClick={() => setIsManualPauseEditingActivated(true)}/>}
                {isSaveButtonVisible && <div className="save-manual-time-button">
                    <PrimaryButton text="Speichern" onClick={saveManualPauseChanging} />
                </div>}
                {createSnackBarFeedback()}
            </div>
            </div>
        </div>
    )
}

export default SelectedShiftSummary;