import "./WorktimeModals.css"
import { Alert, InputLabel, Snackbar, TextField } from "@mui/material";
import { Modal } from "react-bootstrap";
import CancelButton from "../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import { useEffect, useState } from "react";
import { CustomProvider, DatePicker } from "rsuite";
import de_DE from 'rsuite/locales/de_DE';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

function WorktimeModals(props) {
    const [correctionTimeHour, setCorrectionTimeHour] = useState("0");    
    const [correctionTimeMinute, setCorrectionTimeMinute] = useState("0");
    const [correctionTimeErrorHour, setCorrectionTimeErrorHour] = useState(false);
    const [correctionTimeErrorMinute, setCorrectionTimeErrorMinute] = useState(false);

    const [startResultValueHour, setStartResultValueHour] = useState("0");
    const [startResultValueMinute, setStartResultValueMinute] = useState("0");
    const [startResultValueErrorHour, setStartResultValueErrorHour] = useState(false);
    const [startResultValueErrorMinute, setStartResultValueErrorMinute] = useState(false)

    const [startResultChangeValueHour, setStartResultChangeValueHour] = useState("0");
    const [startResultChangeValueMinute, setStartResultChangeValueMinute] = useState("0");
    const [startResultChangeValueErrorHour, setStartResultChangeValueErrorHour] = useState(false);
    const [startResultChangeValueErrorMinute, setStartResultChangeValueErrorMinute] = useState(false);


    const [monthlyTimeCreate, setMonthlyTimeCreate] = useState("");
    const [monthlyTimeError, setMonthlyTimeError] = useState(false);

    useEffect(() => {
        setCorrectionTimeHour("0");
        setCorrectionTimeMinute("0");
        setStartResultValueHour("0");
        setStartResultValueMinute("0");
        setStartResultChangeValueHour("0");
        setStartResultChangeValueMinute("0");

        setMonthlyTimeCreate("");
        setCorrectionTimeErrorHour(false);
        setCorrectionTimeErrorMinute(false);
        setStartResultValueErrorHour(false);
        setStartResultValueErrorMinute(false);
        setStartResultChangeValueErrorHour(false);
        setStartResultChangeValueErrorMinute(false);
    }, [props.correctionOpen, props.newAccountOpen])

    const handleCorrectionChange = (e) => {
        const regex = /^[0-9\b]*$/;
        if (regex.test(e.target.value)) {
            if(e.target.id === "correctionTimeHour") setCorrectionTimeHour(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));
            if(e.target.id === "correctionTimeMinute") setCorrectionTimeMinute(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));
            if(e.target.id === "startResultValueMinute") setStartResultValueMinute(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));
            if(e.target.id === "startResultChangeValueMinute") setStartResultChangeValueMinute(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));
        }        
        setCorrectionTimeErrorHour(e.target.value === "");
        setCorrectionTimeErrorMinute(e.target.value === "");

        const regex2 = /^-?[0-9\b]*$/;
        if(regex2.test(e.target.value)){
            if(e.target.id === "startResultValueHour") setStartResultValueHour(e.target.value === "" ? "" : (e.target.value));
            if(e.target.id === "startResultChangeValueHour") setStartResultChangeValueHour(e.target.value === "" ? "" : (e.target.value));
        }
        setStartResultValueErrorHour(e.target.value === "");
        setStartResultValueErrorMinute(e.target.value === "");
    }

    function modalAddCorrection(){
        return (
            <Modal
                show={props.correctionOpen}
                onHide={props.correctionClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Überstunden ausgezahlt hinzufügen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.correctionClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <InputLabel>Ausgezahlte Überstunden eintragen:</InputLabel>
                    <div className="create-timeaccount-add-time">        
                        <TextField 
                            required 
                            value={correctionTimeHour}
                            error={correctionTimeErrorHour}
                            id="correctionTimeHour" 
                            label="Stunden" 
                            helperText={correctionTimeErrorHour ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                        <TextField 
                            required 
                            value={correctionTimeMinute}
                            error={correctionTimeErrorMinute}
                            id="correctionTimeMinute"  
                            label="Minuten" 
                            helperText={correctionTimeErrorMinute ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.correctionClose} />
                    <PrimaryButton text="Hinzufügen" onClick={props.createCorrectionPostRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function modalCloseWorktimeAccount(){
        return (
            <Modal
                show={props.closingShow}
                onHide={props.closingClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Arbeitszeitkonto schließen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.closingClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <CustomProvider locale={de_DE}>
                        <DatePicker required locale="de-DE" format="MMM yyyy" size="lg" placeholder="Endmonat" value={props.closingDatePickerValue} onChange={(selectedDate) => props.closingDatePickerOnChange(selectedDate)} shouldDisableDate={date => isBefore(date, new Date())} oneTap/>
                    </CustomProvider>                    
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.closingClose} />
                    <PrimaryButton text="Arbeitszeitkonto schließen" onClick={props.closeAccountPutRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    const handleMonthlyTimeCreateChange = (e) => {
        const regex = /^\d*,?\d{0,2}$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setMonthlyTimeCreate(e.target.value);
        }
        setMonthlyTimeError(e.target.value === "");
    }

    function modalAddNewWorktimeAccount(){
        let currentDate = new Date();
        let dateTwoYearsInFuture = new Date(currentDate.getFullYear() + 2, 0, 0);        

        return (
            <Modal
                show={props.newAccountOpen}
                onHide={props.newAccountClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Arbeitszeitkonto hinzufügen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.newAccountClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="create-timeaccount-modal">
                        <CustomProvider locale={de_DE}>
                            <DatePicker required id="firstMonthPickerValue" format="MMM yyyy" size="lg" placeholder="Startmonat" value={props.newAccountDatePickerValue} onChange={(value) => props.newAccountDatePickerOnChange(value)} shouldDisableDate={date => isBefore(date, new Date()) || isAfter(date, dateTwoYearsInFuture)} oneTap/>
                        </CustomProvider>                        
                        <TextField 
                            value={monthlyTimeCreate} 
                            required 
                            error={monthlyTimeError} 
                            id="monthlyPlannedWorkTime" 
                            label="monatliche Soll-Zeit (in Std)" 
                            helperText={monthlyTimeError ? "Feld darf nicht leer sein" : false} 
                            onChange={handleMonthlyTimeCreateChange}
                        />
                    </div>
                    <InputLabel>Starttransferstunden eintragen:</InputLabel>
                    <div className="create-timeaccount-add-time">        
                        <TextField 
                            required 
                            value={startResultValueHour}
                            error={startResultValueErrorHour}
                            id="startResultValueHour" 
                            label="Stunden" 
                            helperText={startResultValueErrorHour ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                        <TextField 
                            required 
                            value={startResultValueMinute}
                            error={startResultValueErrorMinute}
                            id="startResultValueMinute"  
                            label="Minuten" 
                            helperText={startResultValueErrorMinute ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.newAccountClose} />
                    <PrimaryButton text="Arbeitszeitkonto eröffnen" onClick={props.newAccountPostRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function modalDeleteWorktimeAccount(){
        return (
            <Modal
                show={props.deleteOpen}
                onHide={props.deleteClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Arbeitszeitkonto löschen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.deleteClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Möchten Sie dieses Arbeitszeitenkonto wirklich löschen?</p>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.deleteClose} />
                    <PrimaryButton text="Arbeitszeitkonto löschen" onClick={props.deleteAccountDeleteRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function modalChangeStartTransferValue(){
        return (
            <Modal
                show={props.changeStartValueShow}
                onHide={props.changeStartValueClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Starttransferstunden ändern</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.changeStartValueClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <InputLabel>Starttransferstunden eintragen:</InputLabel>
                    <div className="create-timeaccount-add-time">        
                        <TextField 
                            required 
                            value={startResultChangeValueHour}
                            error={startResultChangeValueErrorHour}
                            id="startResultChangeValueHour" 
                            label="Stunden" 
                            helperText={startResultChangeValueErrorHour ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                        <TextField 
                            required 
                            value={startResultChangeValueMinute}
                            error={startResultChangeValueErrorMinute}
                            id="startResultChangeValueMinute"  
                            label="Minuten" 
                            helperText={startResultChangeValueErrorMinute ? "Feld darf nicht leer sein" : false} 
                            onChange={handleCorrectionChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.changeStartValueClose} />
                    <PrimaryButton text="Starttransferstunden ändern" onClick={props.changeStartValuePutRequest} />
                </Modal.Footer>
            </Modal>
        )
    }

    function datePickerErrorSnackbar() {
        return (
            <Snackbar open={props.datePickerError} autoHideDuration={6000} onClose={props.datePickerErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={props.datePickerErrorClose} severity="error" sx={{ width: '100%' }}>
                    Es wurde kein Monat ausgewählt.
                </Alert>
            </Snackbar>
        )
    }

    return(
        <>
            {modalAddCorrection()}
            {modalCloseWorktimeAccount()}
            {modalAddNewWorktimeAccount()}
            {modalDeleteWorktimeAccount()}
            {datePickerErrorSnackbar()}
            {modalChangeStartTransferValue()}
        </>
    )
}

export default WorktimeModals;