import "./CreateShiftStepNavigation.css";
import React from "react";
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";

function CreateShiftStepNavigation(props) {
    return (
        <PrimaryButton text="Weiter"  onClick={props.nextOnClick} disabled={props.step === 3}/>
    )
}

export default CreateShiftStepNavigation;