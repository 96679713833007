import { Avatar, ListItemAvatar, ListItem, ListItemButton, ListItemText, Tabs, Tab } from "@mui/material";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import Logout from "../../../../sharedComponents/Logout";
import "./WorkTimeAccount.css";
import { FixedSizeList } from "react-window";
import EventNoteIcon from '@mui/icons-material/EventNote';
import HistoryIcon from '@mui/icons-material/History';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Link } from "react-router-dom";
import PrimaryButton from "../../../../sharedComponents/primaryButton/PrimaryButton";
import formatDuration from "../../../../sharedComponents/FormatDuration";
import parseDuration from "../../../../sharedComponents/ParseDuration";

const monthNames = [
    "Januar", "Februar", "März", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Dezember"
];

function WorkTimeAccount() {

    const [isLoading, setIsLoading] = useState(true);

    const [selectedTab, setSelectedTab] = useState(1);
    
    const [workingTimeAccounts, setWorkingTimeAccounts] = useState(null);
    const [lastYearMonth, setLastYearMonth] = useState(null);
    const [currentYearMonth, setCurrentYearMonth] = useState(null);

    useEffect(() => {
        const currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        let currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const yearMonth = `${currentYear}-${currentMonth}`;

        let lastMonthDate;
        let lastMonth;
        let lastYear = currentYear;
        if (currentMonth === '01') {
            lastYear = currentYear - 1;
            lastMonth = '12';
        } else {
            lastMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
            lastMonth = (lastMonthDate.getMonth() + 1).toString().padStart(2, '0');
        }

        setCurrentYearMonth(yearMonth);
        setLastYearMonth(`${lastYear}-${lastMonth}`);
        getExistingWorkingTimeAccounts(yearMonth);
    }, [])

    function getExistingWorkingTimeAccounts(yearmonth) {
        axios.get(`workingTimeAccount/getAllWhichExistInGivenMonth?yearmonth=${yearmonth}`).then(results => {
            const workingTimeAccountsData = results.data;
            axios.get('employee/getActive').then(resultsData => {
                resultsData.data.forEach(employee => {
                    const matchingAccountIndex = workingTimeAccountsData.findIndex(account => account.employee_id === employee.id);
                    if (matchingAccountIndex !== -1) {
                        workingTimeAccountsData[matchingAccountIndex].first_name = employee.first_name;
                        workingTimeAccountsData[matchingAccountIndex].last_name = employee.last_name;
                    }
                })

                setWorkingTimeAccounts(workingTimeAccountsData);
                setIsLoading(false);
            }).catch(() => { Logout()})
        }).catch(() => { Logout()})
    }

    const changeTab = () => {
        if (selectedTab === 0)
            setSelectedTab(1);
        else
            setSelectedTab(0);
    }

    const handleEmployeeWorkingTimeAccountClick = () => {
        sessionStorage.setItem("currentNavItem", "employees");
        sessionStorage.setItem("emplyoeeViewIndex", "2");
    }

    function generateCurrentMonthEmployeeItems(props) {
        const { index, style } = props;
        const employee = workingTimeAccounts[index];

        let selectedMonth;
        if (selectedTab === 1)
            selectedMonth = employee.months.filter(workingTimeMonth => workingTimeMonth.month === currentYearMonth)[0];
        else if (selectedTab === 0)
            selectedMonth = employee.months.filter(workingTimeMonth => workingTimeMonth.month === lastYearMonth)[0];

        if ((employee.error_not_completed_shift_begin_ids.length === 0) && (employee.error_pause_overflow_shift_begin_ids.length === 0) && (employee.error_unresolved_shift_objection_shift_begin_ids.length === 0)) {
            return (
                <Link to={'/home/employees/employeeView?employeeId=' + employee.employee_id} onClick={handleEmployeeWorkingTimeAccountClick}>
                    <ListItem style={style} key={index} component="div" disablePadding>
                    <ListItemButton>
                        <ListItemAvatar>
                            <Avatar>
                                <PermIdentityIcon />
                            </Avatar>
                        </ListItemAvatar>
                        {generateItemText(selectedMonth, employee)}
                    </ListItemButton>
                    </ListItem>
                </Link> 
            )
        } else {
            return (
                <Link to={'/home/employees/employeeView?employeeId=' + employee.employee_id} onClick={handleEmployeeWorkingTimeAccountClick}>
                    <ListItem style={style} key={index} component="div" disablePadding>
                    <ListItemButton>
                        <ListItemAvatar>
                            <Avatar className="red-background">
                                <PriorityHighIcon />
                            </Avatar>
                        </ListItemAvatar>
                        {generateItemText(selectedMonth, employee)}
                    </ListItemButton>
                    </ListItem>
                </Link> 
            )
        }
    }

    function generateItemText(selectedMonth, employee) {
        if (selectedMonth === undefined) {
            return <ListItemText primary={employee.first_name + " " + employee.last_name} secondary={"Nicht aktiv"} />
        } else {
            let formattedResult = formatDuration(parseDuration(selectedMonth.result));
            return <ListItemText primary={employee.first_name + " " + employee.last_name} secondary={formattedResult} />
            
            // Farben erstmal rausgenommen
            /*if (formattedResult.startsWith("-")) {
                return <ListItemText className="red-list-item" primary={employee.first_name + " " + employee.last_name} secondary={formattedResult} />
            } else if (formattedResult === "00:00 Std") {
                return <ListItemText primary={employee.first_name + " " + employee.last_name} secondary={formattedResult}/>
            } else {
                return <ListItemText className="green-list-item" primary={employee.first_name + " " + employee.last_name} secondary={formattedResult}/>
            }*/
        }
    }

    return isLoading ?
        <LoadingSpinner />
        :
        <div className="work-time-account-container">
            <div className="work-time-account-header">
                <div className="horizontal-header">
                    <h3>Arbeitszeitkonten</h3>
                    <Link to={"worktimeaccounts/"}>
                        <PrimaryButton text="Zur Gesamtübersicht"/>
                    </Link>
                </div>
                <Tabs onChange={changeTab} value={selectedTab} >
                    <Tab icon={<HistoryIcon />} iconPosition="start" label={"Letzter Monat (" + monthNames[new Date().getMonth() - 1] + ")"} />
                    <Tab icon={<EventNoteIcon />} iconPosition="start" label={"Aktueller Monat (" + monthNames[new Date().getMonth()] + ")"} />
                </Tabs>
            </div>

            <FixedSizeList
                height={400}
                width={"90%"}
                itemSize={60}
                itemCount={workingTimeAccounts.length}
                overscanCount={3}>
                {generateCurrentMonthEmployeeItems}
            </FixedSizeList>
        </div>
}

export default WorkTimeAccount;