import { Link } from "react-router-dom";
import BackButton from "../../../../sharedComponents/backButton/BackButton";
import "./MessageToTablet.css";
import MessageList from "./messageList/MessageList";
import MessageCreation from "./messageCreation/MessageCreation";
import WarningIcon from '@mui/icons-material/Warning';


function MessageToTablet(){
    return(
        <>
            <div className="message-to-tablet-container">
                <Link to={"/home/settings/"}><BackButton /></Link>
                <div className="message-to-tablet-header">
                    <h3>Nachrichten ans Tablet</h3>
                    <MessageCreation/>
                    <div className="warning-update-qr-app"><WarningIcon fontSize="large"/> Diese Funktionalität ist erst mit einem Update der Tablet-App verfügbar</div>
                </div>
                <MessageList/>
            </div>
        </>
    )
}

export default MessageToTablet;