import { ListItemButton, ListItemText, Divider, IconButton, TextField, ListItem, Collapse, Checkbox, FormControlLabel, ListItemAvatar, Avatar, List, useMediaQuery } from "@mui/material";
import { ExpandLess, ExpandMore, Check, MoreHoriz, Edit, DoDisturb, HealthAndSafety, BeachAccess, SyncAlt, Functions, NoteAdd, AccessTime, PunchClock, Celebration } from "@mui/icons-material";
import { useEffect, useState } from "react";
import formatDuration from "../../../../../../sharedComponents/FormatDuration";
import parseDuration from "../../../../../../sharedComponents/ParseDuration";

function WorktimeList(props){ // new State for Textfieldvalue, as targetTime is Array of Durationstrings

    const [openOverview, setOpenOverview] = useState([]);

    const [targetTime, setTargetTime] = useState(props.targetTimeArray.map(obj => (((obj.hours * 60)+ obj.minutes)/60).toLocaleString("de-DE", {maximumFractionDigits: 2})));
    
    const [editActive, setEditActive] = useState([]);

    const matches = useMediaQuery('(min-width:700px)');

    useEffect(() => {
        const storedOpenState = localStorage.getItem("WorktimeAccountOpenState");
        const initialOpenState = Array(props.allMonthsLength).fill(false);
        const fillWithFalse = Array(props.allMonthsLength).fill(false);

        if(storedOpenState) setOpenOverview(JSON.parse(storedOpenState));
            else setOpenOverview(initialOpenState)
        props.setChangeFollowing(fillWithFalse);
        setEditActive(fillWithFalse);
    }, [props.allMonthsLength])

    const handleClick = (index) => {
        const newOpenState = [...openOverview];
        newOpenState[index] = !newOpenState[index];
        setOpenOverview(newOpenState);
        localStorage.setItem("WorktimeAccountOpenState", JSON.stringify(newOpenState));
    };

    const handleCheckbox = (index) => {
        props.setChangeFollowing((prevChange) => {
            const newChangeFollowing = [...prevChange];
            newChangeFollowing.splice(index, 1, !props.changeFollowing[index]);
            return newChangeFollowing;
        });
    }

    const handleCancel = (index) => {
        setEditActive((prevEdit) => {
            const newEditActive = [...prevEdit];
            newEditActive.splice(index, 1, false);
            return newEditActive; 
        });
    }

    const handleEdit = (index) => {
        setEditActive(() => {
            const newEditActive = [...editActive];
            newEditActive.splice(index, 1, true);
            return newEditActive; 
        });
    }

    const handleTargetTimeChange = (e, index) => {
        const regex = /^\d*,?\d{0,2}$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            const newTargetTime = [...targetTime];
            newTargetTime[index] = e.target.value;
            setTargetTime(newTargetTime);
            props.targetTimeChange(e, index);
        }
    }

    function monthListItems() {
        let monthsList = [];
        props.allMonths.forEach((item) => {
            const monthString = new Date(item.month).toLocaleDateString('de', {month: 'long', year: 'numeric'});
            monthsList.push(monthString);
        })

        let currentYear = monthsList[0].split(" ").pop();
        let monthRender = [];
        monthRender.push(
            <div key={currentYear}>
                <ListItemText primary={currentYear} primaryTypographyProps={{fontSize: '26px'}} sx={{marginLeft: '25px'}}/>
                <Divider/>
            </div>
        )
        monthsList.forEach((item, index) => {
            let itemYear = item.split(" ").pop();
            if(itemYear !== currentYear){
                monthRender.push(
                    <div key={itemYear}>
                        <ListItemText primary={itemYear} primaryTypographyProps={{fontSize: '26px'}} sx={{marginLeft: '25px'}}/>
                        <Divider/>
                    </div>
                )
                currentYear = itemYear;
            }
            monthRender.push(
                <div key={index}>
                    <ListItemButton onClick={() => handleClick(index)} >
                        <ListItemText primary= {item} />
                        {openOverview[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <ListItem secondaryAction={
                        <>
                            {matches && editActive[index] && <FormControlLabel control={<Checkbox checked={props.changeFollowing[index]} onChange={() => handleCheckbox(index)}/>} label="für folgende Monate übernehmen"/>}
                            {editActive[index] && <><IconButton onClick={() => {props.save(index); setEditActive(Array(props.allMonthsLength).fill(false));}}><Check/></IconButton> <IconButton onClick={() => handleCancel(index)}><DoDisturb/></IconButton></>}
                            {!editActive[index] && <IconButton onClick={() => handleEdit(index)}><Edit/></IconButton>}
                            <IconButton onClick={() => props.addCorrection(props.allMonths[index])}><MoreHoriz/></IconButton>
                        </>
                    }>
                        <div className="listitem-secondary">
                            <TextField 
                                label={"Soll-Zeit (in Std)"} 
                                size="small"
                                disabled={!editActive[index]} 
                                value={targetTime[index]} 
                                sx={{width: '120px', marginLeft: '30px'}} 
                                onChange={(e) => handleTargetTimeChange(e, index)}
                            />
                        </div>
                    </ListItem>
                    <Collapse in={openOverview[index]} timeout="auto" unmountOnExit>
                        <List>
                            {monthDetailsItems(props.allMonths[index])}
                        </List>
                    </Collapse>
                </div>
            )
        })
        return monthRender;
    }

    function monthDetailsItems(object){
        let detailsList = [];
        const paidIllness = formatDuration(parseDuration(object.total_payed_illness_duration));
        const paidVacation = formatDuration(parseDuration(object.total_payed_vacation_duration));
        const paidHoliday = formatDuration(parseDuration(object.total_payed_holiday_duration));
        const sumWorktime = formatDuration(parseDuration(object.total_tracked_shifts_duration));
        let result;
        let transferlastMonth;
        if(object.transfer_from_last_month === null){
            transferlastMonth = "Noch nicht verfügbar"
        } else {
            transferlastMonth = formatDuration(parseDuration(object.transfer_from_last_month));
        }
        if(object.result === null) result = "Noch nicht verfügbar"
            else result = formatDuration(parseDuration(object.result));

        detailsList.push(
            <ListItem key="transferlastMonth"  className="month-details-listitem" secondaryAction={<ListItemText primary={transferlastMonth} />}>
                <ListItemAvatar>
                    <Avatar>
                    <SyncAlt fontSize="small"/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText className="total-worktime-listitemtext" primary={"Transferstunden aus letztem Monat: "} />
            </ListItem>
        )
        detailsList.push(            
            <ListItem key="worktarget"  className="month-details-listitem" secondaryAction={<ListItemText primary={formatDuration(parseDuration(object.target_working_hours))} />}>
                <ListItemAvatar>
                    <Avatar>
                    <AccessTime fontSize="small"/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"Soll-Zeit: "} />
            </ListItem>
        )
        detailsList.push(
            <ListItem key="sumWorktime"  className="month-details-listitem" secondaryAction={<ListItemText primary={sumWorktime} />}>
                <ListItemAvatar>
                    <Avatar>
                    <PunchClock fontSize="small"/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText className="total-worktime-listitemtext" primary={"Gesamt Arbeitsstunden: "}/>
            </ListItem>
        )
        if(paidIllness !== "00:00 Std"){
            detailsList.push(
                <ListItem key="paidIllness" className="month-details-listitem" secondaryAction={<ListItemText primary={paidIllness} />}>
                    <ListItemAvatar>
                        <Avatar>
                        <HealthAndSafety fontSize="small"/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={"Bezahlte Krankenstunden: "} />
                </ListItem>
            )
        }
        if(paidVacation !== "00:00 Std"){
            detailsList.push(
                <ListItem key="paidVacation"  className="month-details-listitem" secondaryAction={<ListItemText primary={paidVacation} />}>
                     <ListItemAvatar>
                        <Avatar>
                        <BeachAccess fontSize="small" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={"Bezahlte Urlaubsstunden: "} />
                </ListItem>
            )
        }
        if(paidHoliday !== "00:00 Std"){
            detailsList.push(
                <ListItem key="paidHoliday"  className="month-details-listitem" secondaryAction={<ListItemText primary={paidHoliday} />}>
                     <ListItemAvatar>
                        <Avatar>
                        <Celebration fontSize="small" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={"Bezahlte Feiertagsstunden: "} />
                </ListItem>
            )
        }
        object.corrections.forEach((item, index) => {
            detailsList.push(
                <ListItem key={index} className="month-details-listitem" secondaryAction={
                    <div className="correction-secondary">                        
                        <ListItemText primary={Math.abs(item.working_time_correction.hours).toString().padStart(2, '0') + ":" + Math.abs(item.working_time_correction.minutes).toString().padStart(2, '0') + " Std"} />                        
                    </div>
                }>
                    <img className="hover-cursor-click" src={process.env.PUBLIC_URL + '/icons/x-icon.png'} onClick={() => props.deleteTimeCorrection(item.id)} width="25px" alt="Rotes X-Icon"/>
                    <ListItemAvatar sx={{marginLeft: '10px'}}>
                        <Avatar>
                        <NoteAdd fontSize="small"/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText key={index} primary={"Korrekturstunden: "} secondary={item.description}/>
                </ListItem>
            )
        });
        detailsList.push(
            <div key={"results"}>
                <Divider sx={{ borderBottomWidth: 5 }} variant="inset"/>
                <ListItem className="month-details-listitem" secondaryAction={<ListItemText primary={result} primaryTypographyProps={{style:{fontWeight: 'bold', fontSize: '20px'}}}/>}>                
                    <ListItemAvatar>
                        <Avatar>
                        <Functions fontSize="small"/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={"Bilanz: "} primaryTypographyProps={{style:{fontWeight: 'bold', fontSize: '20px'}}}/>
                </ListItem>
            </div>
            
        )
        return detailsList;
    }

    return(
        <>{monthListItems()}</>
    )

}

export default WorktimeList;