import { useEffect, useState } from "react";
import "./WorkTimePlanning.css";
import axios from "axios";
import Logout from "../../../sharedComponents/Logout";
import LoadingSpinner from "../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { IconButton } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DateRangePicker } from "rsuite";
import { de } from "date-fns/locale";
import { FaClock } from "react-icons/fa";
import CustomSnackbar from "../../../sharedComponents/snackbar/CustomSnackbar";
import GetWeekNumber from "../../../sharedComponents/GetWeekNumber";
import PrimaryButton from "../../../sharedComponents/primaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import CancelButton from "../../../sharedComponents/cancelButton/CancelButton";
import parseDuration from "../../../sharedComponents/ParseDuration";
import formatDuration from "../../../sharedComponents/FormatDuration";
import isBefore from 'date-fns/isBefore';

let options = { day: '2-digit', month: '2-digit', year: 'numeric' };

function WorkTimePlanning() {

    const [isLoading, setIsLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [employeesSchedulingTime, setEmployeesSchedulingTime] = useState([]);
    const [employeesCoreWorkTime, setEmployeesCoreWorkTime] = useState([]);
    const [currentDate, setCurrentDate] = useState(getMonday(sessionStorage.getItem("currentDate") ? sessionStorage.getItem("currentDate") : new Date()));
    const [today] = useState(new Date());

    const [targetHourTime, setTargetHourTime] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [localChanges, setLocalChanges] = useState({});
    const [snackbarConfig, setSnackbarConfig] = useState({ open: false, message: "", variant: "" });

    useEffect(() => {
        getEmployees();
    }, [refresh])

    function getEmployees() {
        axios.get('employee/getActive').then(results => {            
            setEmployees(results.data);
            const monday = currentDate;
            const sunday = new Date(monday);
            sunday.setDate(monday.getDate() + 6);

            let targetHourArray = [];
            results.data.forEach((item) => {
                
                axios.get('workingTimeAccount/getAllForEmployeeWithId?id=' + item.id).then(results2 => {
                    const timeAccountData = results2.data[0];
                    if(timeAccountData !== undefined) targetHourArray.push(timeAccountData);
                })
            })
            setTargetHourTime(targetHourArray);

            axios.get('scheduling/getAllInDuration?start=' + monday.toISOString().slice(0, 10) + '&end=' + sunday.toISOString().slice(0, 10)).then(results => {
                setEmployeesSchedulingTime(results.data);
                axios.get('coreWorkingHoursPolicy/getAll').then(results => {
                    setEmployeesCoreWorkTime(results.data);
                    setIsLoading(false);
                })
            }).catch(() => { Logout() })
        }).catch(() => { Logout()})
    }

    const handleLocalChange = (employeeId, date, value, scheduleId) => {
        if (value === null) {
            if (scheduleId) {
                setLocalChanges(prevState => ({
                    ...prevState,
                    [`${employeeId}-${date}`]: {
                        employee_id: employeeId,
                        date: date,
                        id: scheduleId,
                        toDelete: true,
                    }
                }))
            } else {
                setLocalChanges(prevState => {
                    const newState = { ...prevState };
                    delete newState[`${employeeId}-${date}`];
                    return newState;
                });
            }
        } else if (value[0] !== null && value[1] !== null) {
            const startTime = addZeroToTime(value[0].getHours()) + ":" + addZeroToTime(value[0].getMinutes());
            const endTime = addZeroToTime(value[1].getHours()) + ":" + addZeroToTime(value[1].getMinutes());
            
            setLocalChanges(prevState => ({
                ...prevState,
                [`${employeeId}-${date}`]: {
                    employee_id: employeeId,
                    date: date,
                    start_time: startTime,
                    end_time: endTime,
                    id: scheduleId,
                    toDelete: false,
                }
            }));
        }  
    }

    const handleSaveChanges = () => {
        const requests = Object.values(localChanges).map(change => {
            let dateArray = change.date.split(".");
            let date = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
            if (change.toDelete && change.id) {
                return axios.delete(`scheduling/delete?id=${change.id}`);
            } else if (change.id) {
                return axios.put('scheduling/update', {
                    id: change.id,
                    date: date,
                    start_time: change.start_time,
                    end_time: change.end_time,
                    strict_mode: true,
                    comment: ""
                });
            } else if (!change.toDelete) {
                return axios.post('scheduling/create', {
                    employee_id: change.employee_id,
                    date: date,
                    start_time: change.start_time,
                    end_time: change.end_time,
                    strict_mode: true,
                    comment: ""
                });
            }
            return null;
        }).filter(request => request !== null);

        Promise.all(requests).then(() => {
            sessionStorage.setItem("currentDate", currentDate);
            window.location.reload();
            setSnackbarConfig({ open: true, message: "Änderungen erfolgreich gespeichert.", variant: "success" });
        }).catch(() => {
            setSnackbarConfig({ open: true, message: "Fehler beim Speichern der Änderungen.", variant: "error" });
        });
    };

    function addZeroToTime(time) {return time < 10 ? "0" + time : time;}

    function createTable() {
        let dynamicTable = [];
        let dates = [];
        let startDate = currentDate;
        let totalDurations = Array(7).fill(0);
        let weeklyTotals = [];
        
        for (let i = 0; i < 7; i++) {
            let date = new Date(startDate);
            date.setDate(startDate.getDate() + i);
            dates.push(date);
        }

        employees.forEach((employee) => {
            const coreTime = employeesCoreWorkTime.find(coreTime => coreTime.id === employee.assigned_core_working_hours_policy_id);
            let weeklyTotalMinutes = 0;
    
            dates.forEach((currentDate) => {
                let foundSchedule = false;
                let startTime = '';
                let endTime = '';
    
                const dateWeekDay = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"][currentDate.getDay()];
    
                if (coreTime) {
                    const period = coreTime.core_working_hours_periods.find(period => period.day_of_week === dateWeekDay);
                    if (period) {
                        foundSchedule = true;
                        startTime = period.start_time.slice(0, 5);
                        endTime = period.end_time.slice(0, 5);
                    }
                }
                
                const schedule = employeesSchedulingTime.find(schedule => schedule.employee_id === employee.id && new Date(schedule.date).toLocaleDateString('de-DE', options) === currentDate.toLocaleDateString('de-DE', options));
    
                if (schedule) {
                    foundSchedule = true;
                    startTime = schedule.start_time.slice(0, 5);
                    endTime = schedule.end_time.slice(0, 5);
                }
    
                if (foundSchedule) {
                    let initialStartDate = new Date(currentDate.setHours(parseInt(startTime.slice(0, 2)), parseInt(startTime.slice(3, 5)), 0, 0));
                    let initialEndDate = new Date(currentDate.setHours(parseInt(endTime.slice(0, 2)), parseInt(endTime.slice(3, 5)), 0, 0));
    
                    if(!isBefore(initialStartDate, new Date())){
                        const duration = (initialEndDate - initialStartDate) / (1000 * 60); // calculate duration in minutes
                        weeklyTotalMinutes += duration; // Add the duration to the weekly total
                    }
                }
            });
    
            weeklyTotals.push(weeklyTotalMinutes); // Store the weekly total for this employee
        });

        dynamicTable.push(
            <>
                <thead>
                    <tr>
                        <th></th>
                        {dates.map((date, index) => (
                            <th key={index} className={date.getDate() === today.getDate() ? 'planning-table-today-header' : ''}>
                                {["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"][date.getDay()]}
                                <br />
                                {date.toLocaleDateString('de-DE', {month: "2-digit", day: "2-digit", year: "numeric"})}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {employees.map((employee, rowIndex) => {
                        const coreTime = employeesCoreWorkTime.find(coreTime => coreTime.id === employee.assigned_core_working_hours_policy_id);

                        const targetTimeObject = targetHourTime.find(item => (employee.first_name === item.employee_first_name && employee.last_name === item.employee_last_name))
                        
                        let displayTime = "";

                        if(targetTimeObject !== undefined) {
                            const targetTimeObjectMonth = targetTimeObject.months.find(object => object.month === currentDate.toISOString().slice(0, 7))
                            
                            
                            if(targetTimeObjectMonth !== undefined){

                                const targetTime = parseDuration(targetTimeObjectMonth.target_working_hours);

                                if(targetTimeObjectMonth.transfer_from_last_month !== null){
                                    let transferTimeString = targetTimeObjectMonth.transfer_from_last_month;
                                    let newTransferTimeString = transferTimeString.replace(/-/g, '');
                                    const transferTime = parseDuration(newTransferTimeString);
                                    
                                    const targetAndTransferObject = {hours: targetTime.hours + transferTime.hours, minutes: targetTime.minutes + transferTime.minutes}
                                    const sumTargetAndTransfer = formatDuration(targetAndTransferObject);

                                    let doneTime = formatDuration({hours: 0, minutes: 0});       
                                    
                                    if(targetTimeObjectMonth.result !== null){
                                        let timeAccountResult = parseDuration(targetTimeObjectMonth.result)
                                    
                                        doneTime = formatDuration({hours: timeAccountResult.hours + targetAndTransferObject.hours, minutes: timeAccountResult.minutes + targetAndTransferObject.minutes} ); 
                                    }
                                    displayTime = doneTime + "/" + sumTargetAndTransfer;
                                }
                                    
                            }
                        }

                        return (
                            <tr key={rowIndex}>
                                <th scope="row">
                                    {employee.first_name} {employee.last_name} 
                                    <br/>
                                    {coreTime && (
                                        <>
                                            <Link className="link-standardregelung" to={"/home/settings/coretime/detailView?id=" + employee.assigned_core_working_hours_policy_id} target="_blank">Standardregelung</Link>
                                            <br/>
                                        </>
                                    )}
                                    <div className="target-working-time-display">
                                        {displayTime}
                                    </div>
                                    <div className="weekly-total-time">
                                        {weeklyTotals[rowIndex] > 0 && "+" + formatDuration({hours: Math.floor(weeklyTotals[rowIndex] / 60), minutes: weeklyTotals[rowIndex] % 60})}
                                    </div>
                                </th>
                                {dates.map((currentDate, colIndex) => {
                                    let foundSchedule = false;
                                    let startTime = '';
                                    let endTime = '';

                                    const dateWeekDay = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"][currentDate.getDay()];

                                    if (coreTime) {
                                        const period = coreTime.core_working_hours_periods.find(period => period.day_of_week === dateWeekDay);
                                        if (period) {
                                            foundSchedule = true;
                                            startTime = period.start_time.slice(0, 5);
                                            endTime = period.end_time.slice(0, 5);
                                        }
                                    }
                                    
                                    const schedule = employeesSchedulingTime.find(schedule => schedule.employee_id === employee.id && new Date(schedule.date).toLocaleDateString('de-DE', options) === currentDate.toLocaleDateString('de-DE', options));

                                    if (schedule) {
                                        foundSchedule = true;
                                        startTime = schedule.start_time.slice(0, 5);
                                        endTime = schedule.end_time.slice(0, 5);
                                    }

                                    const localChange = localChanges[`${employee.id}-${currentDate.toLocaleDateString('de-DE', options)}`];
                                    if (localChange?.toDelete) {
                                        return (
                                            <td key={colIndex}>
                                                <div className="deleted-label">Geplante Zeit entfernt</div>
                                            </td>
                                        );
                                    }

                                    if (foundSchedule) {
                                        let initialStartDate = new Date(currentDate.setHours(parseInt(startTime.slice(0, 2)), parseInt(startTime.slice(3, 5)), 0, 0));
                                        let initialEndDate = new Date(currentDate.setHours(parseInt(endTime.slice(0, 2)), parseInt(endTime.slice(3, 5)), 0, 0));

                                        const duration = (initialEndDate - initialStartDate) / (1000 * 60); // calculate duration in minutes
                                        totalDurations[colIndex] += duration;

                                        return (
                                            <td key={colIndex}>
                                                <DateRangePicker defaultValue={[initialStartDate, initialEndDate]} format="HH:mm" character="-" placeholder="Start - Ende" locale={de} caretAs={FaClock} oneTap 
                                                    onChange={value => handleLocalChange(employee.id, currentDate.toLocaleDateString('de-DE', options), value, schedule?.id, false)}
                                                    cleanable={schedule !== undefined}
                                                />
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td key={colIndex} >
                                                <DateRangePicker onChange={value => handleLocalChange(employee.id, currentDate.toLocaleDateString('de-DE', options), value, false)} 
                                                format="HH:mm" character="-" placeholder={coreTime ? "Verboten" : "Erlaubt"} locale={de} oneTap caretAs={FaClock} className="empty-cell" />
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        );
                    })}
                    <tr className="worktime-table-dailytotal" key={"lastRow"}>
                        <th>Summe Tagesstunden</th>
                        {totalDurations.map((object, index) => {
                            return (
                                <td key={index}>
                                    {Math.floor(object /60)} Std {object % 60} min
                                </td>
                            )
                        })}
                    </tr>
                </tbody>
            </>
        )
        
        const grandTotal = totalDurations.reduce((acc, curr) => acc + curr, 0);

        return <>
            <table className="work-time-planning-table">
                {dynamicTable}
            </table>
            <div className="worktime-table-grandtotal" key="grand-total" style={{ marginTop: "10px", marginLeft: "5%", fontWeight: "bold" }}>
                Gesamtdauer dieser Woche: 
                <br/>
                {Math.floor(grandTotal /60)} Std {grandTotal % 60} min
            </div>
        </>
            
    }

    function getMonday(date) {
        date = new Date(date);
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }

    const handleNextWeek = () => {
        let newWeekDate = getMonday(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7))
        newWeekDate.setHours(10, 0, 0, 0);
        setCurrentDate(newWeekDate);
        setIsLoading(true);
        setRefresh(!refresh);
    }

    const handleLastWeek = () => {
        let newWeekDate = getMonday(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7))
        newWeekDate.setHours(10, 0, 0, 0);
        setCurrentDate(newWeekDate);
        setIsLoading(true);
        setRefresh(!refresh);
    }

    function getButtons() {
        return (
            <>
                <IconButton aria-label="next" disabled={Object.keys(localChanges).length > 0} onClick={handleLastWeek}>
                    <ChevronLeftIcon />
                </IconButton>
                <p>KW {GetWeekNumber(currentDate)}</p>
                <IconButton aria-label="next" disabled={Object.keys(localChanges).length > 0} onClick={handleNextWeek}>
                    <ChevronRightIcon />
                </IconButton>
            </>
            
        )
    }

    function createTableOrLoadingSpinner() {
        if(isLoading) {
            return <LoadingSpinner />
        } else {
            return (
                createTable()
            )
        }
    }

    const handleTodayButtonClicked = () => {
        setCurrentDate(getMonday(new Date()));
        setIsLoading(true);
        setRefresh(!refresh);
    }

    return (
        <div className="work-time-planning-page">
            <div className="work-time-planning-header">
                <h1>Arbeitszeitplanung</h1>
                <div className="work-time-planning-today-button">
                    <PrimaryButton text="Heute" onClick={handleTodayButtonClicked}/>
                </div>
                <div className="work-time-planning-next-back-buttons">
                    {getButtons()}
                </div>
                <CancelButton text="Wochenplan herunterladen" onClick={() => window.print()}/>
            </div>
            <h3 className="not-visible-info red">Planung kann nur auf einem größeren Bildschirm stattfinden.</h3>
            <div className="planning-information-section">
                <p>Hier können alle Arbeitszeiten der Mitarbeitenden geplant werden. Die <span className="bold">Kernarbeitszeiten</span> werden <span className="bold">automatisch</span> eingefügt. Diese können hier für die geplanten Tage <span className="bold">überschrieben</span> werden.</p>
                <p><span className="red-color">Achtung:</span> Mitarbeitende können sich nur in den geplanten Zeiten einloggen.</p>
            </div>
            <div className="work-time-planning-save-button">
                <PrimaryButton text="Speichern" onClick={handleSaveChanges} disabled={Object.keys(localChanges).length === 0}/>
            </div>
            {createTableOrLoadingSpinner()}
            <CustomSnackbar 
                variant={snackbarConfig.variant} 
                message={snackbarConfig.message} 
                open={snackbarConfig.open} 
                onClose={() => setSnackbarConfig({ ...snackbarConfig, open: false })} 
            />
        </div>
    )
}

export default WorkTimePlanning;