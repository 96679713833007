import "./FullGeneratedActivity.css";
import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CancelButton from "../../../../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../../../../sharedComponents/primaryButton/PrimaryButton";

function FullGeneratedActivity(props) {

    const [time, setTime] = useState("");
    const [date, setDate] = useState("");

    const [show, setShow] = useState(false);

    const [updatedTime, setUpdatedTime] = useState("");

    const [timeError, setTimeError] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        setTime(props.time);
        setDate(props.date);
    }, [])

    function createActivity() {
        return (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">{createToolTipText()}</Tooltip>}>
                    <img className="icon"
                         src={createCorrectLogoSource()}
                         alt="Aktivitäts Bild"/>
                </OverlayTrigger>
                {createTimeInputWithEditableButton()}
            </>
        )
    }

    function createToolTipText() {
        let text = "";

        switch (props.type) {
            case "shift_begin":
                text = "Arbeit gestartet";
                break;
            case "pause_begin":
                text = "Pause begonnen";
                break;
            case "pause_end":
                text = "Arbeit fortgesetzt";
                break;
            case "shift_end":
                text = "Arbeit beendet!";
                break;
            default:
                break;
        }

        return text;
    }

    function createCorrectLogoSource() {
        let logoSource = "";
        let colorCode;

        if (time === "") {
            colorCode = "red";
        } else {
            colorCode = "green";
        }

        switch (props.type) {
            case "shift_begin":
                logoSource = process.env.PUBLIC_URL + '/icons/login-rounded-right/' + colorCode + '.png';
                break;
            case "pause_begin":
                logoSource = process.env.PUBLIC_URL + '/icons/cup/' + colorCode + '.png';
                break;
            case "pause_end":
                logoSource = process.env.PUBLIC_URL + '/icons/wrench/' + colorCode + '.png';
                break;
            case "shift_end":
                logoSource = process.env.PUBLIC_URL + '/icons/logout/' + colorCode + '.png';
                break;
            default:
                break;
        }

        return logoSource;
    }

    function createTimeInputWithEditableButton() {
        return (
            <>
                <p className="full-generated-activity-time-Input">{date + " - "}<span className="bold">{time}</span></p>
                <Button disabled={false} onClick={() => handleShow()} className="full-generated-activity-editable-button" variant={"info"}>Bearbeiten</Button>
            </>
        )
    }

    function renderActivityModal() {
        return (
            <Modal
                className="full-generated-activity-modal"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Uhrzeit anpassen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-form-element">
                        <p>Uhrzeit: </p>
                        <input onChange={handleTimeChange} disabled={false} type="time" className="modal-input form-control" value={updatedTime}/>
                    </div>
                    {timeError && <p className="error-in-time-validation">Die Uhrzeit kann nicht eingefügt werden.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Speichern" onClick={() => saveTime()} />
                </Modal.Footer>
            </Modal>
        )
    }

    function handleTimeChange(event) {
        setUpdatedTime(event.target.value);
    }

    function saveTime() {
        updateTimeInSessionStorage(true);
        let activities = props.checkActivitiesToChangeDate();
        if (checkTimeInput(activities)) {
            props.setFilledFirstActivity(true);
            props.saveActivityChanges(activities);
            setTime(updatedTime);
            setCurrentDate();
            handleClose();
        } else {
            updateTimeInSessionStorage(false);
            setTimeError(true);
        }
    }

    function checkTimeInput(activities) {
        let index = activities.findIndex(item => item.id === props.id);
        
        if (checkTimeBefore(activities, index) && checkTimeAfter(activities, index))
            return true;
        else 
            return false;
    }

    function checkTimeBefore(activities, index) {
        if (index === 0)   
            return true;

        let currentIndex = index - 1;

        while (currentIndex > 0) {
            if (activities[currentIndex].type !== 'pseudo' && activities[currentIndex].time !== "") {
                if (!time1BeforeTime2(activities[currentIndex].date, activities[index].date, activities[currentIndex].time, activities[index].time)) {
                    return false;
                }
            }
            currentIndex--;
        }

        return true;
    }

    function checkTimeAfter(activities, index) {
        if (index === activities.length - 1)
            return true;

        let currentIndex = index + 1;

        while (currentIndex < activities.length) {
            if (activities[currentIndex].type !== 'pseudo' && activities[currentIndex].time !== "") {
                if (!time1BeforeTime2(activities[index].date, activities[currentIndex].date, activities[index].time, activities[currentIndex].time)) {
                    return false;
                }
            }
            currentIndex++;
        }

        return true;
    }

    function time1BeforeTime2(date1, date2, time1, time2) {
        let splittedDate1 = date1.split(".");
        let splittedDate2 = date2.split(".");
        let splittedTime1 = time1.split(":");
        let splittedTime2 = time2.split(":");

        let dateWithTime1 = new Date(parseInt(splittedDate1[2]), parseInt(splittedDate1[1] - 1), parseInt(splittedDate1[0]), splittedTime1[0], splittedTime1[1]);
        let dateWithTime2 = new Date(parseInt(splittedDate2[2]), parseInt(splittedDate2[1] - 1), parseInt(splittedDate2[0]), splittedTime2[0], splittedTime2[1]);

        if (dateWithTime1.getDate() < dateWithTime2.getDate()) {
            return true;
        }

        if (dateWithTime1.getTime() <= dateWithTime2.getTime())
            return true;
        else
            return false;
    }

    function setCurrentDate() {
        let currentActivities = JSON.parse(sessionStorage.getItem("new-shift-current-activities"));

        for (let activity of currentActivities) {
            if (activity.key === props.key && activity.date !== null) {
                setDate(activity.date);
            }
        }

        setDate(sessionStorage.getItem("new-shift-begin-date"));
    }

    function updateTimeInSessionStorage(change) {
        let allActivities = JSON.parse(sessionStorage.getItem("new-shift-current-activities"));

        for(let activity of allActivities) {
            if (activity.id === props.id) {
                if (change)
                    activity.time = updatedTime;
                else
                    activity.time = time;
            }
        }

        sessionStorage.setItem("new-shift-current-activities", JSON.stringify(allActivities));
    }

    return (
        <>
            {createActivity()}
            {renderActivityModal()}
        </>
    )
}

export default FullGeneratedActivity;