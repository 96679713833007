import "./EmployeeItem.css";

function EmployeeItem(props) {
    if (props.notCompleted || props.unresolvedShiftObjection) {
        return (
            <div className="employee-item-container warning">
                <p className="employee-item-text">{props.firstName + " " + props.lastName}<span className="small-text"> - {props.id}</span>
                    <img className="warning-logo" src={process.env.PUBLIC_URL + '/icons/exclamation-mark.png'} alt="Rotes Ausrufezeichen"/>
                </p>
            </div>
        )
    } else {
        return (
            <div className="employee-item-container">
                <p className="employee-item-text">{props.firstName + " " + props.lastName}<span className="small-text"> - {props.id}</span>

                </p>
            </div>
        )
    }
}

export default EmployeeItem;