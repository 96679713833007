import "./EmployeeHoliday.css";
import { useEffect, useState } from "react";
import ConfigureHolidayModal from "./configureHolidayModal/ConfigureHolidayModal";
import axios from "axios";
import Logout from "../../../../../sharedComponents/Logout";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import HolidayView from "./holidayView/HolidayView";

function EmployeeHoliday() {

    const [isLoading, setIsLoading] = useState(true);
    const [payedHolidays, setPayedHolidays] = useState(false);
    const [holidays, setHolidays] = useState(false);

    useEffect(() => {
        getHolidays();
    }, [])

    function getHolidays() {
        axios.get('employee/getAllBundeslandModifications?employee_id=' + new URLSearchParams(window.location.search).get("employeeId")).then(results => {
            setPayedHolidays(results.data.length !== 0);
            setHolidays(results.data);
            setIsLoading(false);
        }).catch(() => { Logout()})
    }

    return isLoading ?
        <LoadingSpinner />
        :
        payedHolidays ?
            <HolidayView holidays={holidays}/>
            :
            <div className="non-payed-holidays">
                <p>Feiertage werden aktuell auf den Exporten nicht berücksichtigt.</p>
                <ConfigureHolidayModal withNoneSelection={false}/>
            </div>
}

export default EmployeeHoliday;