import { useState } from "react";
import './MessageCreation.css'
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";
import { Alert, InputLabel, Snackbar, TextField } from "@mui/material";
import { Modal } from "react-bootstrap";
import CancelButton from "../../../../../sharedComponents/cancelButton/CancelButton";
import axios from "axios";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { de } from "date-fns/locale";


function MessageCreation(){

    const [openModal, setOpenModal] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [errorSnackbarText, setErrorSnackbarText] = useState("")
    const [messageString, setMessageString] = useState("");
    const [messageStringError, setMessageStringError] = useState(false);
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        }
    ])

    const handleAddCLick = () => {
        setOpenModal(true);
        setSelectionRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection"
            }
        ])
        setMessageString("");
        setMessageStringError(false);
    }

    const handleClose = () => {
        setOpenModal(false);
    }

    const handleAddMessage = () => {
        if(messageString === ""){
            setMessageStringError(true);
        } else {
            setMessageStringError(false);
            let starttime = new Date(selectionRange[0].startDate);
            let endtime = new Date(selectionRange[0].endDate);
            starttime.setHours(0);
            endtime.setHours(23, 59, 59);

            axios.post('employeeGeneralMessage/create', {
                "start_timestamp": starttime,
                "end_timestamp": endtime,
                "message": messageString
            }).then(() => {
                setOpenModal(false);
                window.location.reload();
            }).catch(() => {
                setErrorSnackbarText("Achten Sie darauf, dass die Tage der Nachrichten sich nicht überschneiden!");
                setOpenError(true);
            })
        }
    }

    const getDatesBeforeToday = () => {
        const today = new Date();
        const dates = [];
        for (let date = new Date(2023, 0, 1); date < today; date.setDate(date.getDate() + 1)) {
            if (date.toDateString() !== today.toDateString()) {
                dates.push(new Date(date));
            }
        }
        return dates;
    };

    function creationModal(){
        return(
            <Modal
                show={openModal}
                onHide={handleClose}
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Neue Nachricht erstellen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="message-creation-modal-body">
                        <TextField 
                            error={messageStringError} 
                            helperText= {messageStringError ? "Bitte ausfüllen." : ""}
                            onChange={(e) => setMessageString(e.target.value)} 
                            value={messageString} 
                            multiline 
                            rows={4} 
                            label="Nachricht eingeben" 
                        />
                        <div className="Daterange-picker">
                            <InputLabel>Auswählen wann die Nachricht angezigt werden soll:</InputLabel>
                            <DateRangePicker
                                locale={de}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                ranges={selectionRange}
                                onChange={(ranges) => setSelectionRange([ranges.selection])}
                                disabledDates={getDatesBeforeToday()}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Nachricht hinzufügen" onClick={handleAddMessage} />
                </Modal.Footer>
            </Modal>
        )
    }

    function errorSnackbar(){
        return(
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {errorSnackbarText}
                </Alert>
            </Snackbar>
        )
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway')
            return;

        setOpenError(false);
    }

    return(
        <>
            <PrimaryButton text="Neue Nachricht hinzufügen" onClick={handleAddCLick} />
            {creationModal()}
            {errorSnackbar()}
        </>
    )
}

export default MessageCreation;