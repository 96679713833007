import "./LocationInformation.css";
import {useEffect, useState} from "react";
import axios from "axios";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import {Modal, Button} from "react-bootstrap";
import { TextField } from "@mui/material";
import CancelButton from "../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";

function LocationInformation() {

    const [isLoading, setIsLoading] = useState(true);
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    const [name, setName] = useState("");
    const [id, setId] = useState("");

    const [activateDeactivateText, setActivateDeactivateText] = useState("");
    const [activateDeactivateVariant, setActivateDeactivateVariant] = useState("");
    const [activateDeactivateError, setActivateDeactivateError] = useState(false);

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setActivateDeactivateError(false);
    }

    useEffect(() => {
        if (isLoading) {
            getLocationData();
        }
    }, [disableSaveButton])

    function getLocationData() {
        axios.get('location/getById?id=' + new URLSearchParams(window.location.search).get("locationId")).then(results => {
            setName(results.data.name);
            setId(results.data.id);
            createActivateDeactivateButton(results.data.is_active);

            setIsLoading(false);
        })
    }

    function createActivateDeactivateButton(active) {
        if (active) {
            setActivateDeactivateText("Standort deaktivieren");
            setActivateDeactivateVariant("error");
        } else {
            setActivateDeactivateText("Standort aktivieren");
            setActivateDeactivateVariant("success");
        }
    }

    const handleChange = (event) => {
        let value = event.target.value;
        checkInputFields(event);
        setDisableSaveButton(checkAllInputFields());
        updateDisplay();

        switch (event.target.id) {
            case "LocationName":
                setName(value);
                break;
            case "LocationId":
                setId(value);
                break;
            default:
                break;
        }
    }

    function checkInputFields(event) {
        if (event.target.value === "") {
            event.target.style.borderColor = "red";
        } else {
            event.target.style.borderColor = "black";
        }
    }

    function updateDisplay() {
        let changeDisplay = document.getElementById("ChangeDisplay");
        changeDisplay.innerHTML = "Änderungen bitte speichern!";
        changeDisplay.style.color = "red";
    }

    function checkAllInputFields() {
        let disableInputField = false;

        if (document.getElementById("LocationName").value === "") {
            disableInputField = true
        }
        if (document.getElementById("LocationId").value === "") {
            disableInputField = true
        }

        return disableInputField;
    }

    function handleSaveButtonClick() {
        let changeDisplay = document.getElementById("ChangeDisplay");
        axios.put('location/update', {
            "id": id,
            "name": name
        }).then(response => {
            changeDisplay.innerHTML = "Änderungen wurden gespeichert!";
            changeDisplay.style.color = "green";
        }).catch(error => {
            changeDisplay.innerHTML = "Ein Fehler ist aufgetreten!";
            changeDisplay.style.color = "red";
        })
    }

    function createModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{activateDeactivateText}</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p className="modal-text">{"Möchten Sie diesen Standort wirklich " + activateDeactivateText.split(" ")[1] + "?"}</p>
                    {activateDeactivateError && <p className="red">Ein Fehler ist aufgetreten. (Hinweis: Prüfen Sie, ob Sie einen weiteren Standort aktivieren dürfen)</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Ja" onClick={handleActivateDeactivateProfile} />
                </Modal.Footer>
            </Modal>
        )
    }

    function handleActivateDeactivateProfile() {
        if (activateDeactivateText === "Standort deaktivieren") {
            axios.put("location/setStatus", {
                "id": id,
                "is_active": false
            }).then(() => { 
                window.location.reload();
            }).catch(error => {
                setActivateDeactivateError(true);
            })
        } else if (activateDeactivateText === "Standort aktivieren") {
            axios.put("location/setStatus", {
                "id": id,
                "is_active": true
            }).then(() => { 
                window.location.reload();
            }).catch(error => {
                setActivateDeactivateError(true);
            })
        }
    }

    return isLoading ?

        <LoadingSpinner/>
        :
        (
            <>
                <div className="header-information-container">
                    <h1>Informationen</h1>
                    <PrimaryButton text="Speichern" disabled={disableSaveButton} onClick={handleSaveButtonClick} />
                </div>
                <h5 id="ChangeDisplay"></h5>
                <div className="location-information-form">
                    <TextField id="LocationName" className="location-information-input" label="Bezeichnung" variant="outlined" value={name} onChange={handleChange} />
                    <TextField id="LocationId" className="location-information-input" label="Standortnummer" disabled={true} variant="outlined" value={id} />
                </div>
                <div className="activate-deactivate-button-container">
                    <Button variant="danger" disableElevation color={activateDeactivateVariant} onClick={handleShow}>{activateDeactivateText}</Button>
                </div>
                {createModal()}
            </>
        )

}

export default LocationInformation;