import { Box, Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import CancelButton from "../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import "./AppAccess.css";
import AppAccessList from "./list/AppAccessList";

function AppAccess() {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [firstNameError, setFirstNameError] = useState(false);
    const [successfulCreating, setSuccessfulCreating] = useState(false);
    const [refreshList, setRefreshList] = useState(false);

    // Modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setFirstNameError(false);
        setOpen(false);
    }

    useEffect(() => {
        if (successfulCreating) {
            setRefreshList(prevState => !prevState);
            setSuccessfulCreating(false);
          }
    }, [successfulCreating])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (document.getElementById("AppAddingNameInput").value === sessionStorage.getItem("employeeFirstName")) {
            axios.post('employee/app/connections/create', {
                "employee_id" : sessionStorage.getItem("employeeId"),
            }).then(() => {
                setSuccessfulCreating(true);
                handleClose();
            }).catch(() => {
                
            })
        } else {
            setFirstNameError(true);
        }
    } 

    function createAddConnectionModal() {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        App Zugang hinzufügen
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Hier haben Sie die Möglichkeit einen neuen App Zugang für den entsprechenden Mitarbeiter anzulegen.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Geben Sie hier bitte zur Überprüfung den Vornamen des Mitarbeitenden ein.
                    </Typography>
                    <form onSubmit={handleSubmit} className="app-adding-modal-form">
                        <TextField id="AppAddingNameInput" className="app-adding-modal-name-input" label="Vorname" variant="outlined" />
                    </form>
                    {firstNameError && <Typography sx={{ mt: 2 }} color="red">
                        Der Vorname ist nicht korrekt.
                    </Typography>}
                    <div className="app-adding-modal-buttons">
                        <CancelButton text="Abbrechen" onClick={handleClose} />
                        <PrimaryButton text="App Zugang anlegen" onClick={handleSubmit} />
                    </div>
                </Box>
            </Modal>
        )
    }

    return (
        <>
            <div className="access-app-header">
                <h2>App Zugang</h2>
                <PrimaryButton text="Hinzufügen" onClick={handleOpen} />
            </div>
            <AppAccessList successfulCreating={successfulCreating} refresh={refreshList}/>
            {createAddConnectionModal()}
        </>
    )
}

export default AppAccess;