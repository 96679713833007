import { Link } from "react-router-dom";
import "./WorkTimeAccountDashboard.css";
import BackButton from "../../../../../sharedComponents/backButton/BackButton";
import { CustomProvider, DatePicker } from "rsuite";
import de_DE from 'rsuite/locales/de_DE';
import { useEffect, useState } from "react";
import axios from "axios";
import Logout from "../../../../../sharedComponents/Logout";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { DataGrid } from '@mui/x-data-grid';
import formatDuration from "../../../../../sharedComponents/FormatDuration";
import parseDuration from "../../../../../sharedComponents/ParseDuration";
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";

const columns = [
    { field: 'firstName', headerName: 'Vorname', width: 100 },
    { field: 'lastName', headerName: 'Nachname', width: 150 },
    { field: 'transfer', headerName: "Transfer (Bilanz) aus dem letzten Monat", width: 100 },
    { field: 'soll-time', headerName: "Soll-Arbeitszeit", width: 150 },
    { field: 'ist-time', headerName: "Aufgezeichnete Ist-Arbeitszeit", width: 250 },
    { field: 'absences', headerName: "Summe Urlaub mit LFZ", width: 200 },
    { field: 'illness', headerName: "Summe Krankheit mit LFZ", width: 200 },
    { field: 'holidays', headerName: "Summe Feiertage mit LFZ", width: 200 },
    { field: 'correction', headerName: "Summe Ist-Zeit Korrekturen", width: 230 },
    { field: 'bilance', headerName: "Bilanz", width: 100 },
];

function WorkTimeAccountDashboard() {

    const [isLoading, setIsLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState(new Date(), 'MMM yyyy');
    const [workingTimeAccounts, setWorkingTimeAccounts] = useState(null);

    useEffect(() => {
        getExistingWorkingTimeAccounts(formatInYearMonth(selectedMonth));
    }, [])

    useEffect(() => {
        setIsLoading(true);
        getExistingWorkingTimeAccounts(formatInYearMonth(selectedMonth));
    }, [selectedMonth])

    function formatInYearMonth(date) {
        let currentYear = date.getFullYear();
        let currentMonth = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${currentYear}-${currentMonth}`;
    }

    const handleExportPDFDownload = () => {
        axios.get('workingTimeAccount/export/fullMonthly/pdf?yearmonth='+ formatInYearMonth(selectedMonth),
        {
            responseType: 'blob',
            headers: {'Content-Type': 'application/json'},
        }
        ).then((results) => 
            createPDF(results, 'Gesamt Arbeitszeitkonto Export ' + selectedMonth.toLocaleDateString('de', {month: 'long', year: 'numeric'}))
        )
    }

    function createPDF(results, filename){
        const data = results.data
        const blob = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    function getExistingWorkingTimeAccounts(yearmonth) {
        axios.get(`workingTimeAccount/getAllWhichExistInGivenMonth?yearmonth=${yearmonth}`).then(results => {
            const workingTimeAccountsData = results.data;
            axios.get('employee/getActive').then(resultsData => {
                resultsData.data.forEach(employee => {
                    const matchingAccountIndex = workingTimeAccountsData.findIndex(account => account.employee_id === employee.id);
                    if (matchingAccountIndex !== -1) {
                        workingTimeAccountsData[matchingAccountIndex].first_name = employee.first_name;
                        workingTimeAccountsData[matchingAccountIndex].last_name = employee.last_name;
                    }
                })

                setWorkingTimeAccounts(workingTimeAccountsData);
                setIsLoading(false);
            }).catch(() => { Logout()})
        }).catch(() => { Logout()})
    }

    const rowClassGetter = (params) => {
        const rowData = params.row;
        const fullRowData = workingTimeAccounts.find(element => element.id === rowData.id);
        if ((fullRowData.error_not_completed_shift_begin_ids.length !== 0) || (fullRowData.error_pause_overflow_shift_begin_ids.length !== 0) || (fullRowData.error_unresolved_shift_objection_shift_begin_ids.length !== 0)) {
            return 'grid-row-red-color';
        } else if ((fullRowData.warning_possible_holiday_collision_shift_begin_ids.length !== 0)) {
            return 'grid-row-yellow-color';
        }
    }

    const generateRows = () => {
        let rows = [];

        workingTimeAccounts.forEach(workTimeAccount => {
            let currentMonth = workTimeAccount.months.filter(workingTimeMonth => workingTimeMonth.month === formatInYearMonth(selectedMonth))[0];
            if (currentMonth !== undefined) {
                let row = {
                    id: workTimeAccount.id,
                    firstName: workTimeAccount.employee_first_name,
                    lastName: workTimeAccount.employee_last_name,
                    transfer: formatDuration(parseDuration(currentMonth.transfer_from_last_month)),
                    'soll-time': formatDuration(parseDuration(currentMonth.target_working_hours)),
                    'ist-time': formatDuration(parseDuration(currentMonth.total_tracked_shifts_duration)),
                    absences: formatDuration(parseDuration(currentMonth.total_payed_vacation_duration)),
                    illness: formatDuration(parseDuration(currentMonth.total_payed_illness_duration)),
                    holidays: formatDuration(parseDuration(currentMonth.total_payed_holiday_duration)),
                    correction: formatDuration(parseDuration(currentMonth.total_sum_of_corrections)),
                    bilance: formatDuration(parseDuration(currentMonth.result)),
                }
                rows.push(row);
            }
        })

        return rows;
    }

    function generateDataTable() {
        return isLoading ?
            <LoadingSpinner />
            :
            <DataGrid
                rows={generateRows()}
                columns={columns}
                disableRowSelectionOnClick
                hideFooterPagination
                density="compact"
                disableColumnMenu
                hideFooter
                getRowClassName={rowClassGetter}
            />
    }

    return (
        <>
            <Link to={'/home/dashboard'}>
                <div className="employee-information-header-container">
                    <BackButton text="Zurück" />
                </div>
            </Link>
            <div className="work-time-account-dasboard">
                <CustomProvider locale={de_DE}>
                    <DatePicker className="dashboard-datepicker" cleanable={false} label="Ausgewählter Monat" oneTap required format="MMM yyyy" size="lg" placeholder="Startmonat" value={selectedMonth} onSelect={(value) => setSelectedMonth(value)}/>
                </CustomProvider>  
                {generateDataTable()}
                <PrimaryButton onClick={handleExportPDFDownload} text={'Download Export'} />
            </div>            
        </>
    )
}

export default WorkTimeAccountDashboard;