import "./LocationView.css";
import {useState, useEffect} from "react";
import axios from "axios";
import LoadingSpinner from "../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import LocationInformation from "./information/LocationInformation";
import { Link } from "react-router-dom";
import Logout from "../../../../sharedComponents/Logout";
import BackButton from "../../../../sharedComponents/backButton/BackButton";

function LocationView() {

    const [isLoading, setIsLoading] = useState(true);

    const [name, setName] = useState("");
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("jwtToken");
        getLocationData();
    })

    function getLocationData() {
        axios.get('location/getById?id=' + new URLSearchParams(window.location.search).get("locationId")).then(results => {
            setName(results.data.name);
            setIsActive(results.data.is_active);

            setIsLoading(false);
        }).catch(() => Logout())
    }

    function createContainers() {
        return (
            <div className="location-view-container">
                <div className="location-information-container">
                    <LocationInformation/>
                </div>
            </div>
        )
    }

    function createLocationStatus() {
        return isActive ? (<p className="active-header">Aktives Profil</p>) : (<p className="inactive-header">Deaktiviertes Profil</p>)
    }

    return isLoading ?

        <LoadingSpinner/>
        :
        (
            <>
                <div className="location-view-header-container">
                    <Link to={'/home/locations'}>
                        <BackButton text="Zurück"/>
                    </Link>
                    <p>{name}</p>
                    {createLocationStatus()}
                </div>
                {createContainers()}
            </>
        )

}

export default LocationView;