import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./SelectedShiftHistory.css";

function SelectedShiftHistory(props) {

    function generateHistory() {
        let history = [];
        let newlyCreated = false;

        props.history.map((item, index) => {
            switch(item.modification_type) {
                case "newly_created":
                    if (!newlyCreated) {
                        history.push(
                            <React.Fragment key={"Changing " + index}>
                                <Typography>
                                    {"Die Arbeitszeit wurde von " + item.modified_by_username + " am " + new Date(item.modified_at).toLocaleDateString() + " nachträglich hinzugefügt."}
                                </Typography>
                                <hr/>
                            </React.Fragment>
                        )
                    }
                    newlyCreated = true;
                    break;
                case "completed_shift":
                    history.push(
                        <React.Fragment key={"Changing " + index}>
                            <Typography>
                                {"Aktivität " + item.activity_id + " wurde von " + item.modified_by_username + " am " + new Date(item.modified_at).toLocaleDateString() + " auf " + new Date(item.timestamp_after).toLocaleTimeString().slice(0, 5) + " Uhr (" + new Date(item.timestamp_after).toLocaleDateString() + ") nachgetragen."}
                            </Typography>
                            <Typography>
                                {"Grund: " + item.reason}
                            </Typography>
                            <hr/>
                        </React.Fragment>
                    )
                    break;
                case "edited_afterwards":
                    history.push(
                        <React.Fragment key={"Changing " + index}>
                            <Typography>
                                {"Aktivität " + item.activity_id + " wurde von " + item.modified_by_username + " am " + new Date(item.modified_at).toLocaleDateString() + " von " + new Date(item.timestamp_before).toLocaleTimeString().slice(0, 5) + " Uhr ("  + new Date(item.timestamp_before).toLocaleDateString() + ") auf " + new Date(item.timestamp_after).toLocaleTimeString().slice(0, 5) + " Uhr (" + new Date(item.timestamp_after).toLocaleDateString() + ") gesetzt."}
                            </Typography>
                            <Typography>
                                {"Grund: " + item.reason}
                            </Typography>
                            <hr/>
                        </React.Fragment>
                    )
                    break;
                case "added_pause":
                    history.push(
                        <React.Fragment key={"Changing " + index}>
                            <Typography>
                                {"Pause (Anfang oder Ende) " + item.activity_id + " wurde von " + item.modified_by_username + " am " + new Date(item.modified_at).toLocaleDateString() + " nachträglich hinzugefügt."}
                            </Typography>
                            <Typography>
                                {"Grund: " + item.reason}
                            </Typography>
                            <hr/>
                        </React.Fragment>
                    )
                    break;
                case "set_ignored_to_true":
                    history.push(
                        <React.Fragment key={"Changing " + index}>
                            <Typography>
                                {"Die Arbeitszeit wurde von " + item.modified_by_username + " am " + new Date(item.modified_at).toLocaleDateString() + " ignoriert."}
                            </Typography>
                            <Typography>
                                {"Grund: " + item.reason}
                            </Typography>
                            <hr/>
                        </React.Fragment>
                    )
                    break;
                case "set_ignored_to_false":
                    history.push(
                        <React.Fragment key={"Changing " + index}>
                            <Typography>
                            {"Die Arbeitszeit wurde von " + item.modified_by_username + " am " + new Date(item.modified_at).toLocaleDateString() + " nicht mehr ignoriert."}
                            </Typography>
                            <Typography>
                                {"Grund: " + item.reason}
                            </Typography>
                            <hr/>
                        </React.Fragment>
                    )
                    break;
                default:
                    break;
            }
        })

        return history;
    }

    return (
        <div className="shift-history-container">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Änderungshistorie
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {generateHistory()}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default SelectedShiftHistory;