import "./EmployeeList.css";
import EmployeeItem from "../employeeItem/EmployeeItem";
import {useEffect, useState} from "react";
import axios from "axios";
import LoadingSpinner from "../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
import Logout from "../../../../sharedComponents/Logout";

function EmployeeList(props) {

    const [activeIsLoading, setActiveIsLoading] = useState(true);
    const [inActiveIsLoading, setInActiveIsLoading] = useState(true);
    const [limitIsLoading, setLimitIsLoading] = useState(true)

    const [activeEmployees, setActiveEmployees] = useState(null);
    const [inActiveEmployees, setInActiveEmployees] = useState(null);

    const [limitEmployee, setLimitEmployee] = useState(null);
    const [currentEmployee, setCurrentEmployee] = useState(null);

    useEffect(() => {
        axios.get('employee/getActive').then(results => {
            setActiveEmployees(results.data);
            setActiveIsLoading(false);
        }).catch(() => { Logout()})

        axios.get('employee/getInactive').then(results => {
            setInActiveEmployees(results.data);
            setInActiveIsLoading(false);
        })  
    }, []);

    useEffect(() => {
        axios.get('info').then(results => {
            setLimitEmployee(results.data.max_employees);
            if(activeEmployees != null) {
                setCurrentEmployee(activeEmployees.length)
                setLimitIsLoading(false)
            }
        }).catch(() => { Logout()})
    }, [activeEmployees])

    function createActiveEmployees() {
        let activeProfiles = [];
        
        activeEmployees.map((item, index) => {
            let firstLastNameCombination = item.first_name + " " + item.last_name;
            let lastFirstNameCombination = item.last_name + " " + item.first_name;
            let idCombination = item.id;

            if (props.searchInputText === '' || firstLastNameCombination.toUpperCase().includes(props.searchInputText.toUpperCase()) || lastFirstNameCombination.toUpperCase().includes(props.searchInputText.toUpperCase()) || idCombination.toUpperCase().includes(props.searchInputText.toUpperCase())) {
                activeProfiles.push(
                    <Link to={'employeeView?employeeId=' + item.id} key={index}>
                        <EmployeeItem notCompleted={item.has_not_completed_shift} firstName={item.first_name}
                                      lastName={item.last_name} id={item.id} unresolvedShiftObjection={item.has_unresolved_shift_objection}/>
                    </Link>
                )
            }
        })

        return activeProfiles;
    }

    function createInactiveEmployees() {
        let inactiveProfiles = [];

            inActiveEmployees.map((item, index) => {
                let firstLastNameCombination = item.first_name + " " + item.last_name;
                let lastFirstNameCombination = item.last_name + " " + item.first_name;
                let idCombination = item.id;

                if (props.searchInputText === '' || firstLastNameCombination.toUpperCase().includes(props.searchInputText.toUpperCase()) || lastFirstNameCombination.toUpperCase().includes(props.searchInputText.toUpperCase()) || idCombination.toUpperCase().includes(props.searchInputText.toUpperCase())) {
                    inactiveProfiles.push(
                        <Link to={'employeeView?employeeId=' + item.id} key={index}>
                            <EmployeeItem firstName={item.first_name} lastName={item.last_name} id={item.id}/>
                        </Link>
                    )
                }
            })


        return inactiveProfiles;
    }

    if (activeIsLoading || inActiveIsLoading || limitIsLoading) {
        return (<LoadingSpinner/>);
    } else {
        return (
            <div className="employee-list-container">
                <p className="active-profile-header">Aktiviert <span className="text-gray" >({currentEmployee}/{limitEmployee})</span> </p>
                {createActiveEmployees()}
                <p className="inactive-profile-header">Deaktiviert</p>
                {createInactiveEmployees()}
            </div>
        )
    }

}

export default EmployeeList;