import "./CardAccessItem.css";

import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import CancelButton from "../../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";
import { Button } from "react-bootstrap";

function CardAccessItem(props) {

    const removeModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // Modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [removingCardError, setRemovingCardError] = useState(false);

    const [connection] = useState(props.connection);

    function createCardConnection() {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };

        const createdDate = new Date(connection.createdAt).toLocaleString('de-DE', options);

        return (
            <>
                <h5>Karte registriert</h5>
                <h6>Erstellt: {createdDate}</h6>
                <h6>RFID-Code: {connection.rfidCode}</h6>
            </>
        )
    }

    function createConnectionButtons() { 
        return (
            <>
                <Button variant="danger" onClick={handleOpen}>Löschen</Button>
            </>
        )  
    }

    const removeCard = () => {
        axios.delete('employee/physicalCard/disconnect?id=' + connection.id, {
        }).then(() => {
            sessionStorage.setItem("emplyoeeViewIndex", 5);
            sessionStorage.setItem("emplyoeeAccessOption", "Karten Zugang")
            window.location.reload();
            handleClose();
        }).catch(() => {
            setRemovingCardError(true);
        })
    }

    function removeCardModal() {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={removeModalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Karten Zugang löschen
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Möchten Sie diesen Karten Zugang wirklich löschen?
                    </Typography>
                    {removingCardError &&
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} color="red">
                        Ein Fehler beim Löschen ist aufgetreten. 
                    </Typography>}
                    <div className="app-adding-modal-buttons">
                        <CancelButton text="Abbrechen" onClick={handleClose} />
                        <PrimaryButton text="Zugang löschen" onClick={removeCard} />
                    </div>
                </Box>
            </Modal>
        )
    }

    return (
        <>
            <div className="card-connection-container">
                {/* Vielleicht Karten Icon einbauen */}
                <div className="card-connection-information">
                    {createCardConnection()}
                </div>
                <div className="card-connection-button-container">
                    {createConnectionButtons()}
                </div>
            </div>
            {removeCardModal()}
        </>
    )
}

export default CardAccessItem;