import "./Employees.css";
import EmployeeList from "./employeeList/EmployeeList";
import EmployeeCreation from "./creation/EmployeeCreation";
import {useEffect, useState} from "react";
import axios from "axios";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function Employees() {

    const [searchInputText, setSearchInputText] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        sessionStorage.setItem("emplyoeeViewIndex", 0);
    }, [searchInputText])

    function handleSearchBarChange(event) {
        setSearchInputText(event.target.value);
    }

    return (
        <>
            <div className="employee-header-container">
                <div className="mobile-top-header">
                    <div className="header-container">
                        <h1>Mitarbeiter</h1>
                    </div>
                    <div className="add-button-container">
                        <EmployeeCreation/>     
                        {/* logout not used in EmployeeCreation, need better distinction in errorcodes */}
                    </div>
                    <div className="csv-button-container">
                        <Link to={"csv"}>
                            <Button variant="contained" startIcon={<FileDownloadIcon />} style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}>
                                Export
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="search-bar-container input-group">
                    <span className="input-group-text" id="basic-addon1"><img className="search-logo" src={process.env.PUBLIC_URL + '/icons/suche.png'} alt="Ein Bild mit einer Lupe"/></span>
                    <input type="text" onChange={handleSearchBarChange} className="form-control" placeholder="Nach Mitarbeiter suchen..." aria-label="Mitarbeiter"
                           aria-describedby="basic-addon1"/>
                </div>
            </div>

            <EmployeeList searchInputText={searchInputText}/>
        </>
    )
}

export default Employees;