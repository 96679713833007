import "./Activity.css";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from 'rsuite';
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";
import CancelButton from "../../../../../../../sharedComponents/cancelButton/CancelButton";

function Activity(props) {

    const [time, setTime] = useState("");
    const [date, setDate] = useState("");

    const [updatedTime, setUpdatedTime] = useState("");

    const [reason, setReason] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const [showOtherReasonInput, setShowOtherReasonInput] = useState(false);

    const [missingInputError, setMissingInputError] = useState(false);
    const [updateTimeRequestError, setUpdateTimeRequestError] = useState(false);

    const [show, setShow] = useState(false);
    const handleShow = () => {
        setReason("");
        setOtherReason("");
        setMissingInputError(false);
        setUpdateTimeRequestError(false);
        setShow(true);
    }

    const handleClose = () => setShow(false);

    useEffect(() => {
        createDateAndTime();
    }, [])

    function createDateAndTime() {
        let currentDate = new Date(props.activityItem.timestamp).toLocaleDateString('de', { year: "numeric", month: "2-digit", day: "2-digit" });
        let currentTime = new Date(props.activityItem.timestamp).toLocaleTimeString('de', { hour: "2-digit", minute: "2-digit" });

        if (props.activityItem.generated) {
            setTime("");
            setDate("");
        } else {
            setDate(currentDate);
            setTime(currentTime);
        }

        setUpdatedTime(currentTime);
    }

    function createCorrectLogoSource() {
        let logoSource = "";
        let colorCode;

        if (props.activityItem.generated) {
            colorCode = "red";
        } else if (props.activityItem.edited) {
            colorCode = "orange";
        } else {
            colorCode = "green";
        }

        switch (props.activityItem.activity_type) {
            case "shift_begin":
                logoSource = process.env.PUBLIC_URL + '/icons/login-rounded-right/' + colorCode + '.png';
                break;
            case "pause_begin":
                logoSource = process.env.PUBLIC_URL + '/icons/cup/' + colorCode + '.png';
                break;
            case "pause_end":
                logoSource = process.env.PUBLIC_URL + '/icons/wrench/' + colorCode + '.png';
                break;
            case "shift_end":
                logoSource = process.env.PUBLIC_URL + '/icons/logout/' + colorCode + '.png';
                break;
            default:
                break;
        }

        return logoSource;
    }

    function createToolTipText() {
        let text = "";

        switch (props.activityItem.activity_type) {
            case "shift_begin":
                text = "Arbeit gestartet!";
                break;
            case "pause_begin":
                text = "Pause begonnen";
                break;
            case "pause_end":
                text = "Arbeit fortgesetzt";
                break;
            case "shift_end":
                text = "Arbeit beendet";
                break;
            default:
                break;
        }

        return text;
    }

    function createActivity() {
        return (
                <>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="button-tooltip-2">{createToolTipText()}</Tooltip>}>
                        <img className="icon"
                             src={createCorrectLogoSource()}
                             alt="Aktivitäts Bild"/>
                    </OverlayTrigger>
                    {createTimeInputWithEditableButton()}
                </>
            )
    }

    function createTimeInputWithEditableButton() {
        return (
            <>
                <p className="time-Input">{date + " - " + time + " (" + props.activityItem.id + ")"}</p>
                <PrimaryButton text="Bearbeiten" disabled={props.notEditable} onClick={() => handleShow()} />
            </>
        )
    }

    const changeReason = (event) => {
        switch (event.target.value) {
            case "Technisches Problem":
                setReason("Technisches Problem");
                setShowOtherReasonInput(false);
                break;
            case "Fehlerhaft eingetragen":
                setReason("Fehlerhaft eingetragen");
                setShowOtherReasonInput(false);
                break;
            case "Vergessen auszuloggen":
                setReason("Vergessen auszuloggen");
                setShowOtherReasonInput(false);
                break;
            case "Sonstiges":
                setReason("Sonstiges");
                setShowOtherReasonInput(true);
                setMissingInputError(false);
                break;
            default:
                break;
        }
    }

    function getDropdownReasons() {
        return props.activityItem.generated ?
            [
                <MenuItem key={"Technische Problem Menü"} value={"Technisches Problem"}>Technisches Problem</MenuItem>,
                <MenuItem key={"Vergessen auszuloggen Menü"}value={"Vergessen auszuloggen"}>Vergessen auszuloggen</MenuItem>,
                <MenuItem key={"Sonstiges Menü"}value={"Sonstiges"}>Sonstiges</MenuItem>
            ]
            :
            [
                <MenuItem key={"Technische Problem Menü"} value={"Technisches Problem"}>Technisches Problem</MenuItem>,
                <MenuItem key={"Fehlerhaft eingetragen Menü"}  value={"Fehlerhaft eingetragen"}>Fehlerhaft eingetragen</MenuItem>,
                <MenuItem key={"Sonstiges Menü"}  value={"Sonstiges"}>Sonstiges</MenuItem>
            ]
    }

    function renderActivityModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Uhrzeit anpassen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Uhrzeit</p>
                    <DatePicker format="HH:mm" oneTap ranges={[]} style={{ width: 260 }} onChange={handleChange} onSelect={handleChange} className="edit-time-input" value={new Date().setHours(updatedTime.split(":")[0], updatedTime.split(":")[1], 0)} locale={{hours: 'Stunden', minutes: 'Minuten',}}/>
                    <FormControl className="edit-reason-switch-container" fullWidth>
                            <InputLabel id="edit-reason-select-label">Bitte wählen Sie hier einen Grund aus</InputLabel>
                            <Select
                                labelId="edit-reason-select-label"
                                label="Bitte wählen Sie hier einen Grund aus"
                                value={reason}
                                onChange={changeReason}
                            >
                                {getDropdownReasons()}
                            </Select>
                    </FormControl>
                    {showOtherReasonInput && 
                        <div>
                            <p>Sonstiges</p>
                            <textarea className="other-reason-textarea" rows="2" placeholder="Geben Sie einen alternativen Grund an." onChange={(event) => setOtherReason(event.target.value)}></textarea>
                        </div>
                    }
                    {missingInputError && <p className="red">Bitte geben Sie einen Grund an.</p>}
                    {updateTimeRequestError && <p className="red">Ein Fehler beim Aktualisieren der Zeit ist aufgetreten.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Speichern" onClick={() => updateTime()} />
                </Modal.Footer>
            </Modal>
        )
    }

    const handleChange = (date) => {
        setUpdatedTime(date.toLocaleTimeString('de', { hour: "2-digit", minute: "2-digit" }));
    }

    function updateTime() {
        if(props.isLive){
            if (showOtherReasonInput) {
                if (otherReason !== "") {
                    sendLiveUpdateTimeRequest();
                } else {
                    setMissingInputError(true);
                }
            } else {
                if (reason !== "") {
                    sendLiveUpdateTimeRequest();
                } else {
                    setMissingInputError(true);
                }
            }
        } else {
            if (showOtherReasonInput) {
                if (otherReason !== "") {
                    sendUpdateTimeRequest();
                } else {
                    setMissingInputError(true);
                }
            } else {
                if (reason !== "") {
                    sendUpdateTimeRequest();
                } else {
                    setMissingInputError(true);
                }
            }
        }
        
    }

    function sendUpdateTimeRequest() {
        let updatedDate = evaluateTime();
        let currentReason;
        if(reason === "Sonstiges")
            currentReason = otherReason;
        else 
            currentReason = reason;

        axios.put('activity/updateById', {
            "shift_begin_id": props.beginId,
            "id": props.activityItem.id,
            "timestamp": updatedDate.toISOString(),
            "reason": currentReason
        }).then(response => {
            handleClose();
            window.location.reload();
        }).catch(error => {
            setUpdateTimeRequestError(true);
        })
    }

    function sendLiveUpdateTimeRequest() {
        let updatedDate = evaluateTime();
        let currentReason;
        if(reason === "Sonstiges")
            currentReason = otherReason;
        else 
            currentReason = reason;

        axios.put('activity/updateInOngoingShiftById', {
            "shift_begin_id": props.beginId,
            "id": props.activityItem.id,
            "timestamp": updatedDate.toISOString(),
            "reason": currentReason
        }).then(response => {
            handleClose();
            window.location.reload();
        }).catch(error => {
            setUpdateTimeRequestError(true);
        })
    }

    function evaluateTime() {
        let dateObject;

        let updatedTimeValue = (parseInt(updatedTime.split(":")[0] + updatedTime.split(":")[1]));

        if (props.index === 0) {
            let timeStampAfter;
            if(props.activityAfter === null)
                timeStampAfter = new Date();
            else
                timeStampAfter = new Date(props.activityAfter.timestamp);
                
            dateObject = new Date(timeStampAfter);
            dateObject.setHours(updatedTime.split(":")[0], updatedTime.split(":")[1], 0);

            let activityTimeAfterValue = parseInt(new Date(timeStampAfter).toTimeString().split(":")[0] + new Date(timeStampAfter).toTimeString().split(":")[1]);

            if (updatedTimeValue > activityTimeAfterValue)
                dateObject.setDate(dateObject.getDate() - 1);
        } else {
            let timeStampBefore = new Date(props.activityBefore.timestamp);
            dateObject = new Date(timeStampBefore);
            dateObject.setHours(updatedTime.split(":")[0], updatedTime.split(":")[1], 0);

            let activityTimeBeforeValue = parseInt(new Date(timeStampBefore).toTimeString().split(":")[0] + new Date(timeStampBefore).toTimeString().split(":")[1]);

            if (updatedTimeValue < activityTimeBeforeValue)
                dateObject.setDate(dateObject.getDate() + 1);
        }

        return dateObject;
    }

    return (
        <>
            {createActivity()}
            {renderActivityModal()}
        </>
    )
}

export default Activity;
