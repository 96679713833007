import "./Live.css";
import LiveList from "./liveList/LiveList";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Fab } from "@mui/material";

function Live() {

    function handleRefreshButton() {
        window.location.reload();
    }

    return (
        <>
            <div className="live-header-container">
                <div className="header-container">
                    <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/redCircle.png'} alt="Roter Kreis" />
                    <h2>Live <span className="gray">-&nbsp;</span></h2>
                    <h2 className="gray">{'Stand ' + new Date().toLocaleTimeString("de-DE", {hour:"2-digit", minute:"2-digit"})}</h2>
                </div>
                <div className="refresh-button-container">
                    <Fab color="primary" aria-label="add" size="small" onClick={() => handleRefreshButton()} className="add-mui-icon refresh-icon" style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}>
                        <RefreshIcon />
                    </Fab>
                </div>
            </div>
            <div className="live-list-parent-container">
                <LiveList/>
            </div>
        </>
    )
}

export default Live;