import "./CardAccessList.css";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import CardAccessItem from "../item/CardAccessItem";

function CardAccessList(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [connections, setConnections] = useState(null);

    useEffect(() => {
        getAllConnections();
    }, []);

    useEffect(() => {
        getAllConnections();
    }, [props.successfulCreating])

    function getAllConnections() {
        axios.get('employee/physicalCard/getAllForEmployeeId?id=' + sessionStorage.getItem("employeeId")).then(results => {
            setConnections(results.data);
            setIsLoading(false);
        })
    }

    function createConnections() {
        let cardConnections = [];

        connections.map((item, index) => {
            cardConnections.push(
                <CardAccessItem key={"Card Connection: " + index} connection={item}/>
            )
        })

        return cardConnections;
    }

    function createNoConnectionsText() {
        if (connections.length === 0)
            return (<h5>Keinen Zugang eingerichtet.</h5>)
    }

    return isLoading ?
        <LoadingSpinner />
    :
        <div className="card-connection-list-container">
            {createConnections()}
            {createNoConnectionsText()}
        </div>
}

export default CardAccessList;