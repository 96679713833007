import { ListItemButton } from "@mui/material";
import "./LiveItem.css";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";

function LiveItem(props) {

    const handleEmployeeLiveShiftClick = () => {
        sessionStorage.setItem("currentNavItem", "employees");
        sessionStorage.setItem("emplyoeeViewIndex", "0");
    }

    function createEmployees() {
        let currentEmployees = [];

        props.employees.forEach((employee, index) => {
            let color;
            let workingText;

            if (employee.working_status === 'working') {
                color = 'green';
                workingText = 'Am arbeiten';
            } else {
                color = 'orange';
                workingText = 'In einer Pause';
            }
            
            currentEmployees.push(
                <div className="live-employee-container" key={index}>                    
                    <ListItemButton component={Link} to={"/home/employees/employeeView?employeeId=" + employee.id} onClick={handleEmployeeLiveShiftClick}>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">{workingText}</Tooltip>}
                        >
                            <div style={{"backgroundColor": color}} className="circle"></div>
                        </OverlayTrigger>
                        <p>{employee.first_name + " " + employee.last_name} <span
                            className="lighter-text"> {"(Arbeitet seit " + new Date(employee.since_when_in_shift).toLocaleTimeString("de-DE", {hour:"2-digit", minute:"2-digit"}) + ")"}</span>
                        </p>
                    </ListItemButton>
                    
                </div>
            )
        })

        return currentEmployees;
    }

    return (
        <div className="live-item-container">
            <p className="live-item-header">{props.locationName} <span
                className="lighter-text">{" - " + props.count + " aktive Mitarbeiter"}</span></p>
            {createEmployees()}
        </div>
    )
}

export default LiveItem;