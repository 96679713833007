import "./CreateShiftBeginInput.css";
import deDe from 'rsuite/locales/de_DE';
import {CustomProvider, DatePicker, Message} from "rsuite";
import {useEffect, useState} from "react";

function CreateShiftBeginInput(props) {

    const [startDate, setStartDate] = useState(null);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

    useEffect(() => {
        setStartDateInBeginning();
    }, []) 

    function setStartDateInBeginning() {
        if (sessionStorage.getItem("new-shift-begin-date") !== null) {
            let date = sessionStorage.getItem("new-shift-begin-date");
            let [day, month, year] = date.split('.'); 
            let reversedDate = new Date(`${year}-${month}-${day}`);
            setStartDate(reversedDate);
        }
    }

    function saveDate(date) {
        setStartDate(date);
        sessionStorage.setItem("new-shift-begin-date", date.toLocaleString('de-DE', options));
        sessionStorage.removeItem("new-shift-current-activities");
        props.setNoSelection("");
    }

    function disabledDate(currentDate) {
        const today = new Date();
        return currentDate > today;
    }

    return (
        <>
            <Message className="create-shift-begin-input-message" showIcon type="info">An welchem Tag hat die Arbeitszeit begonnen?</Message>
            <CustomProvider locale={deDe}>
                <DatePicker className={"create-shift-datepicker " + props.noSelection} isoWeek oneTap format={"dd.MM.yyyy"} 
                        onSelect={dateText => saveDate(dateText)} onChange={dateText => saveDate(dateText)} 
                        value={startDate} disabledDate={disabledDate} mon
                        />
            </CustomProvider>
        </>
    )
}

export default CreateShiftBeginInput;