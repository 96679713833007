import "./Licenses.css";
import CookieBanner from "../../sharedComponents/CookieBanner";
import Footer from "../../sharedComponents/footer/Footer";
import BackButton from "../../sharedComponents/backButton/BackButton";
import { Link } from "react-router-dom";

function Licenses() {
    return (
        <>
            <div className="licenses-container">
                <Link to={"/home/live/"}><BackButton text="Zurück" /></Link>
                <h1>Lizenzen</h1>
                <p>Für Informationen der verwendeten Lizenzen, gerne ans uns wenden (info@tictrack.de).</p>
            </div>
            <Footer />
            <CookieBanner />
        </>
    )
}

export default Licenses;