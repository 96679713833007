import "./HolidayChanges.css";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import formatDuration from "../../../../../../../sharedComponents/FormatDuration";
import parseDuration from "../../../../../../../sharedComponents/ParseDuration";
import FederalStates from "../../../../../../../sharedComponents/FederalStates";
import axios from "axios";
import CancelButton from "../../../../../../../sharedComponents/cancelButton/CancelButton";

let options = { day: '2-digit', month: '2-digit', year: 'numeric' };

function HolidayChanges(props) {

    const [holidays] = useState(props.holidays);
    const [removingChangeError, setRemovingChangeError] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    // Removing Modal
    const [openRemovingModal, setOpenRemovingModal] = useState(false);
    const handleCloseRemovingModal = () => setOpenRemovingModal(false);

    function getDisplayNameByInternName(internName) {
        const state = FederalStates.find(state => state.internName === internName);
        return state ? state.displayName : 'Unbekannt';
    }

    function generateChanges() {
        let changeshtml = [];

        holidays.forEach((item, index) => {
            let itemDate = new Date(item.since_when);
            let federalState;
            if (item.bundesland === "NONE")
                federalState = "Keine Bezahlung mehr"
            else
                federalState = getDisplayNameByInternName(item.bundesland);

            changeshtml.push(
                <ListItem
                    key={"Item: " + index}
                    secondaryAction={
                    <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedDate(item.since_when)}}>
                        <ClearIcon color="error" />
                    </IconButton>
                    }
                >
                    <ListItemText secondary={formatDuration(parseDuration(item.standard_duration))}>{"Ab: " + itemDate.toLocaleDateString('de-DE', options) + " - " + federalState} </ListItemText>
                </ListItem>
            )
        })

        return changeshtml;
    }

    function createModal() {
        return (
            <Modal
                show={openModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Übersicht Änderungen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <List>
                        {generateChanges()}
                    </List>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton text="Okay" onClick={handleClose}/>
                </Modal.Footer>
            </Modal>
        )
    }

    function sendDeleteRequest() {
        axios.put("employee/deleteBundeslandModification", {
            "employee_id": new URLSearchParams(window.location.search).get("employeeId"),
            "since_when": selectedDate
        }).then(() => {
            window.location.reload();
        }
        ).catch(error => {
            setRemovingChangeError(true);
        })
    }

    function getNextDateAfterStartDate() {
        let nextDate = null;
    
        for (let holiday of holidays) {
            const holidayDate = new Date(holiday.since_when);
            if (holidayDate > new Date(selectedDate)) {
                if (!nextDate || holidayDate < nextDate) {
                    nextDate = holidayDate;
                }
            }
        }
    
        if (!nextDate) {
            nextDate = new Date(new Date(selectedDate).getFullYear() + 1, 11, 31);
        }
    
        return nextDate;
    }

    function generateWarnText() {
        const nextDate = getNextDateAfterStartDate();
        return (
            <p className="red">{"Achtung. Die ausgewählte Regel wird im Intervall gelöscht:"} <strong>{new Date(selectedDate).toLocaleDateString() + "-" + nextDate.toLocaleDateString()}</strong><br/> {"Betroffene Feiertage werden eventuell mit vorherigen Regeln überschrieben."}</p>
        )
    }

    function createRemovingModal() {
        return (
            <Modal
                show={openRemovingModal}
                onHide={handleCloseRemovingModal}
                keyboard={true}
            >
                <Modal.Header>
                        <Modal.Title>Änderung löschen</Modal.Title>
                        <button type="button" className="close" aria-label="Close" onClick={handleCloseRemovingModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Möchten Sie diese Änderung wirklich löschen?</p> 
                        {generateWarnText()}
                        {removingChangeError && <p className="red">Ein Fehler ist aufgetreten.</p>}              
                    </Modal.Body>
                    <Modal.Footer>
                        <CancelButton text="Abbrechen" onClick={handleCloseRemovingModal} />
                        <Button variant="danger" onClick={() => sendDeleteRequest()}>Änderung löschen</Button>
                    </Modal.Footer>
            </Modal>
        )
    }

    return holidays.length > 0 ?
        <>
            <Button className="holiday-change-button" variant="outline-secondary" onClick={handleOpen}>Übersicht Änderungen</Button> 
            {createModal()}
            {createRemovingModal()}
        </>
        :
        <>
        </>
}

export default HolidayChanges;