import "./Navbar.css";
import { Container, Nav, Navbar, NavbarBrand } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';

function SettingsNavbar() {

    const navigate = useNavigate();
    const [clickedNavItem, setClickedNavItem] = useState("live");
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    useEffect(() => {
        if (sessionStorage.getItem("currentNavItem") !== null)
            setClickedNavItem(sessionStorage.getItem("currentNavItem"));
    }, [])

    const handleNavbarClick = (navItem) => {
        setClickedNavItem(navItem);
        sessionStorage.setItem("currentNavItem", navItem);
        setIsNavExpanded(false);
    }

    const handleNavToggle = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    return (
        <>
            <base href="/settings/" />
            <Navbar variant="dark" expand="xl" expanded={isNavExpanded} style={{ backgroundColor: 'var(--tictrack-gray-color)' }}>
                <Container>
                    <NavbarBrand>
                        TicTrack
                    </NavbarBrand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleNavToggle} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to={"dashboard/"} onClick={() => handleNavbarClick("dashboard")} className={`${clickedNavItem === 'dashboard' ? 'clicked' : ''}`}>
                                <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/dashboard.png'} alt="Zeitung" />
                                Dashboard   
                            </Nav.Link>
                            <Nav.Link as={Link} to={"planning/"} onClick={() => handleNavbarClick("planning")} className={`${clickedNavItem === 'planning' ? 'clicked' : ''}`}>
                                <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/planning.png'} alt="Ort-Icon" />
                                Planung
                            </Nav.Link>
                            <Nav.Link as={Link} to={"locations/"} onClick={() => handleNavbarClick("locations")} className={`${clickedNavItem === 'locations' ? 'clicked' : ''}`}>
                                <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/location.png'} alt="Ort-Icon" />
                                Standorte
                            </Nav.Link>
                            <Nav.Link as={Link} to={"employees/"} onClick={() => handleNavbarClick("employees")} className={`${clickedNavItem === 'employees' ? 'clicked' : ''}`}>
                                <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/employee.png'} alt="Mitarbeiter-Icon" />
                                Mitarbeiter
                            </Nav.Link>
                            <Nav.Link as={Link} to={"settings/"} onClick={() => handleNavbarClick("settings")} className={`${clickedNavItem === 'settings' ? 'clicked' : ''}`}>
                                <img className="standard-nav-icon" src={process.env.PUBLIC_URL + '/icons/einstellungen.png'} alt="Mitarbeiter-Icon" />
                                Einstellungen
                            </Nav.Link>
                            <Nav.Link className="logout-button-link">
                                <Button variant={"danger"} onClick={() => logOut()}>Ausloggen</Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );

    function logOut() {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
    }
}

export default SettingsNavbar;
