import "./Policy.css";
import CookieBanner from "../../sharedComponents/CookieBanner";
import Footer from "../../sharedComponents/footer/Footer";
import { Link } from "react-router-dom";
import BackButton from "../../sharedComponents/backButton/BackButton";

function Policy() {
    return(
        <>
            <div className="policy-container">
            <Link to={"/home/live/"}><BackButton text="Zurück" /></Link>
                <h2>Datenschutzerklärung</h2>
                <p>Wir, die TicTrack UG (haftungsbeschränkt), betreiben www.tictrack.de, www.app.tictrack.de, www.company.tictrack.de, www.client.tictrack.de und erfassen bestimmte Daten von Ihnen, soweit dies erforderlich ist. In der folgenden Datenschutzerklärung erfahren Sie, was wir mit Ihren Daten, sog. personenbezogene Daten, machen und warum wir dies tun. Außerdem teilen wir Ihnen mit, wie wir Ihre Daten schützen, wann die Daten gelöscht werden und welche Rechte Sie dank des Datenschutzes haben.</p>
                <p className="strong-text">An wen kann ich mich wenden?</p>
                <p>Verantwortlich für diese Webseite ist:</p>
                <div className="policy-block">
                    <p>TicTrack UG (haftungsbeschränkt)</p>
                    <p>Heinrich-Imbusch-Str. 30</p>
                    <p>45665 Recklinghausen</p>
                    <p>datenschutz@tictrack.de</p>
                </div>
                <p>Über diese Kontaktdaten erreichen Sie auch unseren <span className="strong-text">Datenschutzbeauftragten oder einen anderen datenschutzrelevanten Kontakt</span>. Wenden Sie sich bitte jederzeit an uns, falls Sie konkrete Fragen zu Ihren Daten, deren Löschung oder Ihren Rechten haben.</p>
                <p className="strong-text">Was sind meine Rechte?</p>
                <p>Sie können sich jederzeit an uns wenden, wenn Sie Fragen zu Ihren Rechten im Datenschutz haben oder Ihre nachfolgenden Rechte geltend machen möchten:</p>
                <ul>
                    <li><span className="strong-text">Widerrufsrecht</span> gem. Art. 7 Abs. 3 DSGVO (z. B. können Sie sich an uns wenden, falls Sie eine zuvor erteilte Einwilligung in einen Newsletter rückgängig machen möchten)</li>
                    <li><span className="strong-text">Recht auf Auskunft</span> gem. Art. 15 DSGVO (z. B. können Sie sich an uns wenden, falls Sie wissen möchten, welche Daten wir über Sie gespeichert haben)</li>
                    <li><span className="strong-text">Berichtigung</span> gem. Art. 16 DSGVO (z. B. können Sie sich an uns wenden, falls sich Ihre E-Mail-Adresse geändert hat und wir die alte E-Mail-Adresse ersetzen sollen) </li>
                    <li><span className="strong-text">Löschung</span> gem. Art. 17 DSGVO (z. B. können Sie sich an uns wenden, falls wir bestimmte Daten löschen sollen, die wir über Sie gespeichert haben)</li>
                    <li><span className="strong-text">Recht auf Einschränkung der Verarbeitung</span> gem. Art. 18 DSGVO (z. B. können Sie sich an uns wenden, falls Sie möchten, dass wir Ihre E-Mail-Adresse zwar nicht löschen, aber nur noch zur Versendung von unbedingt erforderlichen E-Mails verwenden)</li>
                    <li><span className="strong-text">Recht auf Datenübertragbarkeit</span> gem. Art. 20 DSGVO (z. B. können Sie sich an uns wenden, um Ihre bei uns gespeicherten Daten in einem komprimierten Format zu erhalten, z. B. weil Sie die Daten einer anderen Webseite zur Verfügung stellen wollen)</li>
                    <li><span className="strong-text">Widerspruch</span> gem. Art. 21 DSGVO (z. B. können Sie sich an uns wenden, falls Sie mit einem der hier angegebenen Werbe- oder Analyseverfahren nicht einverstanden sind) <br /></li>
                    <li><span className="strong-text">Beschwerderecht</span> bei der zuständigen Aufsichtsbehörde gem. Art. 77 Abs. 1 DSGVO (z. B. können Sie sich bei Beschwerden auch direkt an die Datenschutz-Aufsichtsbehörde in Ihrem Bundesland wenden: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html)</li>
                </ul>
                <p className="strong-text">Löschung von Daten und Speicherdauer</p>
                <p>Sofern nicht anders angegeben, löschen wir Ihre Daten, sobald diese nicht mehr benötigt werden, also z. B. Ihre E-Mail-Adresse nach der Abmeldung von unserem Newsletter. Eine Sperrung oder Löschung Ihrer Daten erfolgt auch dann, wenn eine Aufbewahrungsfrist abläuft. Bestimmte Daten müssen womöglich aus gesetzlichen Gründen länger aufbewahrt werden. Sie können selbstverständlich jederzeit Auskunft über die gespeicherten Daten verlangen. Datenschutzanfragen und andere rechtliche Belange können ebenfalls über einen längeren Zeitraum gespeichert werden im Rahmen der gesetzlich relevanten Aufbewahrungs- sowie Verjährungsfristen.</p>
                <p className="strong-text">Besuch der Webseite</p>
                <p>Falls Sie sich bloß auf unserer Webseite umschauen wollen, erfassen wir keine personenbezogenen Daten, mit Ausnahme der Daten, die Ihr Browser übermittelt, um den Besuch der Webseite zu ermöglichen, allen voran:</p>
                <ul>
                    <li>IP-Adresse (z. B. 81.91.215.beispiel oder 2a02:8109:9440:1198:bdb1:551f:beispiel)</li>
                    <li>Ungefähre Ortsbestimmung anhand IP-Bereich (z. B. Berlin)</li>
                    <li>Internet-Anbieter (z. B. Vodafone oder Deutsche Telekom)</li>
                    <li>Internet-Geschwindigkeit (z. B. 120 Mbit)</li>
                    <li>Datum und Uhrzeit (z. B. 11:55 am 25.05.2023)</li>
                    <li>Zuletzt besuchte Webseite (z. B. google.de)</li>
                    <li>Browser (z. B. Chrome oder Safari)</li>
                    <li>Betriebssystem (z. B. Mac OS)</li>
                    <li>Hardware (z. B. Intel Prozessor)</li>
                </ul>
                <p>Als <span className="strong-text">Schutzmaßnahme</span> zugunsten Ihrer Privatsphäre, löschen oder anonymisieren wir die IP-Adresse nach Ihrem Besuch unserer Webseite. Somit können die anderen, technischen Daten, nicht mehr auf Sie zurückgeführt werden und dienen nur noch anonymen, statistischen Zwecken zur Optimierung unserer Webseite. <span className="strong-text">Zweck</span> der vorrübergehenden Speicherung der Daten ist einerseits die technische Erforderlichkeit zur Verbindungsherstellung sowie die korrekte, fehlerfreie Darstellung unserer Webseite. Die IP-Adresse und die bereits genannten technischen Daten sind erforderlich, um die Webseite anzuzeigen, Darstellungsprobleme bei den Besuchern zu vermeiden und Fehlermeldungen zu beheben. <span className="strong-text">Rechtsgrundlage</span> ist das sogenannte berechtigte Interesse, das im Rahmen der vorgenannten Schutzmaßnahmen sowie in Einklang mit den europäischen Datenschutzanforderungen aus Art. 6 Abs. 1 lit. f) DSGVO geprüft wurde.</p>
                <p className="strong-text">Kontakt</p>
                <p>Sie haben die Möglichkeit, sich über unser Kontaktformular oder auf anderen Wegen mit uns in Verbindung zu setzen. Folgende Daten können Sie uns dabei mitteilen:</p>
                <ul>
                    <li>Vor-/Nachname</li>
                    <li>Telefon</li>
                    <li>E-Mail-Adresse</li>
                    <li>Mitteilung bzw. Anfrage</li>
                </ul>
                <p>Als <span className="strong-text">Schutzmaßnahme</span> erfolgt die Kontaktaufnahme – genauso wie der Besuch der übrigen Webseite – über eine verschlüsselte Verbindung. Sie können sich auch für andere Formen der Kontaktaufnahme entscheiden. Nach der erfolgreichen Kontaktaufnahme und abgeschlossenen Kontaktanfrage, werden Ihre Daten gelöscht. <span className="strong-text">Zweck</span> der abgefragten Daten ist einzig und allein die Kontaktaufnahme bzw. Kommunikation mit Ihnen, weshalb die Daten auch nur hierfür verwendet werden. <span className="strong-text">Rechtsgrundlage</span> ist das sogenannte berechtigte Interesse, das zur Verfolgung des Zwecks und im Rahmen der vorgenannten Schutzmaßnahmen sowie in Einklang mit den europäischen Datenschutzanforderungen aus Art. 6 Abs. 1 lit. f) DSGVO geprüft wurde.</p>
                <p className="strong-text">Produkt (Registrierung)</p>
                <p>Sie haben zudem die Möglichkeit, sich auf unserer Webseite zu registrieren und sich danach jederzeit mit einem Benutzerkonto anzumelden. Um sich bei uns zu registrieren, werden folgende Daten benötigt:</p>
                <ul>
                    <li>Vor-/Nachname</li>
                    <li>Adresse</li>
                    <li>Kontaktdaten</li>
                    <li>Benutzername / Email-Adresse</li>
                    <li>Passwort</li>
                </ul>
                <p>Als <span className="strong-text">Schutzmaßnahme</span> erfolgt die Übermittlung der von Ihnen eingegebenen Daten – genauso wie der Besuch der übrigen Webseite – über eine verschlüsselte Verbindung. Nach erfolgreicher Bestätigung werden Ihre Daten solange gespeichert bis Sie sich entscheiden, einzelne Daten oder das gesamte Benutzerkonto zu löschen oder eine Löschfrist nach Kündigung eines Vertrages verfällt. <span className="strong-text">Zweck</span> der abgefragten Daten ist die Erstellung eines Benutzerkontos zur Nutzung erweiterter Funktionen auf der Webseite. Die Registrierung erfolgt freiwillig und kann jederzeit widerrufen bzw. die Benutzerdaten gelöscht werden. <span className="strong-text">Rechtsgrundlage</span> ist Ihre Einwilligung in Einklang mit den europäischen Datenschutzanforderungen aus Art. 6 Abs. 1 lit. a) DSGVO. In Fällen, in denen die Registrierung sowie Kontonutzung für einen zugrundeliegenden Vertragsabschluss relevant ist, ist die Rechtsgrundlage die gegenseitige Vertragserfüllung in Einklang mit den europäischen Datenschutzanforderungen aus Art. 6 Abs. 1 lit. b) DSGVO. Sofern Sie zugleich in der Rolle eines oder einer Beschäftigten oder Angestellten des jeweiligen Unternehmens auftreten, dient die zugrundeliegende Verarbeitung im Rahmen der Zeiterfassung der Durchführung, Ausübung oder Erfüllung des gegenseitigen Beschäftigungsverhältnisses gemäß § 26 Abs. 1 BDSG.</p>
                <p className="strong-text">Produkt (Ansicht Unternehmensinhaber_in)</p>
                <p>Als Unternehmensinhaber_in (company.tictrack.de) haben Sie die Möglichkeit, das Unternehmenskonto zu bearbeiten, Zahlungsinformationen zu verwalten, Administratorenkonten zu vergeben und allgemeine Einstellungen zur Zeiterfassung zu konfigurieren.</p>
                <p>Folgende Daten werden je nach Einsatzzweck in dieser Ansicht verarbeitet:</p>
                <ul>
                    <li>Email-Adresse</li>
                    <li>Stripe-Nutzer-ID (Verweis)</li>
                    <li>Anzeige-Name</li>
                    <li>Information über das gebuchte Abonnement</li>
                    <li>Information über verknüpfte Administratoren</li>
                    <li>Passwort</li>
                </ul>
                <p>Als <span className="strong-text">Schutzmaßnahme</span> unterliegt der Bereich Unternehmensinhaber_in strengen Berechtigungskonzepten und erfordert starke Passwörter sowie eine Bestätigung per E-Mail. Alle eingegebenen Daten sind von unserem Sicherheitskonzept erfasst und werden verschlüsselt. Zahlungsdaten werden stark verschlüsselt durch unseren PCI-DSS zertifizierten Zahlungsdienstleister verarbeitet. <span className="strong-text">Zweck</span> der verarbeiteten Daten ist die Bereitstellung des Produkts und der beschriebenen Ansicht zwecks Verwendung oder Verwaltung der Zeiterfassungsfunktionen. <span className="strong-text">Rechtsgrundlage</span> ist, in Fällen, in denen die Registrierung sowie Kontonutzung für einen zugrundeliegenden Vertragsabschluss relevant ist, die gegenseitige Vertragserfüllung in Einklang mit den europäischen Datenschutzanforderungen aus Art. 6 Abs. 1 lit. b) DSGVO. Sofern Sie zugleich in der Rolle eines oder einer Beschäftigten oder Angestellten des jeweiligen Unternehmens auftreten, dient die zugrundeliegende Verarbeitung im Rahmen der Zeiterfassung der Durchführung, Ausübung oder Erfüllung des gegenseitigen Beschäftigungsverhältnisses gemäß § 26 Abs. 1 BDSG.</p>
                <p className="strong-text">Produkt (Ansicht Administrator_in)</p>
                <p>Als Administrator_in (app.tictrack.de) haben Sie die Möglichkeit, eine Live-Ansicht, Standorte und Arbeitnehmerdaten zu betrachten. In der Live-Ansicht werden die zugehörigen Arbeitnehmer, Standorte und Zeitstempel des letzten Logins bzw. Logouts angezeigt. Unter der Ansicht Standorte können Unternehmensstandorte oder andere relevante Orte festgelegt und zugewiesen werden. Innerhalb der Ansicht Arbeitnehmer können Administratoren Einstellungen zu Arbeitnehmern sowie deren Daten bearbeiten, diese entsprechen grundsätzlich den Datenkategorien der Ansicht des nächsten Abschnitts (<span class="italic">Ansicht Arbeitnehmer_in</span>). Zudem können hier Arbeitszeiten eingesehen und bearbeitet sowie nachgetragen werden. Zuletzt können auch Zugänge verwaltet und freigeschaltet werden. Hierzu kann ein entsprechendes Dokument erstellt und exportiert werden, das Arbeitnehmern den Download per Google Play bzw. Apple App Store (dort gelten die Datenschutzhinweise von Google und Apple) und die anschließende Freischaltung des Zugangs ermöglicht.</p>
                <p>Folgende Daten werden je nach Einsatzzweck in dieser Ansicht verarbeitet:</p>
                <ul>
                    <li>Nutzername</li>
                    <li>Anzeige-Name</li>
                    <li>Verknüpftes Unternehmen</li>
                    <li>Passwort</li>
                </ul>
                <p>Als <span className="strong-text">Schutzmaßnahme</span> unterliegt der Bereich Administrator_in strengen Berechtigungskonzepten und erfordert starke Passwörter sowie eine Bestätigung per E-Mail. Alle eingegebenen Daten sind von unserem Sicherheitskonzept erfasst und werden verschlüsselt. Es erfolgt außerdem eine Protokollierung (Logging) bei Änderungen innerhalb der Zeiterfassung, um unerwünschte oder unberechtigte Änderungen zu erfassen und nachzuweisen. <span className="strong-text">Zweck</span> der verarbeiteten Daten ist die Bereitstellung des Produkts und der beschriebenen Ansicht zwecks Verwendung oder Verwaltung der Zeiterfassungsfunktionen. <span className="strong-text">Rechtsgrundlage</span> ist, in Fällen, in denen die Registrierung sowie Kontonutzung für einen zugrundeliegenden Vertragsabschluss relevant ist, die gegenseitige Vertragserfüllung in Einklang mit den europäischen Datenschutzanforderungen aus Art. 6 Abs. 1 lit. b) DSGVO. Sofern Sie zugleich in der Rolle eines oder einer Beschäftigten oder Angestellten des jeweiligen Unternehmens auftreten, dient die zugrundeliegende Verarbeitung im Rahmen der Zeiterfassung der Durchführung, Ausübung oder Erfüllung des gegenseitigen Beschäftigungsverhältnisses gemäß § 26 Abs. 1 BDSG.</p>
                <p className="strong-text">Produkt (Ansicht Arbeitnehmer_in)</p>
                <p>Als Arbeitnehmer_in (“TicTrack Mitarbeiter”-App) haben Sie die Möglichkeit, die zur Zeiterfassung erforderliche App im Google Play bzw. Apple App Store (dort gelten die Datenschutzhinweise von <a href="https://policies.google.com/privacy?hl=de">Google</a> und <a href="https://www.apple.com/legal/privacy/de-ww/">Apple</a>) herunterzuladen. Hierzu ist ein QR-Code oder Login erforderlich, der vom Arbeitgeber bereitgestellt wird. Ein Erfassen der Arbeitszeit erfolgt entweder standortbezogen über das Scannen eines Login-QR-Codes am Standort innerhalb der App oder standortunabhängig über den mobilen Login innerhalb der App. Letztere Funktion muss jedoch für Sie spezifisch seitens des Arbeitgebers freigeschaltet sein, damit Sie diese nutzen können. Zudem bietet Ihnen die vorliegende Ansicht in der App die Möglichkeit, Arbeitszeiten einzusehen und allgemeine Einstellungen vorzunehmen. Indirekt erfolgt eine Verarbeitung Ihrer Arbeitszeiten und Aktivitäten durch das Unternehmen bzw. die Adminstratoren. Hierdurch kann eingesehen werden, wann und wo Sie sich eingeloggt haben und zudem in welchem Status Sie sich gerade befinden. (z. B. „Pause“).</p>
                <p>Folgende Daten werden je nach Einsatzzweck in dieser Ansicht verarbeitet:</p>
                <ul>
                    <li>Personalnummer</li>
                    <li>Vorname</li>
                    <li>Nachname</li>
                    <li>Anstellungsverhältnis</li>
                    <li>Aktivierungsstatus</li>
                    <li>Veknüpfte Mitarbeiterkarten-Nummer</li>
                    <li>Handy-Modell (Bei Nutzung der App)</li>
                </ul>
                <p>Als <span className="strong-text">Schutzmaßnahme</span> unterliegt der Bereich Arbeitnehmer_in strengen Berechtigungskonzepten und erfordert die Aktivierung der App durch ein sicheres Authentifizierungsverfahren. Alle eingegebenen Daten sind von unserem Sicherheitskonzept erfasst und werden verschlüsselt Zum Schutz der Arbeitnehmer verwenden wir zudem datenschutzfreundliche Voreinstellungen und Maßnahmen zur datenschutzfreundlichen Technikgestaltung, um möglichst wenige Daten zu erfassen oder diese zu anonymisieren. <span className="strong-text">Zweck</span> der verarbeiteten Daten ist die Bereitstellung des Produkts und der beschriebenen Ansicht zwecks Verwendung oder Verwaltung der Zeiterfassungsfunktionen. <span className="strong-text">Rechtsgrundlage</span> ist, in Fällen, in denen die Registrierung sowie Kontonutzung für einen zugrundeliegenden Vertragsabschluss relevant ist, die gegenseitige Vertragserfüllung in Einklang mit den europäischen Datenschutzanforderungen aus Art. 6 Abs. 1 lit. b) DSGVO. Sofern Sie zugleich in der Rolle eines oder einer Beschäftigten oder Angestellten des jeweiligen Unternehmens auftreten, dient die zugrundeliegende Verarbeitung im Rahmen der Zeiterfassung der Durchführung, Ausübung oder Erfüllung des gegenseitigen Beschäftigungsverhältnisses gemäß § 26 Abs. 1 BDSG.</p>
                <p className="strong-text">Cookies</p>
                <p>Unsere Webseite verwendet teilweise so genannte Cookies. Bei Cookies handelt es sich um kleine Textdateien, die üblicherweise in einem Ordner Ihres Browsers gespeichert werden. Cookies enthalten Informationen über den aktuellen bzw. letzten Besuch der Webseite:</p>
                <ul>
                    <li>Name der Webseite</li>
                    <li>Ablaufdatum des Cookies</li>
                    <li>Beliebiger Wert</li>
                </ul>
                <p>Sofern Cookies kein genaues Ablaufdatum enthalten, werden diese nur zwischengespeichert und automatisch gelöscht, sobald Sie Ihren Browser schließen bzw. das Endgerät neustarten. Cookies mit einem Ablaufdatum bleiben auch gespeichert, wenn Sie Ihren Browser schließen bzw. das Endgerät neustarten. Solche Cookies werden erst zum angegebenen Datum entfernt oder wenn Sie diese manuell löschen.</p>
                <p>Auf unserer Webseite verwenden wir folgende drei Arten von Cookies:</p>
                <ul>
                    <li>Erforderliche Cookies (diese benötigen wir, z. B. um die Webseite für Sie korrekt darzustellen und bestimmte Einstellungen zwischenzuspeichern)</li>
                    <li>Funktions- und leistungsbedingte Cookies (diese helfen uns dabei, z. B. technische Daten Ihres Besuchs auszuwerten und somit Fehlermeldungen zu vermeiden)</li> 
                    <li>Werbe- und analysebedingte Cookies (diese sorgen dafür, dass z. B. Werbung für Schuhe angezeigt wird, wenn Sie zuvor nach Schuhen gesucht haben)</li>
                </ul>
                <p>Sie können Cookies in den Einstellungen Ihres Browsers konfigurieren, blockieren und löschen. Falls Sie alle Cookies unserer Webseite löschen, kann es sein, dass einige Funktionen der Webseite nicht korrekt dargestellt werden. Hilfreiche Informationen und Anleitungen für die gängigen Browser stellt das Bundesamt für Sicherheit in der Informationstechnik bereit: https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/EinrichtungSoftware/EinrichtungBrowser/Sicherheitsmassnahmen/Cookies/cookies_node.html</p>
                <p className="strong-text">Empfänger von Daten</p>
                <p>Entsprechend der oben angegebenen Beschreibungen und Zwecke, teilen wir Ihre Daten mit folgenden Empfängern, die wesentlich zur Bereitstellung unseres Angebots und der Kommunikation mit Ihnen sind:</p>
                <ul>
                    <li><span className="strong-text">Strato</span>, betrieben von der STRATO AG mit Hauptsitz in Berlin (Deutschland). Strato ist ein Internetdienstanbieter inbesondere im Bereich Hosting. Die Daten werden innerhalb der Europäischen Union verarbeitet. Weitere Informationen finden Sie in der <a href="https://www.strato.de/datenschutz/">Datenschutzerklärung</a> von STRATO.</li>
                    <li><span className="strong-text">Stripe</span>, betrieben von der Stripe Payments Europe Limited mit Hauptsitz in den USA. Diesen Dienstleister setzen wir für den Zahlungsverkehr ein. Abhängig von Ihrem Standort werden die Daten entweder innerhalb der Europäischen Union oder in den Vereinigten Staaten verarbeitet. Weitere Informationen finden Sie in der <a href="https://stripe.com/de/privacy">Datenschuzterklärung</a> von Stripe.</li>
                    <li><span className="strong-text">Satellite</span>, betrieben von Sipgate GmbH mit Sitz in Düsseldorf (Deutschland). Dieser Dienstleister stellt Telekommunikations-dienstleistungen zur Verfügung. Die Daten werden innerhalb der Europäischen Union verarbeitet. Weitere Informationen finden Sie in der <a href="https://www.satellite.me/datenschutz">Datenschutzerklärung</a> von Sipgate.</li>
                    <li><span className="strong-text">Cortado MDM</span>, betrieben von der Cortado Mobile Solutions GmbH mit Hauptsitz in Berlin. Der Dienstleister wird als Mobile-Device-Management für vermietete Hardware eingesetzt. Die Daten werden innerhalb der Europäischen Union verarbeitet. Weitere Informationen finden Sie in der <a href="https://www.iubenda.com/privacy-policy/45136503/full-legal">Datenschutzerklärung</a> von Cortado.</li>
                    <li><span className="strong-text">Salesforce</span>, betrieben von der Salesforce.com Germany GmbH mit Hauptsitz in den USA. Der Dienstleister wird als Hosting-Anbieter für unsere Webanwendungen und Datenbanken genutzt (IAAS). Abhängig von Ihrem Standort werden die Daten entweder innerhalb der Europäischen Union oder in den Vereinigten Staaten verarbeitet. Weitere Informationen finden Sie in der <a href="https://www.salesforce.com/de/company/privacy/full_privacy/">Datenschutzerklärung</a> von Salesforce.</li>
                    <li><span className="strong-text">Google</span>, betrieben von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland („Google“). Der Dienstleister wird für den Vertrieb der App im Google PlayStore, die Verwendung von Firebase-Cloud-Messaging und die Verwendung von Android Enterprise im Kontext vermieteter Hardware eingesetzt. Abhängig von Ihrem Standort werden die Daten entweder innerhalb der Europäischen Union oder in den Vereinigten Staaten verarbeitet. Weitere Informationen finden Sie in der <a href="https://policies.google.com/privacy?hl=de">Datenschutzerklärung</a> von Google.</li>
                    <li><span className="strong-text">Apple</span>, betrieben von der Apple Inc. mit Hauptsitz in den USA. Der Dienstleister wird für den Vertrieb der App im Apple AppStore eingesetzt. Abhängig von Ihrem Standort werden die Daten entweder innerhalb der Europäischen Union oder in den Vereinigten Staaten verarbeitet. Weitere Informationen finden Sie in der <a href="https://www.apple.com/legal/privacy/data/en/apple-store-app/">Datenschutzerklärung</a> von Apple.</li>
                </ul>

                <p>Wir teilen nur Daten, die zur Durchführung des gegenseitigen Vertrages oder Arbeitsvertrages erforderlich sind oder sofern Sie uns eine Einwilligung erteilt haben, etwa im Rahmen unseres Newsletters oder des Cookie Banners. Wenn noch kein Vertragsverhältnis besteht, teilen wir die Daten in bestimmten Fällen im Rahmen berechtigter Interessen. Dies ist beispielsweise der Fall, wenn Sie lediglich unsere Webseite besuchen oder Kontakt aufnehmen möchten. Wenn Sie unsere Webseite besuchen, ist es im beidseitigen Interesse, den Zugang zum Angebot bereitzustellen und miteinander zu kommunizieren.</p>

                <p>Wir haben auch mit allen externen Empfängern Verträge zur Auftragsverarbeitung abgeschlossen, um europarechtliche Anforderungen zu erfüllen. Abhängig von Ihrem Standort, werden einige der oben genannten Dienstleister – sofern angegeben – Ihre Daten auch in die Vereinigten Staaten weiterleiten. Der Europäische Gerichtshof hat ursprünglich entschieden, dass die Vereinigten Staaten kein der EU gleichwertiges Datenschutzniveau besitzen und Behörden womöglich ohne ordentliches Verfahren auf Daten zugreifen können. Für ein ausreichendes Datenschutzniveau sind deshalb zusätzliche Absicherungen erforderlich. Um diese Anforderung zu erfüllen, haben wir zusätzliche Verträge zur Auftragsverarbeitung, namens Standardvertragsklauseln, abgeschlossen. Solange ein entsprechender Angemessenheitsbeschluss besteht, stützen wir uns auf diesen. Zudem prüfen wir jeden Dienstleister zusammen mit unserem Datenschutzbeauftragten und stellen sicher, dass zusätzliche Sicherheitsmaßnahmen verfügbar sind, etwa eine starke Verschlüsselung der Daten.</p>

                <p>Stand der Datenschutzerklärung: Juli 2024</p>
            </div> 
            <Footer />
            <CookieBanner />
        </>
    )
}

export default Policy;