import "./ConfigureHolidayModal.css";
import { useState } from "react";
import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import { Modal } from "react-bootstrap";
import { ButtonGroup, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import CancelButton from "../../../../../../sharedComponents/cancelButton/CancelButton";
import FederalStates from "../../../../../../sharedComponents/FederalStates";
import { CustomProvider, DatePicker } from "rsuite";
import deDe from 'rsuite/locales/de_DE';
import isAfter from 'date-fns/isAfter';
import axios from "axios";

function ConfigureHolidayModal(props) {
    
    const [selectedFederalState, setSelectedFederalState] = useState("nordrhein_westfalen");
    const [startDate, setStartDate] = useState(new Date());
    const [countHours, setCountHours] = useState(8);
    const [requestParameterHourMinute, setRequestParameterHourMinute] = useState({});
    const [updateHolidaysError, setUpdateHolidayError] = useState(false);
    const [noPaymentSelection, setNoPaymentSelection] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => {
        setOpenModal(true);
        setUpdateHolidayError(false);
        setNoPaymentSelection(false);
        setRequestParameterHourMinute({hour: 8, minute: 0});
        setCountHours(8);
    }
    const handleClose = () => setOpenModal(false);
    const handleFederalStateChange = (event) => setSelectedFederalState(event.target.value);

    const handleHourChange = (e) => {
        const regex = /^\d*,?\d{0,2}$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setCountHours(e.target.value);

            let valueArray = e.target.value.split(",");
            let timeObject;
            if(valueArray.length === 1 || valueArray[1] === ""){
                timeObject = {hour: valueArray[0], minute: 0};
                setRequestParameterHourMinute(timeObject);    
            } else {
                timeObject = {hour: valueArray[0], minute: Math.round((parseInt(valueArray[1])/Math.pow(10, valueArray[1].length))*60)};
                setRequestParameterHourMinute(timeObject);
            }
        }
    }

    const handleNoPaymentSelection = (event) => setNoPaymentSelection(event.target.checked);

    const sendPutRequestToUpdateHolidays = () => {
        let federalState = "NONE";
        if (!noPaymentSelection)
            federalState = selectedFederalState;

        axios.put("employee/updateBundesland", {
            "employee_id": new URLSearchParams(window.location.search).get("employeeId"),
            "since_when": startDate,
            "bundesland": federalState,
            "standard_duration": "PT"+ requestParameterHourMinute.hour + "H" + requestParameterHourMinute.minute + "M"
        }).then(() => window.location.reload()
        ).catch(error => {
            setUpdateHolidayError(true);
        })
    }

    function getNextDateAfterStartDate() {
        let nextDate = null;
    
        for (let holiday of props.holidays) {
            const holidayDate = new Date(holiday.since_when);
            if (holidayDate > startDate) {
                if (!nextDate || holidayDate < nextDate) {
                    nextDate = holidayDate;
                }
            }
        }
    
        if (!nextDate) {
            nextDate = new Date(startDate.getFullYear() + 1, 11, 31);
        }
    
        return nextDate;
    }

    function generateWarnText() {
        const nextDate = getNextDateAfterStartDate();
        return (
            <p className="red">{"Achtung. Es werden alle vorhandenen Feiertage (Bezahlt und Unbezahlt) aus folgendem Intervall gelöscht und neu erstellt:"} <strong>{startDate.toLocaleDateString() + "-" + nextDate.toLocaleDateString()}</strong></p>
        )
    }

    const handleDateChange = (dateText) => setStartDate(dateText);

    function createPayHolidayModal() {
        return (
            <Modal
                show={openModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Feiertage anpassen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <FormControl className="holiday-no-selection">
                        {props.withNoneSelection && <FormControlLabel label="Feiertage nicht mehr berücksichtigen" control={<Checkbox checked={noPaymentSelection} onChange={handleNoPaymentSelection} />} />}
                    </FormControl>
                    {!noPaymentSelection &&
                    <div className="formcontrol-setting">
                        <InputLabel id="federal-state-label">Bundesland</InputLabel>
                        <Select
                            labelId="federal-state-label"
                            value={selectedFederalState}
                            label="Bundesland"
                            onChange={handleFederalStateChange}
                        >
                        {FederalStates.map(federalState => (
                            <MenuItem key={federalState.internName} value={federalState.internName}>{federalState.displayName}</MenuItem>
                        ))}
                        </Select>
                        <div className="holiday-set-hours-minutes">
                            <InputLabel>bezahlte Stunden pro Feiertag:</InputLabel>
                            <ButtonGroup>
                                {/* <Button
                                    onClick={() => setCountHours((prev) => prev - 1)}
                                    disabled={countHours === 0}
                                >
                                    <RemoveIcon fontSize="small" />
                                </Button> */}
                                <TextField size="small" onChange={handleHourChange} value={countHours} sx={{width: "120px"}}/>
                                {/* <Button onClick={() => setCountHours((prev) => prev + 1)}>
                                    <AddIcon fontSize="small" />
                                </Button> */}
                            </ButtonGroup>
                            <p>Umgerechnet {requestParameterHourMinute.hour}Std {requestParameterHourMinute.minute}min</p>
                        </div>
                    </div>
                    }
                    <p>Startdatum:</p>
                    <CustomProvider locale={deDe}>
                        <DatePicker isoWeek oneTap format={"dd.MM.yyyy"} limitEndYear={2} cleanable={false}
                            onSelect={(dateText) => handleDateChange(dateText)} onChange={dateText => setStartDate(dateText)} 
                            value={startDate} shouldDisableDate={date => isAfter(date, new Date(startDate.getFullYear() + 1, 11, 31))}
                        />
                    </CustomProvider>
                    {props.withNoneSelection && <>{generateWarnText()}</>}
                    {updateHolidaysError && <p className="red">Ein Fehler ist aufgetreten.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Feiertage anpassen" onClick={sendPutRequestToUpdateHolidays} />
                </Modal.Footer>
            </Modal>
        )
    }
    
    return (
        <>
            <PrimaryButton text="Feiertage anpassen" onClick={handleOpen} />
            {createPayHolidayModal()}
        </>
    )
}

export default ConfigureHolidayModal;