import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../sharedComponents/backButton/BackButton";
import "./AutoPause.css";
import {Alert, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField} from "@mui/material";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import LoadingSpinner from "../../../../sharedComponents/LoadingSpinner/LoadingSpinner";

function AutoPause() {

    const [isLoading, setIsLoading] = useState(true);
    const [getAllTimePolicy, setGetAllTimePolicy] = useState([]);
    const [newTimePolicy, setNewTimePolicy] = useState([]);
    const [timePolicyId, setTimePolicyId] = useState("");
    const [timePolicyName, setTimePolicyName] = useState("");
    const [rows, setRows] = useState([{ selectedHour: "", pauseTime: 15 }]);
    const isInitialRender = useRef(true);
    const [editActive, setEditActive] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const hourList = [
        "Ab 0 Stunden", "Ab 1 Stunde", "Ab 2 Stunden", "Ab 3 Stunden", "Ab 4 Stunden", "Ab 5 Stunden", "Ab 6 Stunden", "Ab 7 Stunden", 
        "Ab 8 Stunden", "Ab 9 Stunden", "Ab 10 Stunden", "Ab 11 Stunden", "Ab 12 Stunden", "Ab 13 Stunden", "Ab 14 Stunden", "Ab 15 Stunden",
    ];
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
            },
        },
    };    

    useEffect(() => {
        getEmployeeData();        
    }, []);

    useEffect(() => {
        displayCurrentPolicys();
        setIsLoading(false)
    }, [getAllTimePolicy])

    useEffect(() => {
        if (!isInitialRender.current) {
            sendPutRequest();
        } else {
            isInitialRender.current = false;
        }
    }, [newTimePolicy])

    const handleSave = () => {
        let newValues = [...getAllTimePolicy];
        if(rows.length === 0 || rows[0].selectedHour !== 0){
            rows.push({selectedHour: 0, pauseTime: 0})
        }
        rows.sort((a, b) => a.selectedHour - b.selectedHour);
        rows.forEach((element, index) => {
            if(element.selectedHour === ""){
                rows.splice(index, 1);
            }
        })
        for(let i = 0; i < rows.length - 1; i++){
            let currentValue = rows[i].selectedHour;
            let nextValue = rows[i+1].selectedHour;
            if (currentValue === nextValue){
                setOpenSnackbar(true);
                return;
            }            
        }
        rows.forEach((row) => {
            for(let i = row.selectedHour; i < newValues.length; i++){
                newValues[i] = row.pauseTime;
            }
        });
        setNewTimePolicy(newValues);
    }

    const handlePauseTimeChange = (e, rowIndex) => {
        const regex = /^[0-9\b]*$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            const inputValue = e.target.value === "" ? "" : Math.max(Number(e.target.value), 0);
            setRows((prevRows) => {
                const newRows = [...prevRows];
                newRows[rowIndex].pauseTime = inputValue;
                return newRows;
            });
        }
    };

    const handleSelectChange = (e, rowIndex) => {
        const value = e.target.value;
        setRows((prevRows) => {
            const newRows = [...prevRows];
            newRows[rowIndex].selectedHour = value;
            return newRows;
        });
    };

    const addRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            { selectedHour: "", pauseTime: 15 },
        ]);
    };

    const removeRow = (rowIndex) => {
        setRows((prevRows) => {
            const newRows = [...prevRows];
            newRows.splice(rowIndex, 1);
            return newRows;
        });
    };

    function getEmployeeData() {
        axios.get("minimumPauseTimePolicy/getAll").then((results) => {
            let tempArray = [
            results.data[0].above_0_hours_constraint_in_minutes,
            results.data[0].above_1_hours_constraint_in_minutes,
            results.data[0].above_2_hours_constraint_in_minutes,
            results.data[0].above_3_hours_constraint_in_minutes,
            results.data[0].above_4_hours_constraint_in_minutes,
            results.data[0].above_5_hours_constraint_in_minutes,
            results.data[0].above_6_hours_constraint_in_minutes,
            results.data[0].above_7_hours_constraint_in_minutes,
            results.data[0].above_8_hours_constraint_in_minutes,
            results.data[0].above_9_hours_constraint_in_minutes,
            results.data[0].above_10_hours_constraint_in_minutes,
            results.data[0].above_11_hours_constraint_in_minutes,
            results.data[0].above_12_hours_constraint_in_minutes,
            results.data[0].above_13_hours_constraint_in_minutes,
            results.data[0].above_14_hours_constraint_in_minutes,
            results.data[0].above_15_hours_constraint_in_minutes,
            ];
            setGetAllTimePolicy(tempArray);
            setTimePolicyId(results.data[0].id);
            setTimePolicyName(results.data[0].name);
        });
    }

    function sendPutRequest() {
        axios.put('minimumPauseTimePolicy/update', {
            "id" : timePolicyId, 
            "new_name" : timePolicyName,
            "new_above_0_hours_constraint_in_minutes": newTimePolicy[0],
            "new_above_1_hours_constraint_in_minutes": newTimePolicy[1],
            "new_above_2_hours_constraint_in_minutes": newTimePolicy[2],
            "new_above_3_hours_constraint_in_minutes": newTimePolicy[3],
            "new_above_4_hours_constraint_in_minutes": newTimePolicy[4],
            "new_above_5_hours_constraint_in_minutes": newTimePolicy[5],
            "new_above_6_hours_constraint_in_minutes": newTimePolicy[6],
            "new_above_7_hours_constraint_in_minutes": newTimePolicy[7],
            "new_above_8_hours_constraint_in_minutes": newTimePolicy[8],
            "new_above_9_hours_constraint_in_minutes": newTimePolicy[9],
            "new_above_10_hours_constraint_in_minutes": newTimePolicy[10],
            "new_above_11_hours_constraint_in_minutes": newTimePolicy[11],
            "new_above_12_hours_constraint_in_minutes": newTimePolicy[12],
            "new_above_13_hours_constraint_in_minutes": newTimePolicy[13],
            "new_above_14_hours_constraint_in_minutes": newTimePolicy[14],
            "new_above_15_hours_constraint_in_minutes": newTimePolicy[15]
        }).then(() => {
            window.location.reload();
        }).catch(() => {
            console.log("error");
        });
    }

    function displayCurrentPolicys(){
        let currentRows = [];
        for(let i = 0; i < getAllTimePolicy.length - 1; i++){
            let currentValue = getAllTimePolicy[i];
            let nextValue = getAllTimePolicy[i+1];
            if (i === 0){
                currentRows.push({selectedHour: (i), pauseTime: currentValue})
            }
            if (currentValue !== nextValue){
                currentRows.push({selectedHour: (i+1), pauseTime: nextValue})
            }
        }
        setRows(currentRows);
    }

    return isLoading ? <LoadingSpinner/> :
    (
        <>
            <div className="auto-pause-container">
                <Link to={"/home/settings/"}>
                    <BackButton />
                </Link>
                <div className="auto-pause-header">
                    <h3>Automatisierte Pausen Regelung</h3>
                    <Button variant="contained" startIcon={<EditIcon/>} onClick={() => setEditActive(true)} style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}>Bearbeiten</Button>
                </div>
                <div className="add-remove-save-button">
                    <Button variant="contained" disabled={!editActive} startIcon={<AddIcon/>} onClick={addRow} style={{ backgroundColor: 'var(--tictrack-primary-color)' }}>Hinzufügen</Button>        
                </div>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className="select-pausetime-container">
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel>Regelung</InputLabel>
                            <Select
                                label="Regelung"
                                value={row.selectedHour}
                                onChange={(e) => handleSelectChange(e, rowIndex)}
                                MenuProps={MenuProps}
                                disabled={!editActive}
                            >
                                {hourList.map((element, index) => (
                                    <MenuItem key={element} value={index}>
                                        {element}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="button-group-pausetime">
                            <InputLabel>Pausenzeit in Minuten</InputLabel>
                            <ButtonGroup>
                                <Button onClick={() => handlePauseTimeChange({ target: { value: row.pauseTime - 5 } }, rowIndex)} disabled={row.pauseTime <= 0 || !editActive}>
                                    <RemoveIcon fontSize="small" />
                                </Button>
                                <TextField
                                    size="small"
                                    onChange={(e) => handlePauseTimeChange(e, rowIndex)}
                                    value={row.pauseTime}
                                    sx={{ width: "60px" }}
                                    disabled={!editActive}
                                />
                                <Button onClick={() => handlePauseTimeChange({ target: { value: row.pauseTime + 5 } }, rowIndex)} disabled={!editActive}>
                                    <AddIcon fontSize="small" />
                                </Button>
                            </ButtonGroup>
                        </div>
                        <Button disabled={!editActive} color="error" onClick={() => removeRow(rowIndex)} startIcon={<CancelIcon fontSize="large"/>}></Button>
                    </div>
                ))}
                {editActive && <div className="save-button">
                    <Button variant="outlined" color="error" onClick={() => {window.location.reload()}}>Abbrechen</Button>
                    <Button variant="contained" onClick={handleSave} style={{ backgroundColor: 'var(--tictrack-primary-color)' }}>Speichern</Button>
                </div>}
                <Snackbar open={openSnackbar} onClose={() => setOpenSnackbar(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} sx={{ width: '80%' }}>
                    <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
                        Bitte doppelte Regelungen entfernen
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}

export default AutoPause;
