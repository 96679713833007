import "./ShiftsOverview.css";
import axios from "axios";
import {useState, useEffect, useRef} from "react";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import {Link} from "react-router-dom";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Snackbar, useMediaQuery } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CreateShiftModal from "./createShift/modal/CreateShiftModal";
import ScrollRecovery from "../../../../../sharedComponents/ScrollRecovery";

function ShiftsOverview() {

    const [isLoading, setIsLoading] = useState(true);
    const [allShifts, setAllShifts] = useState([]);
    const [hasliveShift, setHasLiveShift] = useState("");
    const [open, setOpen] = useState([]);
    const [creationModalShow, setCreationModalShow] = useState(false);
    const matches = useMediaQuery('(min-width:550px)');

    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    
    const monthListLength = useRef(0);   

    useEffect(() => {
        axios.get('shift/getByEmployeeIdInDuration?id=' + new URLSearchParams(window.location.search).get("employeeId") + '&start=2022-01-01&end=' + new Date().toISOString().slice(0, 10) + '&sort=desc').then(results => {
            setAllShifts(results.data);
            setIsLoading(false);
        })
        axios.get('activity/getAllForOngoingShiftByEmployeeId?id=' + new URLSearchParams(window.location.search).get("employeeId")).then(results => {
            setHasLiveShift(results.data);
        })
    }, [isLoading]);

    useEffect(() => {
        const storedOpenState = localStorage.getItem("openState");

        if(storedOpenState === null){
            const initialOpenState = Array(monthListLength).fill(false);
  
            // Set the first element (current month) to true
            initialOpenState[0] = true;

            setOpen(initialOpenState);
        } else {
            setOpen(JSON.parse(storedOpenState));
        }
    }, [monthListLength])

    const handleClick = (index) => {
        const newOpenState = [...open];
        newOpenState[index] = !newOpenState[index];
        setOpen(newOpenState);
        localStorage.setItem("openState", JSON.stringify(newOpenState));
    };

    function timeFormatCheck(number) {
        if (number < 10)
            return "0" + number;
        else
            return number;
    }

    function createMonths() {
        // Find out earliest month, where shifts exist
        let earliestDate = new Date();
        if(allShifts.length !== 0){
            let earlyDate = allShifts.reduce((earliest, current) => {return current.start_time < earliest.start_time ? current : earliest;});
            earliestDate = new Date(earlyDate.start_time);
        }        
        const currentDate = new Date();
        const monthsList = [];
        let currentMonth = earliestDate.getMonth();
        let currentYear = earliestDate.getFullYear();
        while (currentYear < currentDate.getFullYear() ||(currentYear === currentDate.getFullYear() && currentMonth <= currentDate.getMonth())){
            const monthString = new Date(currentYear, currentMonth, 1).toLocaleDateString('de', {month: 'long', year: 'numeric'});
            monthsList.push(monthString);

            // Move to the next month
            if (currentMonth === 11) {
                currentMonth = 0;
                currentYear++;
            } else {
                currentMonth++;
            }
        }
        monthsList.reverse();

        let monthRender = [];
        monthsList.forEach((item, index) => {
            monthRender.push(
                <div key={index}>
                    <ListItemButton onClick={() => handleClick(index)} >
                        <ListItemText primary= {item} />
                        {open[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                        <List>
                            {createShiftObjects(item)}
                        </List>
                    </Collapse>
                </div>
            )
        })

        monthListLength.current = monthsList.length;

        return monthRender;
    }

    function createDuration(hours, minutes) {
        if ((hours && minutes) !== null)
            return hours + ":" + timeFormatCheck(minutes) + " Std";
        else
            return "???";
    }

    function createListItemTextForShifts(date, startTime, endTime, isIgnored) {
        return <p>Arbeitszeit am {date}, {startTime} - {endTime} Uhr {} {isIgnored && <p className="red-color">Wird ignoriert</p>}</p>;
    }

    function createShiftObjects(monthName) {
        let shifts = [];

        if(hasliveShift){
            let liveShift = hasliveShift[0];
            let liveShiftTime = new Date(liveShift.timestamp);
            let monthString = liveShiftTime.toLocaleDateString('de', { month: 'long', year: 'numeric' });
            if(monthString === monthName){
                shifts.push(
                    <ListItem className="duration-pause-time" key={"live"} secondaryAction={matches &&
                        <ListItemText
                            primary={"Live-Arbeitszeit"}
                        />
                    }>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to={"/home/employees/employeeView/shift?beginId=" + liveShift.id}>
                            <ListItemIcon>
                                <img src={process.env.PUBLIC_URL + '/icons/redCircle.png'} alt="" width={30} height={30} />
                            </ListItemIcon>
                            <ListItemText primary={"Arbeitszeit am " + liveShiftTime.toLocaleDateString('de', { year: "numeric", month: "2-digit", day: "2-digit" }) + ", " + liveShiftTime.toLocaleTimeString('de', { hour: "2-digit", minute: "2-digit" }) + "-??Uhr"} secondary={" (" + liveShift.location.name + ")"} />
                        </ListItemButton>
                    </ListItem>
                );
            }
        }

        allShifts.forEach((item, index) => {
            let itemDate = new Date(item.start_time);
            let itemEndDate = new Date(item.end_time);
            let monthString = new Date(itemDate.getFullYear(), itemDate.getMonth(), 1).toLocaleDateString('de', { month: 'long', year: 'numeric' });
            let localDateString = itemDate.toLocaleDateString('de', { year: "numeric", month: "2-digit", day: "2-digit" });
            let startTime = itemDate.toLocaleTimeString('de', { hour: "2-digit", minute: "2-digit" });
            let endTime = itemEndDate.toLocaleTimeString('de', { hour: "2-digit", minute: "2-digit" });
            let ignored = item.is_ignored;

            

            if (monthName === monthString) {
                if ((item.duration_working || item.duration_in_pause) === null) {
                    shifts.push(
                        <ListItem className="duration-pause-time" key={index} secondaryAction={matches &&
                            <ListItemText
                                primary={"Arbeit: ???"}
                                secondary={"Pause: ???"}
                            />
                        }>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to={"/home/employees/employeeView/shift?beginId=" + item.shift_begin_activity_id}>
                                <ListItemIcon>
                                    {item.has_unresolved_objections && <img src={process.env.PUBLIC_URL + '/icons/cancel.png'} alt="" width={30} height={30} />}
                                    {!item.has_unresolved_objections && item.shift_status === "completed" && <img src={process.env.PUBLIC_URL + '/icons/ok-green.png'} alt="" width={30} height={30} />}
                                    {!item.has_unresolved_objections && item.shift_status === "not_completed" && <img src={process.env.PUBLIC_URL + '/icons/cancel.png'} alt="" width={30} height={30} />}
                                    {!item.has_unresolved_objections && item.shift_status === "edited_completed" && <img src={process.env.PUBLIC_URL + '/icons/ok-orange.png'} alt="" width={30} height={30} />}
                                    {!item.has_unresolved_objections && item.shift_status === "edited_not_completed" && <img src={process.env.PUBLIC_URL + '/icons/cancel.png'} alt="" width={30} height={30} />}
                                </ListItemIcon>
                                <ListItemText primary={createListItemTextForShifts(localDateString, startTime, endTime, ignored)} secondary={" (" + item.location.name + ")"} />
                            </ListItemButton>
                        </ListItem>
                    );
                } else {
                    shifts.push(
                        <ListItem className="duration-pause-time" key={index} secondaryAction={matches &&
                            <ListItemText
                                primary={"Arbeit: " + createDuration(item.duration_working.hours, item.duration_working.minutes)}
                                secondary={"Pause: " + createDuration(item.duration_in_pause.hours, item.duration_in_pause.minutes)}
                            />
                        }>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to={"/home/employees/employeeView/shift?beginId=" + item.shift_begin_activity_id}>
                                <ListItemIcon>
                                    {item.has_unresolved_objections && <img src={process.env.PUBLIC_URL + '/icons/cancel.png'} alt="" width={30} height={30} />}
                                    {!item.has_unresolved_objections && item.shift_status === "completed" && <img src={process.env.PUBLIC_URL + '/icons/ok-green.png'} alt="" width={30} height={30} />}
                                    {!item.has_unresolved_objections && item.shift_status === "not_completed" && <img src={process.env.PUBLIC_URL + '/icons/cancel.png'} alt="" width={30} height={30} />}
                                    {!item.has_unresolved_objections && item.shift_status === "edited_completed" && <img src={process.env.PUBLIC_URL + '/icons/ok-orange.png'} alt="" width={30} height={30} />}
                                    {!item.has_unresolved_objections && item.shift_status === "edited_not_completed" && <img src={process.env.PUBLIC_URL + '/icons/cancel.png'} alt="" width={30} height={30} />}
                                </ListItemIcon>
                                <ListItemText primary={createListItemTextForShifts(localDateString, startTime, endTime, ignored)} secondary={" (" + item.location.name + ")"} />
                            </ListItemButton>
                        </ListItem>
                    );
                }
            }
        });
    
        if (shifts.length === 0) {
            shifts.push(
                <ListItemText key={monthName} sx={{ pl: 4 }} secondary={"Noch keine Arbeitszeiten"} />
            );
        }
    
        return shifts;
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;

        setShowSuccessSnackbar(false);
    }

    function createSuccessfulSnackBar() {
        return (
            <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Arbeitszeit wurde erfolgreich erstellt!
                </Alert>
            </Snackbar>
        )
    }

    if (isLoading) {
        return (<LoadingSpinner/>);
    } else {
        return (
            <>
                <ScrollRecovery setCondition={!isLoading} scrollKey={"shiftOverviewScroll"}/>
                {createSuccessfulSnackBar()}
                <CreateShiftModal 
                    modalShow={creationModalShow}  
                    modalShowClose={() => setCreationModalShow(false)}
                    reloadPage={() => setIsLoading(true)}
                    successSnackbar={() => setShowSuccessSnackbar(true)}
                />
                <div className="shifts-overview-container-header">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Neue Arbeitszeit nachtragen</Tooltip>}>
                    <Fab color="primary" aria-label="add" onClick={() => setCreationModalShow(true)} className="add-mui-icon" style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}>
                        <AddIcon />
                    </Fab>
                    </OverlayTrigger>
                </div>
                <List className="shift-month-list" >
                    {createMonths()}
                </List>
            </>
        )
    }
}
export default ShiftsOverview;