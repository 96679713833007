import "./EmployeeInformation.css";
import {useEffect, useState} from "react";
import axios from "axios";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { Modal, Button } from "react-bootstrap";
import { TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";
import CancelButton from "../../../../../sharedComponents/cancelButton/CancelButton";

function EmployeeInformation() {

    const [isLoading, setIsLoading] = useState(true);
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [personalNumber, setPersonalNumber] = useState("");
    const [relationship, setRelationship] = useState("");
    const [mobileLoginEnabled, setMobileLoginEnabled] = useState(null);
    const [automaticPauseEnabled, setAutomaticPauseEnabled] = useState(null);
    const [city, setCity] = useState("");
    const [plz, setPlz] = useState("");
    const [adress, setAdress] = useState("");

    const [statusText, setStatusText] = useState("");
    const [activateDeactivateText, setActivateDeactivateText] = useState("");
    const [activateDeactivateVariant, setActivateDeactivateVariant] = useState("");

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (isLoading) {
            getEmployeeData();
        }
    }, [disableSaveButton])

    function getEmployeeData() {
        axios.get('employee/getById?id=' + new URLSearchParams(window.location.search).get("employeeId")).then(results => {
            setLastName(results.data.last_name);
            setFirstName(results.data.first_name);
            setPersonalNumber(results.data.id);
            setRelationship(results.data.employment_type);
            setCity(results.data.city);
            setPlz(results.data.plz);
            setAdress(results.data.street_address);
            setMobileLoginEnabled(results.data.mobile_login_enabled);
            setAutomaticPauseEnabled(results.data.automatic_pause_addition_enabled);

            createActivateDeactivateButton(results.data.is_active);

            setIsLoading(false);
        })
    }

    function handleChange(event) {
        let value = event.target.value;
        checkInputFields(event);
        setDisableSaveButton(checkAllInputFields());
        updateDisplay();

        switch (event.target.id) {
            case "EmployeeName":
                setLastName(value);
                break;
            case "EmployeeFirstName":
                setFirstName(value);
                break;
            case "EmployeePersonalNumber":
                setPersonalNumber(value);
                break;
            case "EmployeeRelationship":
                setRelationship(value);
                break;
            case "EmployeeCity":
                setCity(value);
                break;
            case "EmployeePLZ":
                setPlz(value);
                break;
            case "EmployeeStreetNumber":
                setAdress(value);
                break;
            case "EnablingMobileTrackingCheckbox":
                setMobileLoginEnabled(event.target.checked);
                break;
            case "AutomaticPauseCheckbox":
                setAutomaticPauseEnabled(event.target.checked);
                break;
            default:    
                break;
        }
    }

    function checkInputFields(event) {
        if (event.target.value === "") {
            event.target.style.borderColor = "red";
        } else {
            event.target.style.borderColor = "black";
        }
    }

    function updateDisplay() {
        let changeDisplay = document.getElementById("EmployeeChangeDisplay");
        changeDisplay.innerHTML = "Änderungen bitte speichern!";
        changeDisplay.style.color = "red";
    }

    function checkAllInputFields() {
        let disableInputField = false;

        if (document.getElementById("EmployeeName").value === "") {disableInputField = true}
        if (document.getElementById("EmployeeFirstName").value === "") {disableInputField = true}
        if (document.getElementById("EmployeePersonalNumber").value === "") {disableInputField = true}
        if (document.getElementById("EmployeeRelationship").value === "") {disableInputField = true}

        return disableInputField;
    }

    function handleSaveButtonClick() {
        let changeDisplay = document.getElementById("EmployeeChangeDisplay");

        axios.put('employee/update', {
            "last_name": lastName,
            "first_name": firstName,
            "id": personalNumber,
            "employment_type": relationship,
            "city": city,
            "plz": plz,
            "street_address": adress,
            "mobile_login_enabled": mobileLoginEnabled,
            "automatic_pause_addition_enabled": automaticPauseEnabled
        }).then(response => {
            changeDisplay.innerHTML = "Änderungen wurden gespeichert!";
            changeDisplay.style.color = "green";
        }).catch(error => {
            changeDisplay.innerHTML = "Ein Fehler ist aufgetreten!";
            changeDisplay.style.color = "red";
        })
    }

    function createActivateDeactivateButton(active) {
        if (active) {
            setActivateDeactivateText("Mitarbeiter deaktivieren");
            setActivateDeactivateVariant("error");
            setStatusText("Aktiviert")
        } else {
            setActivateDeactivateText("Mitarbeiter aktivieren");
            setActivateDeactivateVariant("success");
            setStatusText("Deaktiviert")
        }
    }

    function createModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>{activateDeactivateText}</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p className="modal-text">{"Möchten Sie diesen Mitarbeiter wirklich " + activateDeactivateText.split(" ")[1] + "?"}</p>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Ja" onClick={handleActivateDeactivateProfile} />
                </Modal.Footer>
            </Modal>
        )
    }

    function handleActivateDeactivateProfile() {
        if (activateDeactivateText === "Mitarbeiter deaktivieren") {
            axios.put("employee/setStatus", {
                "id": personalNumber,
                "is_active": false
            }).then(() => window.location.reload());
        } else if (activateDeactivateText === "Mitarbeiter aktivieren") {
            axios.put("employee/setStatus", {
                "id": personalNumber,
                "is_active": true
            }).then(() => window.location.reload());
        }
    }

    return isLoading?

        <LoadingSpinner/>
        :
        (
        <>
            <div className="information-header-container">
                <h2 className={statusText === 'Aktiviert' ? 'green' : 'red'}>{statusText}</h2>
                <Button variant="danger" color={activateDeactivateVariant} onClick={handleShow}>{activateDeactivateText}</Button>
            </div>
            <h5 id="EmployeeChangeDisplay"></h5>
            <div className="information-data-container">
                <h4>Allgemein</h4>
                <TextField id="EmployeeName" onChange={handleChange} value={lastName} label="Nachname" variant="outlined"/>
                <TextField id="EmployeeFirstName" onChange={handleChange} value={firstName} label="Vorname" variant="outlined"/>
                <TextField id="EmployeePersonalNumber" disabled={true} value={personalNumber} label="Eindeutige Personalnummer" variant="outlined"/>
                <TextField id="EmployeeRelationship" onChange={handleChange} value={relationship} label="Anstellungsverhältnis" variant="outlined"/>
                <FormGroup>
                    <FormControlLabel control={<Checkbox id="EnablingMobileTrackingCheckbox" onChange={handleChange} checked={mobileLoginEnabled} />} label="Mobile Zeiterfassung erlauben (Notwendig für Homeoffice / Außendienst etc.)" title="Dies ist nötig, wenn der Mitarbeiter die Erlaubnis haben sollte im HomeOffice, im Außendienst oder ähnlichem die Arbeitszeit zu erfassen." />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel className="adding-automated-pause-checkbox" control={<Checkbox id="AutomaticPauseCheckbox" onChange={handleChange} checked={automaticPauseEnabled} />} label={
                        <span>
                            Achtung: Das automatisierte Hinzufügen von Pausen aktivieren 
                            (Bei Fragen zu dieser Funktion gerne an 
                            <a href="mailto:info@tictrack.de"> info@tictrack.de</a> wenden.)
                        </span>
                    } />
                </FormGroup>
            </div>
            
            <Button className="save-employee-information-button" variant="light" disabled={disableSaveButton} onClick={handleSaveButtonClick}>Speichern</Button>
            {createModal()}
        </>
    )
}

export default EmployeeInformation;