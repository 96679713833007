import React from "react";
import "./AdminPage.css";
import Navbar from "./navbar/Navbar";
import axios from "axios";
import { Outlet } from "react-router";
import Footer from "../sharedComponents/footer/Footer";
import CookieBanner from "../sharedComponents/CookieBanner";

function AdminPage() {

    function createAdminPage() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("jwtToken");

        if (localStorage.getItem("jwtToken") !== null) {
            return (
                <div className="menu-bar-container">
                    <Navbar />
                </div>
            )
        } else {
            window.location.href = '/';
        }
    }

    return (
        <>
            <div className="adminpage-container"> 
                {createAdminPage()}
                <Outlet/>
                <Footer />
                <CookieBanner/>
            </div>
            
        </>
    )
}

export default AdminPage;