import "./LocationItem.css";

function LocationItem(props) {

    if (props.id === '999999') {
        return (
            <div className="mobile-location-item-container location-item-container" title="Dies ist der mobile Standort (HomeOffice, Aussendienst, ...). Dieser wird nicht als aktiver Standort gezählt.">
                <p className="location-item-text gray">{props.name}</p>
            </div>
        )
    } else {
        return (
            <div className="location-item-container">
                <p className="location-item-text black">{props.name}<span className="small-text"> - {props.id}</span></p>
            </div>
        )
    }
}

export default LocationItem;