import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

function CustomSnackbar({ open, onClose, variant, message }) {

    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        if (open) {
            setIsOpen(true);
        }
    }, [open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }

    return (
        <Snackbar open={isOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity={variant} variant="filled" sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default CustomSnackbar;