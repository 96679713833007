import "./CreateShiftLocationInput.css";
import {useEffect, useState} from "react";
import {Dropdown, Message} from "rsuite";

function CreateShiftLocationInput(props) {

    const [location, setLocation] = useState(null);

    const handleLocationInput = (value) => {
        setLocation(value);
        props.locations.map((item) => {
            if (item.name === value)
                sessionStorage.setItem("new-shift-location-id", item.id);
        })
        sessionStorage.setItem("new-shift-location", value);
        props.setNoSelection("");
    };

    useEffect(() => {
    }, [location])

    function createPossibleLocations() {
        let possibleLocations = [];
        if (props.locations !== null) {
            props.locations.map((item) => {
                possibleLocations.push(
                    <Dropdown.Item key={item.id} eventKey={item.name}>{item.name}</Dropdown.Item>
                )
            })
        }

        return possibleLocations;
    }

    function getDropdownTitle() {
        if (sessionStorage.getItem("new-shift-location") !== null)
            return sessionStorage.getItem("new-shift-location");
        else 
            return "Auswählen";
    }

    return (
        <div className="create-shift-location-input-container">
            <Message className="create-shift-location-input-message" showIcon type="info">Ort der Arbeitszeit</Message>
            <Dropdown className={"create-shift-selection " + props.noSelection} title={getDropdownTitle()} onSelect={handleLocationInput}>
                {createPossibleLocations()}
            </Dropdown>
        </div>

    )
}

export default CreateShiftLocationInput;