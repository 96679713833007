import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import AppAccessItem from "../item/AppAccessItem";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./AppAccessList.css";

function AppAccessList(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [connections, setConnections] = useState(null);
    const [disabledConnections, setDisabledConnections] = useState(null);

    useEffect(() => {
        getAllConnections();
    }, []);

    useEffect(() => {
        getAllConnections();
    }, [props.successfulCreating])

    function getAllConnections() {
        axios.get('employee/app/connections/getAllByEmployeeId?id=' + sessionStorage.getItem("employeeId")).then(results => {
            setConnections(results.data);
            setIsLoading(false);
        })
    }

    function createActiveConnections() {
        let appConnections = [];

        connections.map((item, index) => {
            if (item.status !== 'deleted') {
                appConnections.push(
                    <AppAccessItem key={"App Connection: " + index} connection={item}/>
                )
            }
        })

        return appConnections;
    }

    function createDisabledConnections() {
        let appConnections = [];

        connections.map((item, index) => {
            if (item.status === 'deleted') {
                appConnections.push(
                    <AppAccessItem key={"App Connection: " + index} connection={item}/>
                )
            }
        })

        return appConnections;
    }

    function createNoConnectionsText() {
        if (connections.length === 0)
            return (<h5>Keinen Zugang eingerichtet.</h5>)
    }

    return isLoading ?
        <LoadingSpinner />
    :
        <div className="app-connection-list-container">
            {createActiveConnections()}
            {createNoConnectionsText()}
            <div className="app-disabled-connection-container">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                            Deaktiviert
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {createDisabledConnections()}
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
}

export default AppAccessList;