const FederalStates = [
  { displayName: 'Baden-Württemberg', internName: 'baden_wuerttemberg' },
  { displayName: 'Bayern', internName: 'bayern' },
  { displayName: 'Berlin', internName: 'berlin' },
  { displayName: 'Brandenburg', internName: 'brandenburg' },
  { displayName: 'Bremen', internName: 'bremen' },
  { displayName: 'Hamburg', internName: 'hamburg' },
  { displayName: 'Hessen', internName: 'hessen' },
  { displayName: 'Mecklenburg-Vorpommern', internName: 'mecklenburg_vorpommern' },
  { displayName: 'Niedersachsen', internName: 'niedersachsen' },
  { displayName: 'Nordrhein-Westfalen', internName: 'nordrhein_westfalen' },
  { displayName: 'Rheinland-Pfalz', internName: 'rheinland_pfalz' },
  { displayName: 'Saarland', internName: 'saarland' },
  { displayName: 'Sachsen', internName: 'sachsen' },
  { displayName: 'Sachsen-Anhalt', internName: 'sachsen_anhalt' },
  { displayName: 'Schleswig-Holstein', internName: 'schleswig_holstein' },
  { displayName: 'Thüringen', internName: 'thueringen' },
];
  
  export default FederalStates;