import { Link } from "react-router-dom";
import BackButton from "../../../../sharedComponents/backButton/BackButton";
import PrimaryButton from "../../../../sharedComponents/primaryButton/PrimaryButton";
import CoreList from "./coreList/CoreList";
import "./CoreTime.css";

function CoreTime() {
    return (
        <>
            <div className="core-time-container">
                <Link to={"/home/settings/"}><BackButton /></Link>
                <div className="core-time-header">
                    <h3>Kernarbeitszeit Regelung</h3>
                    <Link to={"creation"}>
                        <PrimaryButton text="Neue Regelung hinzufügen"/>
                    </Link>
                </div>
                <CoreList />
            </div>
        </>
    )
}

export default CoreTime;