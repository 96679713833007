import { TextField } from "@mui/material";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../../../../../../sharedComponents/backButton/BackButton";
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";
import "./CardAccessCreation.css";

function CardAccessCreation() {

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('employee/physicalCard/connect', {
            "employee_id" : sessionStorage.getItem("employeeId"),
            "rfid_code": document.getElementById("CardAddingNumberInput").value
        }).then(() => {
            navigate("/home/employees/employeeView?employeeId=" + sessionStorage.getItem("employeeId"));
            saveTabConfiguration();
        }).catch(() => {
            
        })
    }

    const saveTabConfiguration = () => {
        sessionStorage.setItem("emplyoeeAccessOption", "Karten Zugang");
    }

    return (
        <>
            <div className="create-card-access-header-container">
                <Link to={"/home/employees/employeeView?employeeId=" + sessionStorage.getItem("employeeId")} onClick={saveTabConfiguration}>
                    <BackButton text="Zurück" />
                </Link>
                <h3>Neuen Karten Zugang anlegen</h3>
            </div>
            <div className="create-card-access-image">
                <img src={process.env.PUBLIC_URL + '/images/Mitarbeiterkarte.png'} alt="Man sieht die Mitarbeiterkarte von TicTrack. Die Nummer, welche eingegeben werden soll ist rot markiert."/>
                <h5>Bitte geben Sie hier die Kartennummer an, welche sich auf der Rückseite der Karte befindet.</h5>
            </div>
            <form onSubmit={handleSubmit} className="create-card-access-content-container">
                <TextField id="CardAddingNumberInput" label="Kartennummer" variant="outlined" />
                <PrimaryButton text="Mitarbeiterkarte aktivieren" onClick={handleSubmit} />
            </form>
        </>
    )
}

export default CardAccessCreation;