import { Link } from "react-router-dom";
import "./Errorpage.css";
import Footer from "../sharedComponents/footer/Footer";
import CookieBanner from "../sharedComponents/CookieBanner";
import ClearBanner from "../sharedComponents/clearBanner/ClearBanner";




function Errorpage(){
    return(
        <>
            <ClearBanner />
            <div className="error-page-container">
                <img src={process.env.PUBLIC_URL + '/icons/error_icon_darkblue.png'}/>
                <h1> Seite nicht gefunden</h1>
                <Link to={"/"}> Hier geht es zurück zur Anmeldung</Link>
            </div>
            <Footer />
            <CookieBanner/>
        </>
    )
}

export default Errorpage;