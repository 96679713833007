import "./Locations.css";
import LocationList from "./locationList/LocationList";
import LocationCreation from "./creation/LocationCreation";
import {useEffect, useState} from "react";

function Locations() {

    const [newLocationUpdate, setNewLocationUpdate] = useState(false);

    const [searchInputText, setSearchInputText] = useState("");

    useEffect(() => {
        setNewLocationUpdate(false);
    }, [newLocationUpdate])

    useEffect(() => {

    }, [searchInputText])

    function handleSearchBarChange(event) {
        setSearchInputText(event.target.value);
    }

    return (
        <>
            <div className="location-header-container">
                <div className="mobile-top-header">
                    <div className="header-container">
                        <h1>Standorte</h1>
                    </div>
                    <div className="create-button-container">
                        <LocationCreation />
                    </div>
                </div>
                <div className="search-bar-location-container input-group">
                    <span className="input-group-text" id="basic-addon1"><img className="search-logo" src={process.env.PUBLIC_URL + '/icons/suche.png'} alt="Ein Bild mit einer Lupe"/></span>
                    <input type="text" onChange={handleSearchBarChange} className="form-control" placeholder="Nach Standort suchen..." aria-label="Standort"
                           aria-describedby="basic-addon1"/>
                </div>
            </div>
            <LocationList searchInputText={searchInputText}/>
        </>
    )
}

export default Locations;