import "./CreateShiftModal.css";
import { Button, Modal } from "react-bootstrap";
import { CustomProvider, DatePicker } from "rsuite";
import CancelButton from "../../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";
import deDe from 'rsuite/locales/de_DE';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Alert, Collapse, FormControl, InputLabel, ListItemButton, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function CreateShiftModal(props){

    const [startDate, setStartDate] = useState(null);
    const [updatedStartTime, setUpdatedStartTime] = useState("");
    const [updatedEndTime, setUpdatedEndTime] = useState("");
    const [pauseTime, setPauseTime] = useState("");
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [locations, setLocations] = useState([]);
    const [locationId, setLocationId] = useState("");

    const [nextDayOverlap, setNextDayOverlap] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [emptyTextfields, setEmptyTextfields] = useState(true);

    useEffect(() => {
        getLocations()
    }, [])

    useEffect(() => {
        setStartDate(null);
        setUpdatedStartTime("");
        setUpdatedEndTime("");
        setCollapseOpen(false);
        setLocationId("");
        setNextDayOverlap(false);
    }, [props.modalShow])

    useEffect(() => {
        if(startDate !== null) getActivities();
    }, [updatedEndTime])

    function getLocations() {
        axios.get('location/getActive').then(results => {
            setLocations(results.data);
        })
    }
    
    function createShiftPostRequest(){
        if(startDate === null || locationId === "" || updatedStartTime === "" || updatedEndTime === ""){
            setShowErrorSnackbar(true);
            setEmptyTextfields(true);
        } else {
            let activitiesArray = getActivities();
            const time1 = new Date(activitiesArray[0].timestamp);
            const time2 = new Date(activitiesArray[1].timestamp);
            if((time2 - time1)/(1000*60) < parseInt(pauseTime)){
                setShowErrorSnackbar(true);
                setEmptyTextfields(false);
                return;
            }
            axios.post('shift/asynchronousCreateFromActivities', {
                "employee_id": sessionStorage.getItem("employeeId"),
                "location_id": locationId,
                "activities" : activitiesArray, 
                "reason": "Nachgetragen"
            }).then((results) => {
                if(pauseTime !== ""){
                    axios.put('shift/informationAttachments/additionalPauseDuration/update', {
                        "shift_begin_activity_id" : results.data.shift_begin_activity_id,
                        "additional_pause_duration" : "PT"+ pauseTime + "m",
                    })
                }
                props.reloadPage();
                props.modalShowClose();
                props.successSnackbar();
            });
        }
        
    }

    function getActivities(){
        let standardType = ["shift_begin", "shift_end"];

        let activitiesArray = [];
        let newDate = new Date(startDate);
        newDate.setHours(updatedStartTime.split(':')[0], updatedStartTime.split(':')[1], 0, 0)
        activitiesArray.push({
            "activity_type": standardType[0],
            "timestamp": newDate.toISOString()
        });

        //if endtime < starttime, overlap into next day
        const time1 = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), parseInt(updatedStartTime.split(':')[0]), parseInt(updatedStartTime.split(':')[1]), 0, 0);
        const time2 = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), parseInt(updatedEndTime.split(':')[0]), parseInt(updatedEndTime.split(':')[1]), 0, 0);

        if(time1.getHours() > time2.getHours() || (time1.getHours() === time2.getHours() && time1.getMinutes() > time2.getMinutes())){
            newDate.setDate(startDate.getDate() + 1);
            setNextDayOverlap(true);
        } else setNextDayOverlap(false)

        newDate.setHours(updatedEndTime.split(':')[0], updatedEndTime.split(':')[1], 0, 0)
        activitiesArray.push({
            "activity_type": standardType[1],
            "timestamp": newDate.toISOString()
        });

        return activitiesArray;
    }

    function disabledDate(currentDate) {
        const today = new Date();
        return currentDate > today;
    }

    function getMenuItems() {
        let newLocationsId = [];
        locations.forEach(element => {
            newLocationsId.push(
                <MenuItem className="menu-items" key={element.id} value={element.id} disableRipple disableTouchRipple>{element.name}</MenuItem>
            )
        });
        return newLocationsId;
    }

    const handlePositiveInteger = (e) => {
        const regex = /^\d*$/;
        if(regex.test(e.target.value)){
            setPauseTime(e.target.value === "" ? "" : Math.max(Number(e.target.value), 0));
        }
    }

    const handleLocationChange = (event) => {
        setLocationId(event.target.value);
    }

    function createFastModal(){
        return(
            <Modal
                show={props.modalShow}
                onHide={props.modalShowClose}
                keyboard={true}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>Neue Arbeitszeit erstellen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={props.modalShowClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="create-modal-body">
                        <Button variant="light" style={{width: '200px', backgroundColor: 'var(--tictrack-primary-color)'}} ><Link to={'createShift'}>Detaillierte Erstellung</Link></Button>
                        <FormControl>
                            <InputLabel>Ort auswählen</InputLabel>
                            <Select 
                                value={locationId}
                                label="Ort auswählen"
                                onChange={handleLocationChange}
                                required
                            >
                                {getMenuItems()}
                            </Select>
                        </FormControl>
                        <p>Datum auswählen*</p>
                        <CustomProvider locale={deDe}>
                            <DatePicker isoWeek oneTap format={"dd.MM.yyyy"} 
                                onSelect={dateText => setStartDate(dateText)} onChange={dateText => setStartDate(dateText)} 
                                value={startDate} shouldDisableDate={disabledDate}
                            />
                        </CustomProvider>
                        <p>Start-Uhrzeit*: </p>
                        <input className="form-control" onChange={(event) => setUpdatedStartTime(event.target.value)} disabled={false} type="time" value={updatedStartTime}/>
                        <p>End-Uhrzeit*: </p>
                        <input className="form-control" onChange={(event) => setUpdatedEndTime(event.target.value)} disabled={false} type="time" value={updatedEndTime}/>
                        {nextDayOverlap && <p className="warning-text-overlap">Warnung: Die Enduhrzeit der Arbeitszeit befindet sich am darauffolgenden Tag</p>}
                        <ListItemButton onClick={() => setCollapseOpen(!collapseOpen)}>Pause hinzufügen{collapseOpen ? <ExpandLess /> : <ExpandMore/>}</ListItemButton>
                        <Collapse in={collapseOpen}>
                            <TextField value={pauseTime} label="Pausenzeit (in Minuten)" onChange={handlePositiveInteger} fullWidth/>
                        </Collapse>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={props.modalShowClose} />
                    <PrimaryButton text="Arbeitszeit erstellen" onClick={() => createShiftPostRequest()} />
                </Modal.Footer>
            </Modal>
        )
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway')
            return;

        setShowErrorSnackbar(false);
    }

    function createErrorSnackBar() {
        return (
            <Snackbar open={showErrorSnackbar} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {emptyTextfields ? "Bitte alle Felder ausfüllen" : "Pausenzeit länger als Arbeitszeit!"}
                </Alert>
            </Snackbar>
        )
    }

    return (
        <>
            {createFastModal()}
            {createErrorSnackBar()}
        </>
    )


} export default CreateShiftModal;