import { Avatar, List, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackButton from "../../../../../sharedComponents/backButton/BackButton";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import "./CoreTimeDetailView.css";
import { Divider } from "rsuite";
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";
import { Button, Modal } from "react-bootstrap";
import CancelButton from "../../../../../sharedComponents/cancelButton/CancelButton";
import axios from "axios";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";

function CoreTimeDetailView() {

    const [isLoading, setIsLoading] = useState(true);

    const [name, setName] = useState("");
    const [weekdays, setWeekdays] = useState([]);
    const [assignedEmployees, setAssignedEmployees] = useState([]);

    const [showRemovingConfigModal, setShowRemovingConfigModal] = useState(false);
    const [showRemovingErrorMessage, setShowRemovingErrorMessage] = useState(false);

    const handleShowRemovingConfigModal = () => setShowRemovingConfigModal(true);
    const handleCloseRemovingConfigModal = () => setShowRemovingConfigModal(false);

    useEffect(() => {
        getCoreTimeData();
        setShowRemovingErrorMessage(false);
    }, [])

    function getCoreTimeData() {
        axios.get('coreWorkingHoursPolicy/getById?id=' + new URLSearchParams(window.location.search).get("id")).then(results => {
            setName(results.data.name);
            setWeekdays(results.data.core_working_hours_periods);
            sessionStorage.setItem("core-time-id", new URLSearchParams(window.location.search).get("id"));
            axios.get('coreWorkingHoursPolicy/getAllEmployeesAssignedToPolicy?id=' + new URLSearchParams(window.location.search).get("id")).then(results2 => {
                setAssignedEmployees(results2.data);
                setIsLoading(false);
            }).catch(() => {
                
            })
        }).catch(() => {
            
        })
    }

    function translateDayOfWeekToGerman(dayOfWeek) {
        switch (dayOfWeek.toUpperCase()) {
          case "MONDAY":
            return "Montag";
          case "TUESDAY":
            return "Dienstag";
          case "WEDNESDAY":
            return "Mittwoch";
          case "THURSDAY":
            return "Donnerstag";
          case "FRIDAY":
            return "Freitag";
          case "SATURDAY":
            return "Samstag";
          case "SUNDAY":
            return "Sonntag";
          default:
            return "Ungültiger Wochentag";
        }
      }

    function extractHoursAndMinutes(timeString) {
        const [hours, minutes] = timeString.split(':');
        return `${hours}:${minutes}`;
    }

    function generateWeekdaysWithTimeSelection() {
        let weekdaysWithTimeSelection = [];
        let selectedDays = {};

        weekdays.forEach((weekday, index) => {
            if (!selectedDays[weekday.day_of_week]) {
                selectedDays[weekday.day_of_week] = true;
                const occurrences = weekdays.filter(item => item.day_of_week === weekday.day_of_week).length;
                if (occurrences > 1) {
                    let sameWeekdayArray = weekdays.filter(item => item.day_of_week === weekday.day_of_week);


                    weekdaysWithTimeSelection.push(
                        <>
                            <div className="core-time-weekday-times" key={index}>
                                <h5>{translateDayOfWeekToGerman(weekday.day_of_week)}</h5>
                                <TextField 
                                    value={extractHoursAndMinutes(sameWeekdayArray[0].start_time)}
                                    label="Von"
                                    variant="outlined" 
                                    disabled={true}
                                />
                                <TextField 
                                    value={extractHoursAndMinutes(sameWeekdayArray[0].end_time)}
                                    label="Bis"
                                    variant="outlined" 
                                    disabled={true}
                                />
                                <TextField 
                                    value={extractHoursAndMinutes(sameWeekdayArray[1].start_time)}
                                    label="Von"
                                    variant="outlined" 
                                    disabled={true}
                                />
                                <TextField 
                                    value={extractHoursAndMinutes(sameWeekdayArray[1].end_time)}
                                    label="Bis"
                                    variant="outlined" 
                                    disabled={true}
                                />
                                
                            </div>
                        </>
                    )
                    } else {
                        weekdaysWithTimeSelection.push(
                            <div className="core-time-weekday-times" key={index}>
                                <h5>{translateDayOfWeekToGerman(weekday.day_of_week)}</h5>
                                <TextField 
                                    value={extractHoursAndMinutes(weekday.start_time)}
                                    label="Von"
                                    variant="outlined" 
                                    disabled={true}
                                />
                                <TextField 
                                    value={extractHoursAndMinutes(weekday.end_time)}
                                    label="Bis"
                                    variant="outlined" 
                                    disabled={true}
                                />
                            </div>
                        ) 
                    }
            }
        })
        return weekdaysWithTimeSelection;
    }

    const removeConfig = () => {
        axios.put('coreWorkingHoursPolicy/removeListOfEmployees', {
            "employee_id_list": assignedEmployees.map(employee => employee.id),
        }).then(response => {
            axios.delete('coreWorkingHoursPolicy/delete?id=' + new URLSearchParams(window.location.search).get("id")).then(response => {
                window.location.assign("/home/settings/coretime");
            }).catch(error => {
                setShowRemovingErrorMessage(true);
            })
        }).catch(error => {
            setShowRemovingErrorMessage(true);
        })
    }

    function createRemovingModal() {
        return (
            <Modal
                show={showRemovingConfigModal}
                onHide={handleCloseRemovingConfigModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Regelung löschen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleCloseRemovingConfigModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Möchten Sie wirklich die Regelung löschen?</p>
                    {showRemovingErrorMessage && <p className="red">Fehler beim Löschen der Regelung. Bitte versuchen Sie es erneut.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleCloseRemovingConfigModal} />
                    <Button variant="danger" onClick={removeConfig}>Regelung löschen</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function generateAssignedEmployees() {
        let assignedEmployeeItems = [];

        if (assignedEmployees.length === 0) {
            assignedEmployeeItems.push(<p>Keine Mitarbeiter zugewiesen.</p>);
        } else {
            assignedEmployees.forEach((employee) => {
                assignedEmployeeItems.push(
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <PermIdentityIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={employee.first_name + " " + employee.last_name} />
                    </ListItem>
                )
            })
        }

        return assignedEmployeeItems;
    }

    return isLoading ?
        <LoadingSpinner />
        :
        <div className="core-time-detail-view-container">
            <Link to={"/home/settings/coretime"}><BackButton /></Link>
            <div className="core-time-content">
                <div className="core-time-config">
                    <div className="core-time-config-header">
                        <TextField 
                            value={name}
                            label="Bezeichnung"
                            variant="outlined" 
                            disabled={true}
                        />
                        <Button variant="danger" onClick={handleShowRemovingConfigModal}>Regelung löschen</Button>
                    </div>
                    <Divider />
                    {generateWeekdaysWithTimeSelection()}
                </div>
                <div className="core-time-employees">
                    <Link to={"assignment"}><PrimaryButton text="Zuweisung ändern"/></Link>
                    <h4>Zugewiesene Mitarbeiter</h4>
                    <List>
                        {generateAssignedEmployees()}
                    </List>
                </div>
            </div>
            {createRemovingModal()}
        </div>
}

export default CoreTimeDetailView;