import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import MessageIcon from '@mui/icons-material/Message';
import "./Settings.css";
import { Link } from "react-router-dom";

function Settings() {
    return (
        <div className="settings-list-container">
            <h3>Einstellungen</h3>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                >
                <Link to={"autoPause/"}>
                    <ListItemButton>
                        <ListItemIcon>
                            <PauseCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Automatisierte Pausenzeit" />
                    </ListItemButton>
                </Link>
                <Link to={"coretime/"}>
                    <ListItemButton>
                        <ListItemIcon>
                            <PendingActionsOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Kernarbeitszeit" />
                     </ListItemButton>
                </Link>
                <Link to={"messageToTablet/"}>
                    <ListItemButton>
                        <ListItemIcon>
                            <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Nachricht an Tablet" />
                    </ListItemButton>
                </Link>
            </List>
        </div>
    )
}

export default Settings;