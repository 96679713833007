import { useState } from "react";
import { Button } from "react-bootstrap";

function CreateShiftRemoveButton(props) {

    const [connectedPauseShift] = useState(props.connectedPause);

    return (
        <Button variant="danger" onClick={() => props.removePause(connectedPauseShift)}>{props.text}</Button>
    )
}

export default CreateShiftRemoveButton;