import { Alert, Snackbar, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CancelButton from "../../../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../../../sharedComponents/primaryButton/PrimaryButton";
import "./Complaint.css";

function Complaint(props) {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setResolveText(null);
        setModalError(false);
    }

    const [resolveText, setResolveText] = useState(null);
    const [modalError, setModalError] = useState(false);
    const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('updateSuccess') === 'true') {
            setIsSuccessMessageVisible(true);
            localStorage.removeItem('updateSuccess');
        }
    }, []);

    function sendPostToResolveComplaint() {
        axios.put('shift/objection/resolve', {
            "shift_objection_id": props.id,
            "resolution": resolveText
        }).then(() => {
            localStorage.setItem('updateSuccess', 'true');
            window.location.reload();
        }).catch(() => {
            setModalError(true);
        })
    }

    function createSnackBarFeedback() {
        return (
            <>
                <Snackbar open={isSuccessMessageVisible} autoHideDuration={6000} onClose={() => setIsSuccessMessageVisible(false)}>
                    <Alert onClose={() => setIsSuccessMessageVisible(false)} severity="success" sx={{ width: '100%' }}>
                        Deine Änderungen wurden erfolgreich gespeichert!
                    </Alert>
                </Snackbar>
            </>
        )
    }

    function createSolveComplaintModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Einspruch lösen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="resolve-complaint-form">
                        <TextField 
                            required 
                            className="create-employee-input" 
                            label="Lösung" 
                            variant="outlined" 
                            onChange={e => setResolveText(e.target.value)}
                        />
                        {modalError && <p className="red">Ein Fehler ist aufgetreten.</p>}
                    </div> 
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Einspruch lösen" disabled={resolveText === null} onClick={() => sendPostToResolveComplaint()} />
                </Modal.Footer>
            </Modal>
        )
    }

    return props.resolved ? 
    (
        <div className="shift-complaint-container">
            <h5>Einspruch vom {new Date(props.createdAt).toLocaleDateString()}</h5>  
            <p>Grund: {props.complaintsText}</p> 
            <p className="green">Lösung: {props.resolution}</p>
            <p>Gelöst von {props.resolvedBy} ({new Date(props.resolvedAt).toLocaleDateString()})</p>
        </div>
    )
    :
    (
        <div className="shift-complaint-container">
            <h5>Einspruch vom {new Date(props.createdAt).toLocaleDateString()}</h5>  
            <p>Grund: {props.complaintsText}</p> 
            <Button variant="danger" onClick={handleShow}>Einspruch lösen</Button>
            {createSolveComplaintModal()}
            {createSnackBarFeedback()}
        </div>
    )
}

export default Complaint;