
export default function formatDuration(duration) {
    const { hours, minutes } = duration;

    let hourString;
    if((hours < 0 && hours > -10) || (hours == 0 && minutes < 0)) hourString = '-' + Math.abs(hours).toString().padStart(2, '0');
        else hourString = hours.toString().padStart(2, '0');
    const minutesString = Math.abs(minutes).toString().padStart(2, '0');

    return hourString + ":" + minutesString + " Std";
}