import "./SelectedShift.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import LoadingSpinner from "../../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import Activity from "./activity/Activity";
import { Link } from "react-router-dom";
import SelectedShiftHistory from "./history/SelectedShiftHistory";
import BackButton from "../../../../../../sharedComponents/backButton/BackButton";
import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import CreatingPause from "./creatingPause/CreatingPause";
import SelectedShiftSummary from "./summary/SelectedShiftSummary";
import ShiftComplaints from "./complaints/ShiftComplaints";
import CancelButton from "../../../../../../sharedComponents/cancelButton/CancelButton";
import ShiftIgnoreButton from "./ignoreButton/ShiftIgnoreButton";

function SelectedShift() {

    const [isLoading, setIsLoading] = useState(true);

    const [shiftInformation, setShiftInformation] = useState(null);
    const [activities, setActivities] = useState([]);
    const [changingActivitiesList] = useState([]);

    const [isCreatingPauseDisabled, setIsCreatingPauseDisabled] = useState(false);
    const [showCreatingPauseModal, setShowCreatingPauseModal] = useState(false);

    const [showCreatingChangingPauseAndEndTimeModal, setShowCreatingChangingPauseAndEndTimeModal] = useState(false);
    const handleShowCreatingChangingPauseAndEndTimeModal = () => setShowCreatingChangingPauseAndEndTimeModal(true);
    const handleCloseCreatingChangingPauseAndEndTimeModal = () => setShowCreatingChangingPauseAndEndTimeModal(false);
    const [changingPauseAndEndTimeError, setChangingPauseAndEndTimeError] = useState(false);

    //TODO Live-Ansicht Schicht
    const [isLive, setIsLive] = useState(false);

    useEffect(() => {
        getShiftDetails();
    }, [])

    function getShiftDetails() {
        axios.get('activity/getByShiftBeginId?id=' + new URLSearchParams(window.location.search).get("beginId")).then(results => {
            results.data.forEach(activity => {
                if (activity.generated)
                    setIsCreatingPauseDisabled(true);
            });

            setActivities(results.data);
            getShiftHistory(results.data);
        }).catch(() => {
            axios.get('activity/getAllForOngoingShiftByEmployeeId?id=' + sessionStorage.getItem("employeeId")).then(results => {
                setIsLive(true);
                setActivities(results.data);
                setIsLoading(false);
            }).catch(() => console.log("error"))
        })
    }

    async function getShiftHistory(activitiesForHistory) {
        try {
            await Promise.all(activitiesForHistory.map(async (activity) => {
                const results = await axios.get('activity/modifications/getAllByActivityId?id=' + activity.id);
                for (let result of results.data) {
                    changingActivitiesList.push(result);
                }
            }))

            await axios.get('shift/getByShiftBeginId?id=' + new URLSearchParams(window.location.search).get("beginId")).then(results => {
                setShiftInformation(results.data);
            }).catch(() => console.log("fdsf"))
        } catch (error) {}

        setIsLoading(false);
    }

    function getActivities() {
        let allActivities = [];
        let generatedPauseAndEnd = isGeneratedPauseEndAndShiftEnd();
        let notCompletedCounter = 0;

        activities.map((item, index) => {
            let activityBefore;
            let activityAfter;

            if (activities.length - 1 === index) {
                activityBefore = activities[index - 1];
                activityAfter = null;
            } else if (index === 0) {
                activityBefore = null;
                activityAfter = activities[index + 1];
            } else {
                activityBefore = activities[index - 1];
                activityAfter = activities[index + 1];
            }

            if (item.generated) {
                notCompletedCounter++;

                if (notCompletedCounter < 2) {
                    allActivities.push(
                        <div className="activity-container error-container" key={index}>
                            <Activity activityItem={item} index={index} isLive={isLive}
                                      activityBefore={activityBefore} activityAfter={activityAfter} notEditable={false}
                                      beginId={new URLSearchParams(window.location.search).get("beginId")}
                                      fullGenerateShift={false}/>
                        </div>
                    )
                } else {
                    allActivities.push(
                        <OverlayTrigger
                            key={index}
                            placement="right"
                            overlay={<Tooltip id="button-tooltip-2">Bitte bearbeite zuerst die vorherige Aktivität</Tooltip>}>
                            <div className="activity-container error-container" key={index}>
                                <Activity activityItem={item}  index={index} isLive={isLive}
                                          activityBefore={activityBefore} activityAfter={activityAfter}
                                          notEditable={true} beginId={new URLSearchParams(window.location.search).get("beginId")}/>
                            </div>
                        </OverlayTrigger>
                    )
                }
            } else if (generatedPauseAndEnd && activityAfter.generated) {
                allActivities.push (
                    <>
                        <div className="activity-container" key={index}>
                            <Activity activityItem={item} index={index} isLive={isLive}
                                  activityBefore={activityBefore} activityAfter={activityAfter}
                                  beginId={new URLSearchParams(window.location.search).get("beginId")}/>
                        </div>
                        <div className="generated-pause-and-end-button">
                            <CancelButton text="Pausenanfang in Arbeitszeitende umwandeln" onClick={handleShowCreatingChangingPauseAndEndTimeModal}/>
                        </div>   
                    </>
                )
            } else {
                allActivities.push(
                    <div className="activity-container" key={index}>
                        <Activity activityItem={item} index={index} isLive={isLive}
                                  activityBefore={activityBefore} activityAfter={activityAfter}
                                  beginId={new URLSearchParams(window.location.search).get("beginId")}/>
                    </div>
                )
            }

        })
        return allActivities;
    }

    function isGeneratedPauseEndAndShiftEnd() {
        let generatedPauseEnd = false;
        let generatedShiftEnd = false;
        
        activities.forEach(element => {
            if ((element.activity_type === 'pause_end') && element.generated)
                generatedPauseEnd = true;
            if ((element.activity_type === 'shift_end') && element.generated)
                generatedShiftEnd = true;
        })

        if (generatedPauseEnd && generatedShiftEnd)
            return true;
        else
            return false;
    }

    const sendPutToChangeGeneratedPauseAndEndActivities = () => {
        let pauseStartActivity;
        let generatedPauseEndActivity;
        let generatedShiftEndActivity;

        activities.forEach(element => {
            if (element.activity_type === 'pause_begin') {
                pauseStartActivity = element;
            } else if ((element.activity_type === 'pause_end') && element.generated) {
                generatedPauseEndActivity = element;
            } else if ((element.activity_type === 'shift_end') && element.generated) {
                generatedShiftEndActivity = element;
            }
        })

        const changeActivities = () => {
            return new Promise((resolve, reject) => {
                let beginId = new URLSearchParams(window.location.search).get("beginId");

                axios.put('activity/updateById', {
                    "shift_begin_id": beginId,
                    "id": generatedPauseEndActivity.id,
                    "timestamp": new Date(new Date(pauseStartActivity.timestamp).getTime() + 100).toISOString(),
                    "reason": "Ausversehen Pausenanfang statt Arbeitszeitende"
                }).then(() => {
                    return axios.put('activity/updateById', {
                        "shift_begin_id": beginId,
                        "id": generatedShiftEndActivity.id,
                        "timestamp": new Date(new Date(pauseStartActivity.timestamp).getTime() + 200).toISOString(),
                        "reason": "Ausversehen Pausenanfang statt Arbeitszeitende"
                    });
                }).then(() => resolve("Data changed!")).catch(() => reject(new Error("Failed")));
            })
        }

        changeActivities().then(() => {
            window.location.reload();
        }).catch(() => {
            setChangingPauseAndEndTimeError(true);
        })
    }

    function createChangingGeneratedPauseAndEndTimeModal() {
        return (
            <Modal
                show={showCreatingChangingPauseAndEndTimeModal}
                onHide={handleCloseCreatingChangingPauseAndEndTimeModal}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Pausenanfang in Arbeitszeitende umwandeln</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleCloseCreatingChangingPauseAndEndTimeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Wenn Sie diese Funktion ausführen, werden die beiden automatisch generierten Aktivitäten automatisiert bearbeitet.</p>
                    <p>Das Ende der Pausen- und Arbeitszeit orientiert sich am Anfangszeitpunkt der Pause.</p>
                    {changingPauseAndEndTimeError && <p className="red-color">Ein Fehler ist aufgetreten.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleCloseCreatingChangingPauseAndEndTimeModal} />
                    <PrimaryButton text="Pausenanfang in Arbeitszeitende umwandeln" onClick={sendPutToChangeGeneratedPauseAndEndActivities} />
                </Modal.Footer>
            </Modal>
        )
    }

    return isLoading ?
        <LoadingSpinner />
        :
        <>
            <div className="all-activity-header">
                <Link to={"/home/employees/employeeView?employeeId=" + sessionStorage.getItem("employeeId")}>
                    <BackButton text="Zurück" />
                </Link>
                {isLive && <p className="live-shift-warning-text">Dies ist eine Live-Arbeitszeit, welche gerade noch läuft</p>}
            </div>
            <div className="shift-main-overview-container">
                <div className="all-activity-container">
                    <div className="activities-header">
                        <h2>Aktivitäten</h2>
                        {!isLive && isCreatingPauseDisabled && <PrimaryButton text="Pause einfügen" onClick={() => setShowCreatingPauseModal(true)} title="Sie müssen zuerst die Arbeitszeit korrigieren." disabled={isCreatingPauseDisabled}/>}
                        {!isLive && !isCreatingPauseDisabled && <PrimaryButton text="Pause einfügen" onClick={() => setShowCreatingPauseModal(true)} disabled={isCreatingPauseDisabled}/>}
                    </div>
                    {getActivities()}
                </div>
                <div className="shift-additional-information-container">
                    {!isLive && <SelectedShiftSummary shiftInformation={shiftInformation} />}
                    {!isLive && <ShiftComplaints shiftInformation={shiftInformation} />}
                    {!isLive && <ShiftIgnoreButton ignored={shiftInformation.is_ignored} beginId={shiftInformation.shift_begin_activity_id}/>}
                </div>
            </div>
            {!isLive && <SelectedShiftHistory history={changingActivitiesList} />}
            <CreatingPause activities={activities} beginId={new URLSearchParams(window.location.search).get("beginId")} showCreatingPauseModal={showCreatingPauseModal} setShowCreatingPauseModal={setShowCreatingPauseModal}/>
            {createChangingGeneratedPauseAndEndTimeModal()}
        </>
}

export default SelectedShift;