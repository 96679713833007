import { useEffect, useState } from "react";
import "./CreatingPause.css";
import CancelButton from "../../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";
import { DatePicker } from "rsuite";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Modal } from "react-bootstrap";
import axios from "axios";

function CreatingPause(props) {
    const [creatingPauseBegin, setCreatingPauseBegin] = useState("");
    const [creatingPauseEnd, setCreatingPauseEnd] = useState(""); 
    const [timeDifference, setTimeDifference] = useState("");
    const [isShowingTimeDifference, setIsShowingTimeDifference] = useState(false); 
    const [activities] = useState(props.activities);

    const handleClose = () => {
        setReason("");
        setOtherReason("");
        setShowOtherReasonInput(false);
        setCreatingPauseBegin("");
        setCreatingPauseEnd("");
        setIsShowingTimeDifference(false);
        setMissingInputError(false);
        setCreatingPauseRequestError(false);
        setCreatingPauseValidationError(false);
        props.setShowCreatingPauseModal(false);
        setCreatingPauseButtonDisabled(true);
    }

    const [reason, setReason] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const [showOtherReasonInput, setShowOtherReasonInput] = useState(false);

    const [missingInputError, setMissingInputError] = useState(false);
    const [creatingPauseValidationError, setCreatingPauseValidationError] = useState(false);
    const [creatingPauseButtonDisabled, setCreatingPauseButtonDisabled] = useState(true);
    const [creatingPauseRequestError, setCreatingPauseRequestError] = useState(false);

    const handleCreatingPauseBeginChange = (date) => {
        let startTime = date.toLocaleTimeString().slice(0, 5);
        setCreatingPauseBegin(startTime);
        setCreatingPauseValidationError(false);
        validatePauseInput(startTime, creatingPauseEnd);
        calculateTimeDifference(startTime, creatingPauseEnd);
    }

    const handleCreatingPauseEndChange = (date) => {
        let endTime = date.toLocaleTimeString().slice(0, 5);
        setCreatingPauseEnd(endTime);
        setCreatingPauseValidationError(false);
        validatePauseInput(creatingPauseBegin, endTime);
        calculateTimeDifference(creatingPauseBegin, endTime);
    }

    useEffect(() => {

    }, [isShowingTimeDifference])

    const changeReason = (event) => {
        switch (event.target.value) {
            case "Technisches Problem":
                setReason("Technisches Problem");
                setShowOtherReasonInput(false);
                break;
            case "Vergessen Pause zu erfassen":
                setReason("Vergessen Pause zu erfassen");
                setShowOtherReasonInput(false);
                break;
            case "Sonstiges":
                setReason("Sonstiges");
                setShowOtherReasonInput(true);
                setMissingInputError(false);
                break;
            default:
                break;
        }
    }

    function getDropdownReasons() {
        return (    
        [
            <MenuItem key={"Technische Problem Menü"} value={"Technisches Problem"}>Technisches Problem</MenuItem>,
            <MenuItem key={"Vergessen auszuloggen Menü"} value={"Vergessen Pause zu erfassen"}>Vergessen Pause zu erfassen</MenuItem>,
            <MenuItem key={"Sonstiges Menü"} value={"Sonstiges"}>Sonstiges</MenuItem>
        ])
    }

    const creatingPause = () => {
        if (showOtherReasonInput) {
            if (otherReason !== "") {
                sendCreatePauseRequest();
            } else {
                setMissingInputError(true);
            }
        } else {
            if (reason !== "") {
                sendCreatePauseRequest();
            } else {
                setMissingInputError(true);
            }
        }
    }

    function sendCreatePauseRequest() {
        let currentReason;
        if(reason === "Sonstiges")
            currentReason = otherReason;
        else 
            currentReason = reason;

        axios.post('shift/asynchronousAddAnotherPauseToShift', {
            "shift_begin_id": props.beginId,
            "pause_begin": getStartInputDate(creatingPauseBegin).toISOString(),
            "pause_end": getEndInputDate(creatingPauseBegin, creatingPauseEnd).toISOString(),
            "reason": currentReason
        }).then(response => {
            handleClose();
            window.location.reload();
        }).catch(error => {
            setCreatingPauseRequestError(true);
        })
    }

    function getStartInputDate(startTime) {
        let firstActivityTimestamp = activities[0].timestamp;
        let firstActivityDateOnly = firstActivityTimestamp.split('T')[0];
        // => Man erhält nur das Datum der ersten Aktivität

        let lastActivityTimestamp = activities[activities.length - 1].timestamp;
        let lastActivityDateOnly = lastActivityTimestamp.split('T')[0];
        // => Man erhält nur das Datum der letzten Aktivität

        let startTimeAsDate = new Date(`${firstActivityDateOnly}T${startTime}`);

        if (startTimeAsDate < new Date(firstActivityTimestamp))
            return new Date(`${lastActivityDateOnly}T${creatingPauseBegin}`);
        else
            return new Date(`${firstActivityDateOnly}T${startTime}`);  
    }

    function getEndInputDate(startTime, endTime) {
        let firstActivityTimestamp = activities[0].timestamp;
        let firstActivityDateOnly = firstActivityTimestamp.split('T')[0];

        let lastActivityTimestamp = activities[activities.length - 1].timestamp;
        let lastActivityDateOnly = lastActivityTimestamp.split('T')[0];

        let startTimeAsDate = new Date(`${firstActivityDateOnly}T${startTime}`);
        let endTimeAsDate = new Date(`${firstActivityDateOnly}T${endTime}`);

        if ((firstActivityDateOnly !== lastActivityDateOnly) && (startTimeAsDate.getHours() < new Date(firstActivityTimestamp).getHours())) {
            return new Date(`${lastActivityDateOnly}T${endTime}`);
        }

        if (startTimeAsDate > endTimeAsDate)
            return new Date(`${lastActivityDateOnly}T${endTime}`);
        else
            return new Date(`${firstActivityDateOnly}T${endTime}`);
    }

    function validatePauseInput(startTime, endTime) {
        // startTime = 12:00
        // endTime = 13:00

        if (startTime !== "" && endTime !== "") {
            // Datum und Zeit der ersten Aktivität
            let firstActivityTimestamp = activities[0].timestamp;
            // Datum und Zeit der letzten Aktivität
            let lastActivityTimestamp = activities[activities.length - 1].timestamp;

            // Man bekommt dynamisch das Datum zusammen mit der startTime als Date Objekt
            let beginDate = getStartInputDate(startTime);
            // Man bekommt dynamisch das Datum zusammen mit der endTime als Date Objekt
            let endDate = getEndInputDate(startTime, endTime);

            if (endDate > beginDate) {
                if (beginDate > new Date(firstActivityTimestamp) && endDate < new Date(lastActivityTimestamp)) {
                    setCreatingPauseButtonDisabled(false);
                } else {
                    setCreatingPauseButtonDisabled(true);
                    setCreatingPauseValidationError(true);
                }
            } else {
                setCreatingPauseValidationError(true);
                setCreatingPauseButtonDisabled(true);
            }
        }
    }

    function calculateTimeDifference(startTime, endTime) {
        if (startTime !== "" && endTime !== "") {
            let beginDate = new Date(`1970-01-01T${startTime}`);
            let endDate = new Date(`1970-01-01T${endTime}`);

            let timeDifferenceInMinutens = (endDate - beginDate) / 1000 / 60;
            if (timeDifferenceInMinutens < 0) {
                endDate = new Date(`1970-01-02T${endTime}`);
                let midNight = new Date(`1970-01-01T24:00`);
                let timeToMidnight = (midNight - beginDate) / 1000 / 60;
                
                let timeAfterMidnight = (endDate - midNight) / 1000 / 60;
                
                timeDifferenceInMinutens = timeToMidnight + timeAfterMidnight;
            }
            
            setIsShowingTimeDifference(true);
            setTimeDifference("Dauer: " + timeDifferenceInMinutens + " Minuten");
        }
    }

    return (
        <>
            <Modal
                show={props.showCreatingPauseModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Pause einfügen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {creatingPauseValidationError && <p className="red">Dies ist keine gültige Pause.</p>}
                    <p>Anfang der Pause</p>
                    {!creatingPauseBegin && <DatePicker format="HH:mm" calendarDefaultDate={new Date(0, 0, 0, 0, 0)} oneTap ranges={[]} style={{ width: 260 }} onChange={handleCreatingPauseBeginChange} onSelect={handleCreatingPauseBeginChange} className="edit-creating-pause-begin-input" locale={{hours: 'Stunden', minutes: 'Minuten',}} placeholder="Wählen Sie eine Uhrzeit aus"/>}
                    {creatingPauseBegin && <DatePicker format="HH:mm" oneTap ranges={[]} style={{ width: 260 }} onChange={handleCreatingPauseBeginChange} onSelect={handleCreatingPauseBeginChange} className="edit-creating-pause-begin-input" value={new Date().setHours(creatingPauseBegin.split(":")[0], creatingPauseBegin.split(":")[1], 0)} locale={{hours: 'Stunden', minutes: 'Minuten',}} placeholder="Wählen Sie eine Uhrzeit aus"/>}
                    <p>Ende der Pause</p>
                    {!creatingPauseEnd && <DatePicker format="HH:mm" calendarDefaultDate={new Date(0, 0, 0, 0, 0)} oneTap ranges={[]} style={{ width: 260 }} onChange={handleCreatingPauseEndChange} onSelect={handleCreatingPauseEndChange} className="edit-creating-pause-end-input" locale={{hours: 'Stunden', minutes: 'Minuten',}} placeholder="Wählen Sie eine Uhrzeit aus"/>}
                    {creatingPauseEnd && <DatePicker format="HH:mm" oneTap ranges={[]} style={{ width: 260 }} onChange={handleCreatingPauseEndChange} onSelect={handleCreatingPauseEndChange} className="edit-creating-pause-end-input" value={new Date().setHours(creatingPauseEnd.split(":")[0], creatingPauseEnd.split(":")[1], 0)} locale={{hours: 'Stunden', minutes: 'Minuten',}} placeholder="Wählen Sie eine Uhrzeit aus"/>}
                    {isShowingTimeDifference && <p className="modal-creating-pause-info">{timeDifference}</p>}
                    <FormControl className="edit-reason-switch-container" fullWidth>
                            <InputLabel id="edit-reason-select-label">Bitte wählen Sie hier einen Grund aus</InputLabel>
                            <Select
                                labelId="edit-reason-select-label"
                                label="Bitte wählen Sie hier einen Grund aus"
                                value={reason}
                                onChange={changeReason}
                            >
                                {getDropdownReasons()}
                            </Select>
                    </FormControl>
                    {showOtherReasonInput && 
                        <div>
                            <p>Sonstiges</p>
                            <textarea className="other-reason-textarea" rows="2" placeholder="Geben Sie einen alternativen Grund an." onChange={(event) => setOtherReason(event.target.value)}></textarea>
                        </div>
                    }
                    {missingInputError && <p className="red">Bitte geben Sie einen Grund an.</p>}
                    {creatingPauseRequestError && <p className="red">Ein Fehler beim Einfügen der Pause ist aufgetreten. Prüfen Sie ihre Eingaben.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Pause einfügen" onClick={creatingPause} disabled={creatingPauseButtonDisabled} />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreatingPause;