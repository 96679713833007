import { Avatar, Card, CardContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { HealthAndSafety, BeachAccess, SyncAlt, Functions, NoteAdd, AccessTime, PunchClock, Celebration } from "@mui/icons-material";
import formatDuration from "../../../../../../sharedComponents/FormatDuration";
import parseDuration from "../../../../../../sharedComponents/ParseDuration";

function WorktimeDashboard(props){

    function dashboardCards(){
        let index = props.allMonths.findIndex((item) => {
            let arrayDate = new Date(item.month);
            let todayDate = new Date();
            return arrayDate.getMonth() === todayDate.getMonth() && arrayDate.getFullYear() === todayDate.getFullYear();
        })
        const previousMonthObject = props.allMonths[index-1];
        const currentMonthObject = props.allMonths[index];
        const nextMonthObject = props.allMonths[index+1];
        
        return (
            <>
                {previousMonthObject !== undefined ? dashboardSingleMonthCard(previousMonthObject, true) : false}
                {dashboardSingleMonthCard(currentMonthObject, false)}
                {nextMonthObject !== undefined ? dashboardSingleMonthCard(nextMonthObject, true) : false}
            </>
            
        )
    }

    function dashboardSingleMonthCard(currentMonthObject, sideCardBoolean){
        if(currentMonthObject === undefined) return;
        const currentDate = new Date(currentMonthObject.month).toLocaleDateString('de', { month: 'long', year: 'numeric' })
        let cardHeaderAddOn = sideCardBoolean ? "" : "(aktueller Monat)"
        const sideCardResultBoolean = (sideCardBoolean && currentMonthObject.result === null)
        const illnessTime = formatDuration(parseDuration(currentMonthObject.total_payed_illness_duration));
        const vacationTime = formatDuration(parseDuration(currentMonthObject.total_payed_vacation_duration));
        const paidHoliday = formatDuration(parseDuration(currentMonthObject.total_payed_holiday_duration))

        return (
            <Card sx={{ minWidth: 400, maxWidth: 400, backgroundColor: '#f5f5f5', transform: sideCardBoolean ? 'scale(0.9)' : false }}>
                <CardContent>
                    <div className="card-header">
                        <Typography variant="h5">{currentDate}</Typography>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>{cardHeaderAddOn}</Typography>
                    </div>                    
                    <Divider/>
                    <List>
                        <ListItem secondaryAction={<ListItemText primary={formatDuration(parseDuration(currentMonthObject.transfer_from_last_month))} />}>
                            <ListItemAvatar>
                                <Avatar>
                                <SyncAlt fontSize="small"/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={"Transfer: "}/>
                        </ListItem>
                        <ListItem secondaryAction={<ListItemText primary={formatDuration(parseDuration(currentMonthObject.target_working_hours))} />}>
                            <ListItemAvatar>
                                <Avatar>
                                <AccessTime fontSize="small"/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={"Soll-Zeit: "}/>
                        </ListItem>
                        <ListItem secondaryAction={<ListItemText primary={formatDuration(parseDuration(currentMonthObject.total_tracked_shifts_duration))} />}>
                            <ListItemAvatar>
                                <Avatar>
                                <PunchClock fontSize="small"/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={"aufgezeichnete Stunden: "} primaryTypographyProps={{style: {width: "100px"}}}/>
                        </ListItem>

                        {illnessTime !== "00:00 Std" && <ListItem secondaryAction={<ListItemText primary={illnessTime} />}>
                            <ListItemAvatar>
                                <Avatar>
                                <HealthAndSafety fontSize="small"/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={"Bezahlte Krankenstunden: "} />
                        </ListItem>}

                        {vacationTime !== "00:00 Std" && <ListItem secondaryAction={<ListItemText primary={vacationTime} />}>
                            <ListItemAvatar>
                                <Avatar>
                                <BeachAccess fontSize="small" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={"Bezahlte Urlaubsstunden: "} />
                        </ListItem>}

                        {paidHoliday !== "00:00 Std" && <ListItem key="paidHoliday" secondaryAction={<ListItemText primary={paidHoliday} />}>
                            <ListItemAvatar>
                                <Avatar>
                                <Celebration fontSize="small" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={"Bezahlte Feiertagsstunden: "} />
                        </ListItem>}

                        <ListItem secondaryAction={                  
                            <ListItemText primary={formatDuration(parseDuration(currentMonthObject.total_sum_of_corrections))} />                        
                        }>
                            <ListItemAvatar>
                                <Avatar>
                                <NoteAdd fontSize="small"/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={"Korrekturstunden: "}/>
                        </ListItem>

                        <Divider/>
                        <ListItem secondaryAction={<ListItemText primary={sideCardResultBoolean ? "Noch nicht verfügbar" : formatDuration(parseDuration(currentMonthObject.result))} primaryTypographyProps={{style:{fontWeight: sideCardResultBoolean ? 'normal' : 'bold', fontStyle: sideCardResultBoolean ? 'italic' : 'normal', fontSize: '20px', color: 'black'}}}/>}>
                            <ListItemAvatar>
                                <Avatar>
                                <Functions fontSize="small"/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={"Bilanz: "} primaryTypographyProps={{style:{fontWeight: 'bold', fontSize: '20px'}}} />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        )
    }

    return(
        <>{dashboardCards()}</>
    )
} 

export default WorktimeDashboard;