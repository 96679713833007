import { Tab, Tabs } from "@mui/material";
import Complaint from "./complaint/Complaint";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import "./ShiftComplaints.css";
import { useState } from "react";

function ShiftComplaints(props) {

    const [selectedTab, setSelectedTab] = useState(0);
    const [complaints] = useState(props.shiftInformation.related_shift_objections);

    const changeTab = () => {
        if (selectedTab === 0)
            setSelectedTab(1);
        else
            setSelectedTab(0);
    }

    function createContent() {
        let complaintsObjects = [];
        if (selectedTab === 0) {
            complaints.map((item, index) => {
                if (!item.resolved) {
                    complaintsObjects.push(
                        <Complaint key={index} complaintsText={item.objection_text} createdAt={item.created_at} resolved={item.resolved} id={item.id} />
                    )
                }
            })
        } else if (selectedTab === 1) {
            complaints.map((item, index) => {
                if (item.resolved) {
                    complaintsObjects.push(
                        <Complaint key={index} complaintsText={item.objection_text} createdAt={item.created_at} resolved={item.resolved} resolution={item.resolution} resolvedAt={item.resolved_at} resolvedBy={item.resolved_by} shiftInformationAttachmentId={item.shift_information_attachment_id} />
                    )
                }
            })
        }

        if (complaintsObjects.length !== 0) {
            return complaintsObjects;
        } else {
            return <p>Keinen Einspruch.</p>
        }
    }

    function getRedBorderClassOrNot() {
        const hasUnresolvedComplaint = complaints.some(item => !item.resolved);
        return hasUnresolvedComplaint ? "red-border" : "";
    }

    function createFullComplaintsContainer() {
        if (complaints.length !== 0) {
            return (
                <div className={"shift-complaints-container " + getRedBorderClassOrNot()}>
                    <div className="card text-center">
                        <div className="card-header">
                            Einspruch vom Mitarbeiter
                        </div>
                        <div className="card-body">
                            <Tabs centered={true} onChange={changeTab} value={selectedTab} >
                                <Tab icon={<ErrorOutlineIcon />} iconPosition="start" label="Offen" />
                                <Tab icon={<CheckIcon />} iconPosition="start" label="Gelöst" />
                            </Tabs>
                            {createContent()}
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            {createFullComplaintsContainer()}
        </>
    )     
}

export default ShiftComplaints;