import { useEffect } from "react";


function ScrollRecovery(props){
    useEffect(() => {
        const scrollPosition = localStorage.getItem(props.scrollKey);
        if(props.setCondition && scrollPosition){
            window.scrollTo(0, parseInt(scrollPosition))
        }
    }, [props.setCondition, props.scrollKey])

    useEffect(() => {
        const handleScroll = () => {
            localStorage.setItem(props.scrollKey, JSON.stringify(window.scrollY));
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
}

export default ScrollRecovery;