import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import "./EmployeeCreationAbsence.css";
import { Button, ButtonGroup, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from "@mui/material";
import { Modal } from "react-bootstrap";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from '@mui/icons-material/Add';
import CancelButton from "../../../../../../sharedComponents/cancelButton/CancelButton";
import { useState } from "react";
import axios from "axios";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const weekDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]

function EmployeeCreationAbsence() {

    const [openModal, setOpenModal] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);

    const changeTab = () => {
        setAddingNoInputError(false);
        setAddingAbsenceError(false);
        if (selectedTab === 0) {
            setSelectedTab(1);
        } else {
            setSelectedTab(0);
        }
    }

    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [countDays, setCountDays] = useState(1);
    const [countHours, setCountHours] = useState(8);
    const [requestParameterHourMinute, setRequestParameterHourMinute] = useState({});
    const [paidCheckbox, setPaidCheckbox] = useState(true);
    const [vacationOrIllness, setVacationOrIllness] = useState("vacation"); 
    const [comment, setComment] = useState("");
    const [addingAbsenceError, setAddingAbsenceError] = useState(false);
    const [addingNoInputError, setAddingNoInputError] = useState(false);

    const [createAbsenceDays, setCreateAbsenceDays] = useState([]);

    const [values, setValues] = useState([]);

    const handleClose = () => {
        setAddingAbsenceError(false);
        setOpenModal(false);
    }

    const handleAddClick = () => {
        setOpenModal(true);
        setCountDays(1);
        setCountHours(8);
        setRequestParameterHourMinute({hour: 8, minute: 0})
        setPaidCheckbox(true);
        setVacationOrIllness("vacation");
        setComment("");
        setCreateAbsenceDays([]);
        setValues([]);
        setSelectedMonth(String(new Date().getMonth() + 1).padStart(2, '0'));
        setSelectedYear(new Date().getFullYear());
        setAddingNoInputError(false);
        setSelectedTab(0);
    }

    const handleDayChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setCountDays(Math.max(Number(e.target.value), 1));
        }        
    }
    
    const handleHourChange = (e) => {
        const regex = /^\d*,?\d{0,2}$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setCountHours(e.target.value);

            let valueArray = e.target.value.split(",");
            let timeObject;
            if(valueArray.length === 1 || valueArray[1] === ""){
                timeObject = {hour: valueArray[0], minute: 0};
                setRequestParameterHourMinute(timeObject);    
            } else {
                timeObject = {hour: valueArray[0], minute: Math.round((parseInt(valueArray[1])/Math.pow(10, valueArray[1].length))*60)};
                setRequestParameterHourMinute(timeObject);
            }
        }

        
        
    }

    const handleVacationSelect = (event) => {
        setVacationOrIllness(event.target.value);
    }

    const handleMonthSelect = (event) => {
        setSelectedMonth(event.target.value);
    }

    const handleYearSelect = (event) => {
        setSelectedYear(event.target.value);
    }

    const handleCheckbox = (event) => {
        setPaidCheckbox(event.target.checked)
    }

    const handleAddVacation = () => {
        if (selectedTab === 0) {
            prepareTerminatedAbsenceDays();
            sendTerminatedPostRequest();
        } else {
            for(let i = 0; i < countDays; i++)
                fillAbsenceDaysArray(selectedYear.toString(), selectedMonth, "01", false);
            sendNonTerminatedPostRequest();
        }
    }

    // Returns the number of different months for monthly post requests
    function prepareTerminatedAbsenceDays() {
        values.forEach((value) => {
            const date = new Date(value);
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            if (month < 10)
                month = "0" + month;

            if (day < 10)
                day = "0" + day;

            fillAbsenceDaysArray(year, month, day, true);
        })
    }

    function fillAbsenceDaysArray(year, month, day, isTerminatedAbsensce) {
        createAbsenceDays.push({
            "date": year + "-" + month + "-" + day,
            "duration": "PT"+ requestParameterHourMinute.hour + "H" + requestParameterHourMinute.minute + "M",
            "ignore_specific_date": !isTerminatedAbsensce
        })
    }

    function sendTerminatedPostRequest() {
        // Group array after months
        const groupedAbsences = {};
        createAbsenceDays.forEach(absence => {
            const monthYear = absence.date.substring(0, 7); // Extrahiere Jahr und Monat (z.B. "2024-02")
            if (!groupedAbsences[monthYear]) {
                groupedAbsences[monthYear] = [];
            }
            groupedAbsences[monthYear].push(absence);
        })

        // For every month = One post request
        const promises = Object.keys(groupedAbsences).map(monthYear => {
            const absencesInMonth = groupedAbsences[monthYear];
            return axios.post('absence/createNewDatedAbsencePeriod', {
                "employee_id": new URLSearchParams(window.location.search).get("employeeId"),
                "absence_type": vacationOrIllness,
                "paid": paidCheckbox,
                "comment": comment,
                "absence_days": absencesInMonth
            })
        })

        if (promises.length === 0) {
            setAddingNoInputError(true);
        } else {
            Promise.all(promises)
                .then(() => {
                    handleClose();
                    window.location.reload();
                })
                .catch(error => {
                    setAddingAbsenceError(true);
                    setCreateAbsenceDays([]);
                })
        }
    }

    function sendNonTerminatedPostRequest() {
        axios.post('absence/createNewAbsencePeriod', {
            "employee_id": new URLSearchParams(window.location.search).get("employeeId"),
            "absence_type": vacationOrIllness,
            "paid": paidCheckbox,
            "comment": comment,
            "absence_days": createAbsenceDays
        }).then(() => {
            handleClose();
            window.location.reload();
        }).catch(() => {
            setAddingAbsenceError(true);
            setCreateAbsenceDays([]);
        })
    }

    function createModalContent() {
        if (selectedTab === 0) {
            return (
                <div className="create-absence-modal">
                    <FormControl className="vacation-select-formcontrol">
                        <InputLabel>Art der Abwesenheit</InputLabel>
                        <Select 
                            value={vacationOrIllness}
                            label="Urlaub oder Krankheit"
                            onChange={handleVacationSelect}
                            fullWidth
                        >
                            <MenuItem value={"vacation"}>Urlaub</MenuItem>
                            <MenuItem value={"illness"}>Krankheit</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="set-hours-minutes">
                        <div className="set-buttongroup">
                            <InputLabel>Stunden pro Tag</InputLabel>
                            <ButtonGroup>
                                {/* <Button
                                    onClick={() => setCountHours((prev) => prev - 1)}
                                    disabled={countHours === 0}
                                >
                                    <RemoveIcon fontSize="small" />
                                </Button> */}
                                <TextField size="small" onChange={handleHourChange} value={countHours} sx={{width: "120px"}}/>
                                {/* <Button onClick={() => setCountHours((prev) => prev + 1)}>
                                    <AddIcon fontSize="small" />
                                </Button> */}
                            </ButtonGroup>
                            <p>Umgerechnet {requestParameterHourMinute.hour}Std {requestParameterHourMinute.minute}min</p>
                        </div>
                    </div>
                    <Divider />
                    <Calendar 
                        multiple
                        format="DD.MM.YYYY"
                        minDate={"01.01.2020"}
                        maxDate={"31.12.2300"}
                        weekDays={weekDays}
                        months={months}
                        value={values} 
                        onChange={setValues}
                        weekStartDayIndex={1}
                        plugins={[
                            <DatePanel sort="date" header="Ausgewählt"/>
                        ]}
                    />
                    <FormControlLabel control={<Checkbox checked={paidCheckbox} onChange={handleCheckbox} />} label="Mit Lohnfortzahlung" />
                    {addingNoInputError && <p className="red">Bitte wählen Sie die Tage der Abwesenheit aus.</p>}
                </div>
            )
        } else if (selectedTab === 1) {
            return (
                <div className="create-absence-modal">
                    <FormControl className="vacation-select-formcontrol">
                        <InputLabel>Art der Abwesenheit</InputLabel>
                        <Select 
                            value={vacationOrIllness}
                            label="Urlaub oder Krankheit"
                            onChange={handleVacationSelect}
                            fullWidth
                        >
                            <MenuItem value={"vacation"}>Urlaub</MenuItem>
                            <MenuItem value={"illness"}>Krankheit</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className="vacation-select-formcontrol">
                        {renderMonthSelection()}
                    </FormControl>
                    <FormControl className="vacation-select-formcontrol">
                        {renderYearSelection()}
                    </FormControl>
                    <div className="set-buttongroup">
                        <InputLabel>Anzahl der Tage</InputLabel>
                        <ButtonGroup>
                            <Button
                                onClick={() => setCountDays((prev) => prev - 1)}
                                disabled={countDays === 0}
                            >
                                <RemoveIcon fontSize="small" />
                            </Button>
                            <TextField size="small" onChange={(handleDayChange)} value={countDays} sx={{width: "60px"}}/>
                            <Button onClick={() => setCountDays((prev) => prev + 1)}>
                                <AddIcon fontSize="small" />
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className="set-hours-minutes">
                        <div className="set-buttongroup">
                            <InputLabel>Stunden pro Tag</InputLabel>
                            <ButtonGroup>
                                {/* <Button
                                    onClick={() => setCountHours((prev) => prev - 1)}
                                    disabled={countHours === 1}
                                >
                                    <RemoveIcon fontSize="small" />
                                </Button> */}
                                <TextField size="small" onChange={handleHourChange} value={countHours} sx={{width: "120px"}}/>
                                {/* <Button onClick={() => setCountHours((prev) => prev + 1)}>
                                    <AddIcon fontSize="small" />
                                </Button> */}
                            </ButtonGroup>
                            <p>Umgerechnet {requestParameterHourMinute.hour}Std {requestParameterHourMinute.minute}min</p>          
                        </div>
                    </div>
                    <FormControlLabel control={<Checkbox checked={paidCheckbox} onChange={handleCheckbox} />} label="Mit Lohnfortzahlung" />
                </div>
            )
        }
    }

    function renderMonthSelection() {
        return (
            <>
                <InputLabel>Monat</InputLabel>
                <Select 
                    value={selectedMonth}
                    label="Monat"
                    onChange={handleMonthSelect}
                    fullWidth
                    
                >
                    <MenuItem value={"01"}>Januar</MenuItem>
                    <MenuItem value={"02"}>Februar</MenuItem>
                    <MenuItem value={"03"}>März</MenuItem>
                    <MenuItem value={"04"}>April</MenuItem>
                    <MenuItem value={"05"}>Mai</MenuItem>
                    <MenuItem value={"06"}>Juni</MenuItem>
                    <MenuItem value={"07"}>Juli</MenuItem>
                    <MenuItem value={"08"}>August</MenuItem>
                    <MenuItem value={"09"}>September</MenuItem>
                    <MenuItem value={"10"}>Oktober</MenuItem>
                    <MenuItem value={"11"}>November</MenuItem>
                    <MenuItem value={"12"}>Dezember</MenuItem>
                </Select>
            </>
        )
    }

    function renderYearSelection () {
        return (
            <>
                <InputLabel>Jahr</InputLabel>
                <Select 
                    value={selectedYear}
                    label="Jahr"
                    onChange={handleYearSelect}
                    fullWidth
                >
                    <MenuItem value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</MenuItem>
                    <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                    <MenuItem value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>
                </Select>
            </>
        )
    } 

    function generateCreationModal() {
        return (
            <Modal
                show={openModal}
                onHide={handleClose}
                keyboard={true}
            >
                    <Modal.Header>
                        <Modal.Title>Abwesenheit eintragen</Modal.Title>
                        <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs centered={true} onChange={changeTab} value={selectedTab} >
                                <Tab label="Terminiert" />
                                <Tab label="Nicht terminiert" />
                        </Tabs>
                        {createModalContent()}
                        {addingAbsenceError && <p className="red">Ein Fehler ist aufgetreten.</p>}                                       
                    </Modal.Body>
                    <Modal.Footer>
                        <CancelButton text="Abbrechen" onClick={handleClose} />
                        <PrimaryButton text="Abwesenheit eintragen" onClick={handleAddVacation} />
                    </Modal.Footer>
                </Modal>
        )
    }

    return (
        <>
            <div className="absence-creation-button-container">
                <PrimaryButton text="Abwesenheit eintragen" onClick={handleAddClick}/>
            </div>
            {generateCreationModal()}
        </>
    )
}

export default EmployeeCreationAbsence;