import "./AllEmployeesExport.css";
import axios from "axios";
import {useState, useEffect} from "react";
import {CSVLink} from "react-csv";
import { Link } from "react-router-dom";
import BackButton from "../../../../sharedComponents/backButton/BackButton";
import PrimaryButton from "../../../../sharedComponents/primaryButton/PrimaryButton";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from "@mui/material";

function AllEmployeesCsv() {
    const [fileValue, setFileValue] = useState("");

    const [csvDurationData, setCsvDurationData] = useState("");
    const [csvDurationHeader, setCsvDurationHeader] = useState({});

    const currentMonth = new Date().toLocaleString('en', { month: 'long' });
    const currentYear = new Date().getFullYear();
    const [exportMonth, setExportMonth] = useState(currentMonth);
    const [exportYear, setExportYear] = useState(currentYear);

    // PDF Options
    const [changeTimeToDecimalFormat, setChangeTimeToDecimalFormat] = useState(false);
    const [includeSignatureLine, setIncludeSignatureLine] = useState(false);
    const [includeWorkingTimeAccount, setIncludeWorkingTimeAccount] = useState(false);

    const [downloadAllShifts, setDownloadAllShifts] = useState(false);

    const [includeSumAndUnpaidAbsences, setIncludeSumAndUnpaidAbsences] = useState(false);

    useEffect(() => {
        getDurationCsvData();
    }, [exportMonth, exportYear, downloadAllShifts])

    const handleChange = (event) => {
        setDownloadAllShifts(false)
        switch (event.target.value) {
            case "PDF":
                setFileValue("PDF");
                break;
            case "CSV":
                setFileValue("CSV");
                break;
            case "CSV als ZIP":
                setFileValue("CSV als ZIP");
                break;
            case "erweiterte PDF":
                setFileValue("erweiterte PDF");
                break;
            default:
                break;
        }
    }

    const csvTimeDataLink = {
        filename: csvDurationHeader.filedescription,
        data: csvDurationData
    }

    function getDurationCsvData() {
        if(downloadAllShifts){
            axios.get('shift/getAll/csv')
            .then(results => {
                setCsvDurationData(results.data);
                setCsvDurationHeader(results.headers);
            })
        } else {
            axios.get('/shift/getAllInDuration/csv?month=' + exportMonth + '&year=' + exportYear)
            .then(results => {
                setCsvDurationData(results.data);
                setCsvDurationHeader(results.headers);
            })
        }
    }

    const handleDownload = () => {   
        axios.get('shift/getAllInDuration/pdf?month='+ exportMonth + '&year='+ exportYear
                + '&decimal='+ changeTimeToDecimalFormat + '&signature='+ includeSignatureLine + '&includeWorkingTimeAccount=' + includeWorkingTimeAccount,
        {
                responseType: 'blob'
        }).then(response => {
            handleZIP(response);
        }).catch(error => {
            console.error('Error downloading file:', error);
        });
    }

    const handleZippedCSV = () => {        
        axios.get('shift/getAllInDuration/zippedCsv?month='+ exportMonth + '&year='+ exportYear + '&includeSumAndUnpaidAbsences=' + includeSumAndUnpaidAbsences, {
            responseType: 'blob'
    })
        .then(response => {
            handleZIP(response);
        }).catch(error => {
            console.error('Error downloading file:', error);
        });
    }

    const requestConfig = {
        url: `shift/getAllInDuration/advancedPdf?month=${exportMonth}&year=${exportYear}`,
        data: {
            bundesland: 'nordrhein_westfalen',
            complex_working_hours_configuration: {
                begin_of_normal_work_hours: "06:00:00",
                begin_of_evening_work_hours: "18:00:00",
                begin_of_night_work_hours: "20:00:00"
            },
            include_paid_absences: true,
            include_unpaid_absences: true
        },
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json'
        }
    };   

    const handleAdvancedDownload = () => {
        axios.post(requestConfig.url, requestConfig.data, {
            responseType: requestConfig.responseType,
            headers: requestConfig.headers
        }).then(response => {
            handleZIP(response);
        }).catch(error => {
            console.error('Error downloading file:', error);
        });        
    }

    function handleZIP(response){
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.download = 'Alle Arbeitszeiten '+exportMonth+' '+ exportYear+ '.zip';
        // Append the link to the body
        document.body.appendChild(link);
        // Trigger the click event to start the download
        link.click();
        // Remove the link element from the body
        document.body.removeChild(link);
    }

    function getAccordingDownloadButton() {
        switch(fileValue){
            case "CSV": 
                return (<CSVLink {...csvTimeDataLink}><PrimaryButton text="Download" /></CSVLink>)
            case "PDF":
                return (<PrimaryButton text="Download" onClick={handleDownload} />)
            case "CSV als ZIP":
                return (<PrimaryButton text="Download" onClick={handleZippedCSV} />)
            case "erweiterte PDF":
                return (<PrimaryButton text="Download" onClick={handleAdvancedDownload} />)
            default:
                break;
        }        
    }

    return (
        <>
            <div className="all-employee-header">
                <Link to={'/home/employees'}>
                    <BackButton text="Zurück" />
                </Link>
                <p>Alle Mitarbeiter</p>
            </div>

            <div className="export-switch-container">
                <FormControl className="export-form-selection" sx={{width: '40em'}}>
                    <InputLabel id="export-select-label">Dateiformat auswählen</InputLabel>
                    <Select
                        labelId="export-select-label"
                        value={fileValue}
                        label="Dateiformat auswählen"
                        onChange={handleChange}
                    >
                        <MenuItem value={"CSV"}>CSV</MenuItem>
                        <MenuItem value={"PDF"}>PDF</MenuItem>
                        <MenuItem value={"CSV als ZIP"}>CSV als ZIP</MenuItem>
                        <MenuItem value={"erweiterte PDF"}>erweiterte PDF</MenuItem>
                    </Select>
                </FormControl>
            </div>

            {fileValue !== "" &&<div className="year-select">
                <FormControl className="export-year-select-formcontrol">
                    <InputLabel>Monat</InputLabel>
                    <Select 
                        value={exportMonth}
                        label="Monat"
                        onChange={(event) => setExportMonth(event.target.value)}
                        fullWidth
                        disabled={downloadAllShifts}
                    >
                        <MenuItem value={"January"}>Januar</MenuItem>
                        <MenuItem value={"February"}>Februar</MenuItem>
                        <MenuItem value={"March"}>März</MenuItem>
                        <MenuItem value={"April"}>April</MenuItem>
                        <MenuItem value={"May"}>Mai</MenuItem>
                        <MenuItem value={"June"}>Juni</MenuItem>
                        <MenuItem value={"July"}>Juli</MenuItem>
                        <MenuItem value={"August"}>August</MenuItem>
                        <MenuItem value={"September"}>September</MenuItem>
                        <MenuItem value={"October"}>Oktober</MenuItem>
                        <MenuItem value={"November"}>November</MenuItem>
                        <MenuItem value={"December"}>Dezember</MenuItem>
                    </Select>
                </FormControl>
                
                <FormControl className="export-year-select-formcontrol">
                    <InputLabel>Jahr</InputLabel>
                    <Select 
                        value={exportYear}
                        label="Jahr"
                        onChange={(event) => setExportYear(event.target.value)}
                        fullWidth
                        disabled={downloadAllShifts}
                    >
                        <MenuItem value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</MenuItem>
                        <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                        <MenuItem value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>
                    </Select>
                </FormControl>
            </div>}
            {fileValue === "PDF" && <div className="all-pdf-options-checkbox-container">
            <FormControlLabel control={<Checkbox onChange={(event) => setIncludeWorkingTimeAccount(event.target.checked)} />} label="Arbeitszeitkonto berücksichtigen"/>
                <FormControlLabel control={<Checkbox onChange={(event) => setChangeTimeToDecimalFormat(event.target.checked)} />} label="Dezimaldarstellung verwenden"/>
                <FormControlLabel control={<Checkbox onChange={(event) => setIncludeSignatureLine(event.target.checked)} />} label="Unterschriftzeile einfügen"/>
            </div>}
            {fileValue === "CSV" && <div className="switch-container">
                <FormControlLabel control={<Switch onChange={() => setDownloadAllShifts(!downloadAllShifts)} />} label="Alle Arbeitszeiten herunterladen"/>                
            </div>}
            {fileValue === "CSV" && <p className="manual-pause-export-warning">Dieser Export beinhaltet keine Abwesenheiten und ist daher nicht zur Verwendung in Lohnabrechnungen empfehlen (hierfür CSV als ZIP)</p>}
            {fileValue === "CSV als ZIP" && <div className="switch-container">
                <div className="csv-checkbox-switch">
                    <FormControlLabel control={<Checkbox onChange={(event) => setIncludeSumAndUnpaidAbsences(event.target.checked)} />} label="Übersicht mit Summe und unbezahlten Abwesenheiten herunterladen"/>
                </div>
            </div>}
            <div className="download-button-container">
                {getAccordingDownloadButton()}
            </div>
        </>
    )

}

export default AllEmployeesCsv;