import "./ShiftIgnoreButton.css";
import { useState } from "react";
import { Button } from "react-bootstrap";
import {Modal} from "react-bootstrap";
import CancelButton from "../../../../../../../sharedComponents/cancelButton/CancelButton";
import { TextField } from "@mui/material";
import axios from "axios";

function ShiftIgnoreButton(props) {
    const [isIgnored] = useState(props.ignored);

    const [show, setShow] = useState(false);
    const handleShow = () => {
        setReason("");
        setReasonError(false);
        setRequestError(false);
        setShow(true);
    }
    const handleClose = () => setShow(false);

    const [reason, setReason] = useState("");
    const [reasonError, setReasonError] = useState(false);

    const [requestError, setRequestError] = useState(false);

    const sendPutToIgnoreShift = () => {
        if (reason === "") {
            setReasonError(true);
        } else {
            axios.put('shift/informationAttachments/isIgnored/update', {
                "shift_begin_activity_id": props.beginId,
                "is_ignored": !isIgnored,
                "reason": reason
            }).then(() => {
                window.location.reload();
            }).catch(() => {
                setRequestError(true);
            })
        }
    }

    function createModal() {
        let modalText = renderButtonText();
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>{modalText}</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {!isIgnored && <p>Damit wird die Arbeitszeit bei allen Exporten <strong>nicht</strong> mehr berücksichtigt.</p>}
                    {isIgnored && <p>Damit wird die Arbeitszeit bei allen Exporten <strong>wieder</strong> berücksichtigt.</p>}
                    <TextField 
                            required 
                            error={reasonError} 
                            label="Grund" 
                            variant="outlined" 
                            helperText={reasonError ? "Feld darf nicht leer sein" : false} 
                            onChange={e => setReason(e.target.value)}
                    />
                    {requestError && <p className="red-color">Ein Fehler ist aufgetreten.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <Button variant="danger" onClick={sendPutToIgnoreShift}>{modalText}</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function renderButtonText() {
        return isIgnored ? "Arbeitszeit nicht mehr ignorieren" : "Arbeitszeit ignorieren";
    }

    return (
        <div className="shift-ignore-button-container">
            <Button onClick={handleShow} variant="danger">{renderButtonText()}</Button>
            {createModal()}
        </div>
    )
}

export default ShiftIgnoreButton;