import { useEffect, useState } from "react";
import "./Dashboard.css";
import Live from "./live/Live";
import WorkTimeAccount from "./workTimeAccount/WorkTimeAccount";
import LoadingSpinner from "../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import axios from "axios";
import Logout from "../../../sharedComponents/Logout";

function Dashboard() {

    const [isLoading, setIsLoading] = useState(true);
    const [workTimeAccountIsActive, setWorkTimeAccountIsAktive] = useState(true);

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const yearmonth = `${currentYear}-${currentMonth}`;
        getExistingWorkingTimeAccounts(yearmonth);
    }, [])

    function getExistingWorkingTimeAccounts(yearmonth) {
        axios.get(`workingTimeAccount/getAllWhichExistInGivenMonth?yearmonth=${yearmonth}`).then(results => {
            setWorkTimeAccountIsAktive(results.data.length > 0);
            setIsLoading(false);
        }).catch(() => { Logout()})
    }

    function generateDashboard() {
        return workTimeAccountIsActive ?
            <>
                <div className="half-width-content-container">
                    <WorkTimeAccount />
                </div>
                <div className="half-width-content-container">
                    <Live />
                </div>
            </> 
            :
            <>
                <div className="full-width-content-container">
                    <Live />
                </div>
            </>
    }

    return isLoading ?
        <LoadingSpinner />
        :
        <div className="dashboard-container">
            {generateDashboard()}
        </div>
}

export default Dashboard;