import { Link } from "react-router-dom";
import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import "./CardAccess.css";
import CardAccessList from "./list/CardAccessList";

function CardAccess() {
    return (
        <>
            <div className="card-access-header">
                <h2>Karten Zugang</h2>
                <Link to="createCardAccess">
                    <PrimaryButton text="Hinzufügen" />
                </Link>
            </div>
            <CardAccessList />
        </>
    )
}

export default CardAccess;