import "./LocationCreation.css";
import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import { Fab, TextField, Button, Snackbar, Alert } from "@mui/material";
import { Add } from "@mui/icons-material";
import CancelButton from "../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../sharedComponents/primaryButton/PrimaryButton";
import Logout from "../../../../sharedComponents/Logout";

function LocationCreation() {

    const [show, setShow] = useState(false);
    const [openError, setOpenError] = useState(false)
    const [openCatch, setOpenCatch] = useState(false)

    const handleShow = () => {
        if(currentLocationNumber === limitLocation){
            setOpenError(true)
        } else {
            setShow(true)
            setBezeichnungError(false)
            setStandortnummerError(false)
        }        
    }
    const handleClose = () => setShow(false);

    const [bezeichnungError, setBezeichnungError] = useState(false);
    const [standortnummerError, setStandortnummerError] = useState(false);

    const [limitLocation, setLimitLocation] = useState(null);
    const [currentLocationNumber, setCurrentLocationNumber] = useState(null)

    useEffect(() => {
        axios.get('location/getActive').then(results => {
            setCurrentLocationNumber(results.data.length - 1);
            axios.get('info').then(results => {
                setLimitLocation(results.data.max_locations);                
            }).catch(() => { Logout()})
        })        
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        setBezeichnungError(document.getElementById("LocationName").value === "")
        setStandortnummerError(document.getElementById("LocationId").value === "")

        if(!bezeichnungError && !standortnummerError) {
            sendPostRequest();
        }
    }

    function createModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Standort anlegen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="create-location-form">
                        <TextField 
                            required    
                            error={bezeichnungError}
                            id="LocationName" 
                            className="create-location-input" 
                            label="Bezeichnung" 
                            helperText={bezeichnungError ? "Feld darf nicht leer sein" : false} 
                            variant="outlined" 
                            onChange={e => setBezeichnungError(e.target.value === "")}
                        />
                        <TextField 
                            required
                            error={standortnummerError}
                            id="LocationId" 
                            className="create-location-input" 
                            label="Standortnummer" 
                            variant="outlined" 
                            helperText={standortnummerError ? "Feld darf nicht leer sein" : false} 
                            onChange={e => setStandortnummerError(e.target.value === "")}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Neuen Standort anlegen" onClick={handleSubmit} />
                </Modal.Footer>
            </Modal>
        )
    }

    function createErrorSnackBar() {
        return (
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Maximalanzahl an Standorten erreicht. Deaktivieren Sie einige, um neue erstellen zu können.
                </Alert>
            </Snackbar>
        )
    }

    function createCatchSnackBar() {
        return (
            <Snackbar open={openCatch} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Die Standortnummer existiert bereits.
                </Alert>
            </Snackbar>
        )
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway')
            return;

        setOpenError(false);
        setOpenCatch(false);
    }

    function sendPostRequest() {
        axios.post('location/new', {
            "id": document.getElementById("LocationId").value,
            "name": document.getElementById("LocationName").value
        }).then(response => {
            handleClose();
            window.location.reload();
        }).catch(() => {
            setOpenCatch(true)
        })
    }

    return (
        <>
            <Fab color="primary" onClick={handleShow} aria-label="add" size="small" className="add-mui-icon location-add-icon" style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}>
                <Add />
            </Fab>
            {createModal()}
            {createErrorSnackBar()}
            {createCatchSnackBar()}
        </>
    )
}

export default LocationCreation;