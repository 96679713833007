import 'rsuite/dist/rsuite-no-reset.min.css';
import AdminPage from "./components/adminpage/AdminPage";
import Employees from "./components/adminpage/content/employees/Employees";

import {
    BrowserRouter,
    Route,
} from 'react-router-dom';
import {Routes} from "react-router";

import Locations from "./components/adminpage/content/locations/Locations";
import EmployeeView from "./components/adminpage/content/employees/employeeView/EmployeeView";
import LocationView from "./components/adminpage/content/locations/locationView/LocationView";
import AdminLogin from "./components/adminpage/login/AdminLogin";
import SelectedShift
    from "./components/adminpage/content/employees/employeeView/shiftsOverview/selectedShift/SelectedShift";
import CreateShift from "./components/adminpage/content/employees/employeeView/shiftsOverview/createShift/CreateShift";
import Impressum from './components/adminpage/impressum/Impressum';
import Licenses from './components/adminpage/licenses/Licenses';
import CardAccessCreation from './components/adminpage/content/employees/employeeView/access/card/creation/CardAccessCreation';
import Errorpage from './components/errorpage/Errorpage';
import Policy from './components/adminpage/policy/Policy';
import AllEmployeesExport from './components/adminpage/content/employees/export/AllEmployeesExport';
import Settings from './components/adminpage/content/settings/Settings';
import CoreTime from './components/adminpage/content/settings/coreTime/CoreTime';
import CoreTimeCreation from './components/adminpage/content/settings/coreTime/creation/CoreTimeCreation';
import CoreTimeDetailView from './components/adminpage/content/settings/coreTime/detailView/CoreTimeDetailView';
import CoreTimeChangeAssignment from './components/adminpage/content/settings/coreTime/detailView/changeAssignment/CoreTimeChangeAssignment';
import AutoPause from './components/adminpage/content/settings/autoPause/AutoPause';
import Dashboard from './components/adminpage/content/dashboard/Dashboard';
import WorkTimeAccountDashboard from './components/adminpage/content/dashboard/workTimeAccount/fullWorktimeDashboard/WorkTimeAccountDashboard';
import MessageToTablet from './components/adminpage/content/settings/messageToTablet/MessageToTablet';
import WorkTimePlanning from './components/adminpage/content/planning/WorkTimePlanning';

function App() {
    return (
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<AdminLogin/>}/>
                        <Route path="home/" element={<AdminPage/>}>
                            <Route path="dashboard">
                                <Route index element={<Dashboard />}/>
                                <Route path="worktimeaccounts" element={<WorkTimeAccountDashboard />}/>
                            </Route>
                            <Route path="planning">
                                <Route index element={<WorkTimePlanning />}/>
                            </Route>
                            <Route path="locations">
                                <Route index element={<Locations/>}/>
                                <Route path="locationView" element={<LocationView/>}/>
                            </Route>   
                            <Route path="employees" >
                                <Route index element={<Employees/>}/>
                                <Route path="employeeView" >
                                    <Route index element={<EmployeeView/>}/>
                                    <Route path="shift" element={<SelectedShift/>}/>
                                    <Route path="createShift" element={<CreateShift/>}/>
                                    <Route path="createCardAccess" element={<CardAccessCreation/>}/>
                                </Route>
                                <Route path="csv" element={<AllEmployeesExport/>}/>
                            </Route>
                            <Route path="settings">
                                <Route index element={<Settings/>}/>
                                <Route path="coretime">
                                    <Route index element={<CoreTime/>}/>
                                    <Route path="creation" element={<CoreTimeCreation />}/>
                                    <Route path="detailView">
                                        <Route index element={<CoreTimeDetailView />}/>
                                        <Route path="assignment" element={<CoreTimeChangeAssignment />}/>
                                    </Route>
                                </Route>
                                <Route path="autoPause">
                                    <Route index element={<AutoPause/>}/>
                                </Route>
                                <Route path='messageToTablet'>
                                    <Route index element={<MessageToTablet/>}/>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="impressum" element={<Impressum />}/>
                        <Route path="lizenzen" element={<Licenses />}/>
                        <Route path="datenschutzerklaerung" element={<Policy />}/>
                    </Route>
                    <Route path='*' element={<Errorpage/>} />
                </Routes>
            </BrowserRouter>
    )
}

export default App;

