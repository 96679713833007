import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import ClearIcon from '@mui/icons-material/Clear';
import './MessageList.css';
import CancelButton from "../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";
import { Modal } from "react-bootstrap";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import HistoryIcon from '@mui/icons-material/History';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


function MessageList(){

    const [isLoading, setIsLoading] = useState(true);
    const [messageList, setMessageList] = useState([]);
    const [openRemovingModal, setOpenRemovingModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [listOpenPast, setListOpenPast] = useState(false);
    const [listOpenFuture, setListOpenFuture] = useState(false);

    useEffect(() => {
        axios.get('employeeGeneralMessage/getAll').then(results => {
            setMessageList(results.data);
            setIsLoading(false);
        })
    }, [])

    function sendDeleteRequest(){
        axios.delete('employeeGeneralMessage/delete?id=' + selectedId, {}).then(() => {
            setOpenRemovingModal(false);
            window.location.reload();
        })
    }

    const handleCloseRemovingModal = () => {
        setOpenRemovingModal(false);
    }

    function removeAbsenceModal() {
        return (
            <Modal
                show={openRemovingModal}
                onHide={handleCloseRemovingModal}
                keyboard={true}
            >
                <Modal.Header>
                        <Modal.Title>Nachricht löschen</Modal.Title>
                        <button type="button" className="close" aria-label="Close" onClick={handleCloseRemovingModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Möchten Sie wirklich diese Nachricht löschen?</p>          
                    </Modal.Body>
                    <Modal.Footer>
                        <CancelButton text="Abbrechen" onClick={handleCloseRemovingModal} />
                        <PrimaryButton text="Nachricht löschen" onClick={() => sendDeleteRequest()} />
                    </Modal.Footer>
            </Modal>
        )
    }

    function createFutureList(){
        let newList = [];
        let listItems = [];

        messageList.sort((a, b) => new Date(a.start_timestamp) - new Date(b.start_timestamp));
        messageList.forEach((item, index) => {
            let currenttime = new Date();
            let starttime = new Date(item.start_timestamp);
            let endtime = new Date(item.end_timestamp);                
            if(starttime >= currenttime){
                listItems.push(
                    <ListItem key={index}
                        secondaryAction={
                            <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(item.id)}}>
                                <ClearIcon color="error" />
                            </IconButton>
                        }
                    >
                        <ListItemButton key={index}>
                            <ListItemText primary={"Nachricht: " + item.message} secondary={starttime.toLocaleDateString("de-DE") + " - " + endtime.toLocaleDateString("de-DE")} />
                        </ListItemButton>
                    </ListItem>                    
                )
            }                
        })

        if(listItems.length === 0){
            listItems.push(
                <ListItem key={"noFuture"}>
                    <ListItemText primary="Keine zukünftigen Nachrichten"/>
                </ListItem>
            )
        }

        newList.push(
            <Fragment key={"top"}>
                <ListItemButton onClick={() => setListOpenFuture(!listOpenFuture)}>
                    <ListItemIcon>
                        <AccessTimeIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Zukünftige Nachrichten" primaryTypographyProps={{style:{fontWeight: 'bold'}}} />
                    {listOpenFuture ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={listOpenFuture} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {listItems}
                    </List>
                </Collapse>
            </Fragment>
        )

        return newList;
    }

    function createPastList(){
        let newList = [];
        let listItems = [];

        messageList.sort((a, b) => new Date(a.start_timestamp) - new Date(b.start_timestamp));
        messageList.forEach((item, index) => {
            let currenttime = new Date();
            let starttime = new Date(item.start_timestamp);
            let endtime = new Date(item.end_timestamp);
            if(endtime < currenttime){
                listItems.push(
                    <ListItem key={index}
                        secondaryAction={
                            <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(item.id)}}>
                                <ClearIcon color="error" />
                            </IconButton>
                        }
                    >
                        <ListItemButton key={index}>
                            <ListItemText primary={"Nachricht: " + item.message} secondary={starttime.toLocaleDateString("de-DE") + " - " + endtime.toLocaleDateString("de-DE")} />
                        </ListItemButton>
                    </ListItem>                    
                )
            }                
        })

        if(listItems.length === 0){
            listItems.push(
                <ListItem key={"noPast"}>
                    <ListItemText primary="Keine vergangene Nachrichten"/>
                </ListItem>
            )
        }

        newList.push(
            <Fragment key={"top2"}>
                <ListItemButton onClick={() => setListOpenPast(!listOpenPast)}>
                    <ListItemIcon>
                        <HistoryIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Vergangene Nachrichten" primaryTypographyProps={{style:{fontWeight: 'bold'}}} />
                    {listOpenPast ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={listOpenPast} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {listItems}
                    </List>
                </Collapse>
            </Fragment>
        )

        return newList;
    }

    function createActiveList(){
        let newList = [];

        messageList.sort((a, b) => new Date(a.start_timestamp) - new Date(b.start_timestamp));
        messageList.forEach((item, index) => {
            let currenttime = new Date();
            let starttime = new Date(item.start_timestamp);
            let endtime = new Date(item.end_timestamp);                
            if(endtime >= currenttime && starttime <= currenttime){
                newList.push(
                    <ListItem key={index}
                        secondaryAction={
                            <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(item.id)}}>
                                <ClearIcon color="error" />
                            </IconButton>
                        }
                    >
                        <ListItemButton key={index}>
                            <ListItemText primary={"Aktuelle Nachricht: " + item.message} secondary={starttime.toLocaleDateString("de-DE") + " - " + endtime.toLocaleDateString("de-DE")} primaryTypographyProps={{style:{fontWeight: 'bold'}}} />
                        </ListItemButton>
                    </ListItem>                    
                )
            }
        })

        if(newList.length === 0){
            newList.push(
                <ListItem key={"noActive"}>
                    <ListItemText primary="Keine aktuelle Nachricht"/>
                </ListItem>
            );
        }

        return newList;
    }

    return isLoading ?
    <LoadingSpinner />
    :
    <List>
        <div className="active-message-list">{createActiveList()}</div>
        <div className="past-future-list">
            <div className="list-items">{createFutureList()}</div>
            <div className="list-items">{createPastList()}</div>
        </div>
        
        {removeAbsenceModal()}
    </List>
}

export default MessageList;