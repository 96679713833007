import { Avatar, List, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import "./CoreList.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import axios from "axios";

function CoreList() {

    const [isLoading, setIsLoading] = useState(true);
    const [policies, setPolicies] = useState(null);

    useEffect(() => {
        getPolicies();
    }, [])

    function getPolicies() {
        axios.get('coreWorkingHoursPolicy/getAll').then(results => {
            setPolicies(results.data);
            setIsLoading(false);
        })
    }

    function createPolicyItems() {
        let policyItems = [];

        if (policies.length === 0) {
            policyItems.push(
                <p>Keine Regelungen.</p>
            )
        } else {
            policies.map((item, index) => {
                policyItems.push(
                    <Link to={'detailView?id=' + item.id} key={"CoreTimePolicy " + index}>
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar>
                                    <PendingActionsOutlinedIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </Link>
                )
            })
        }

        return policyItems;
    }

    return isLoading ?
        <LoadingSpinner />
        :
        <List>
            {createPolicyItems()}
        </List>
}

export default CoreList;