import { Box, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./AppAccessItem.css";
import axios from "axios";
import { Button } from "react-bootstrap";
import CancelButton from "../../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../../sharedComponents/primaryButton/PrimaryButton";

function AppAccessItem(props) {

    const removeModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // Modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [removingAppAccessError, setRemovingAppAccessError] = useState(false);

    const [connection] = useState(props.connection);
    const [color, setColor] = useState(null);

    useEffect(() => {
        switch (connection.status) {
            case 'pending': 
                setColor('pink-color');
                break;
            case 'active':  
                setColor('green-color');
                break;
            case 'deleted': 
                setColor('gray-color');
                break;
            default: break;
        }
    }, [])

    function renderStatusConnection() {

        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };

        const createdDate = new Date(connection.createdAt).toLocaleString('de-DE', options);
        
        let activatedDate = null;
        if (connection.activatedAt !== null)
            activatedDate = new Date(connection.activatedAt).toLocaleString('de-DE', options);

        switch (connection.status) {
            case 'pending': return createNotActiveConnection(createdDate);
            case 'active':  return createActiveConnection(createdDate, activatedDate);
            case 'deleted': return createDisabledConnection(createdDate, activatedDate);
            default: break;
        }
    }

    function createNotActiveConnection(createdDate) {
        return (
            <>
                <h5>Nicht aktiver Zugang</h5>
                <h6>Erstellt: {createdDate}</h6>
                <h6>Aktiviert: --</h6>
            </>
        )
    }

    function createActiveConnection(createdDate, activatedDate) {
        return (
            <>
                <h5>Zugang auf {connection.deviceInformation}</h5>
                <h6>Erstellt: {createdDate}</h6>
                <h6>Aktiviert: {activatedDate}</h6>
            </>
        )
    }

    function createDisabledConnection(createdDate, activatedDate) {
        return (
            <>
                <h5>Deaktivierter Zugang</h5>
                <h6>Erstellt: {createdDate}</h6>
                <h6>Aktiviert: {activatedDate}</h6>
            </>
        )
    }

    const handlePrint = () => {
        axios.post('employee/app/connections/createActivationPdfForId?id='+ connection.id,
            {},
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ).then((results) =>{
            const data = results.data
            const blob = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        }).catch((error) => {
            
        })
    }

    const handleDownload = () => {
        axios.post('employee/app/connections/createActivationPdfForId?id='+ connection.id,
        {},
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        ).then((results) => {
            const data = results.data
            const blob = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', 'AppAktivierung.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
    }

    function createConnectionButtons() {
        switch (connection.status) {
            case 'pending': 
                return (
                    <>
                        <Button variant="info" className="pink-button-color" onClick={handlePrint}>Drucken</Button>
                        <Button variant="info" className="pink-button-color" onClick={handleDownload}>Download</Button>
                        {/* <Button variant="contained" className="pink-button-color">Link verschicken</Button> */}
                    </>
                )
            case 'active':  
                return (
                    <>
                        <Button variant="danger" className="red-button-color" onClick={handleOpen}>Deaktivieren</Button>
                    </>
                )
            case 'deleted': 
                return (
                    <>
                        
                    </>
                )
            default: break;
        }
    }

    const removeAppAccess = () => {
        axios.put('employee/app/connections/deactivate?id=' + connection.id, {
        }).then(() => {
            sessionStorage.setItem("emplyoeeViewIndex", 5)
            sessionStorage.setItem("emplyoeeAccessOption", "App Zugang")
            window.location.reload();
            handleClose();
        }).catch(() => {
            setRemovingAppAccessError(true);
        })
    }

    function removeAppAccessModal() {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={removeModalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        App Zugang deaktivieren
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Möchten Sie diesen App Zugang wirklich deaktivieren?
                    </Typography>
                    {removingAppAccessError &&
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} color="red">
                        Ein Fehler beim Löschen ist aufgetreten. 
                    </Typography>}
                    <div className="app-adding-modal-buttons">
                        <CancelButton text="Abbrechen" onClick={handleClose} />
                        <PrimaryButton text="Zugang deaktivieren" onClick={removeAppAccess} />
                    </div>
                </Box>
            </Modal>
        )
    }

    return (
        <>
            <div className={"app-connection-container " + color}>
                <div className="app-connection-information">
                    {renderStatusConnection()}
                </div>
                <div className="app-connection-button-container">
                    {createConnectionButtons()}
                </div>
            </div>
            {removeAppAccessModal()}
        </>
    )
}

export default AppAccessItem;