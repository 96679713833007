import "./EmployeeCreation.css";
import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import axios from "axios";
import { Fab, TextField, FormControlLabel, Checkbox, FormGroup, Snackbar, Alert } from "@mui/material";
import { Add } from "@mui/icons-material";
import CancelButton from "../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../sharedComponents/primaryButton/PrimaryButton";
import Logout from "../../../../sharedComponents/Logout";

function EmployeeCreation() {

    const [isMobileTrackingEnabled, setIsMobileTrackingEnabled] = useState(false);

    const [show, setShow] = useState(false);
    const [openError, setOpenError] = useState(false)
    const [openCatch, setOpenCatch] = useState(false)

    const handleShow = () => {
        if(currentEmployee === limitEmployee){
            setOpenError(true)
        } else {
            setShow(true);
            setNameError(false);
            setFirstNameError(false);
            setIdError(false);
        }
    }
    const handleClose = () => setShow(false);

    const [limitEmployee, setLimitEmployee] = useState(null);
    const [currentEmployee, setCurrentEmployee] = useState(null);

    const [nameError, setNameError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [idError, setIdError] = useState(false);
    const [idErrorText, setIdErrorText] = useState("Feld darf nicht leer sein")

    const [employeeRelationship, setEmployeeRelationship] = useState("---");

    useEffect(() => {
        axios.get('employee/getActive').then(results => {
            setCurrentEmployee(results.data.length);
            axios.get('info').then(results => {
                setLimitEmployee(results.data.max_employees);                
            }).catch(() => { Logout()})
        })        
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        setNameError(document.getElementById("EmployeeName").value === "")
        setFirstNameError(document.getElementById("EmployeeFirstName").value === "")
        setIdError(document.getElementById("EmployeePersonalNumber").value === "")
        setIdErrorText("Feld darf nicht leer sein")

        if(!nameError && !firstNameError && !idError) {
            sendPostRequest();
        }
    }

    const changeEnableMobileTracking = (event) => {
        setIsMobileTrackingEnabled(event.target.checked);
    }

    function createModal() {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Mitarbeiter anlegen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="create-employee-form">
                        <TextField 
                            required 
                            error={firstNameError} 
                            id="EmployeeFirstName" 
                            className="create-employee-input" 
                            label="Vorname" 
                            variant="outlined" 
                            helperText={firstNameError ? "Feld darf nicht leer sein" : false} 
                            onChange={e => setFirstNameError(e.target.value === "")}
                        />
                        <TextField 
                            required 
                            error={nameError} 
                            id="EmployeeName" 
                            className="create-employee-input" 
                            label="Nachname" 
                            variant="outlined" 
                            helperText={nameError ? "Feld darf nicht leer sein" : false} 
                            onChange={e => setNameError(e.target.value === "")}
                        />
                        <TextField 
                            required 
                            error={idError} 
                            id="EmployeePersonalNumber" 
                            className="create-employee-input" 
                            label="Personalnummer" 
                            variant="outlined" 
                            helperText={idError ? idErrorText : false} 
                            onChange={e => setIdError(e.target.value === "")}
                        />
                        <TextField 
                            id="EmployeeRelationship" 
                            className="create-employee-input" 
                            label="Anstellungsverhältnis" 
                            variant="outlined" 
                            onChange={e => setEmployeeRelationship(e.target.value)}
                        />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={changeEnableMobileTracking} />} label="Mobile Zeiterfassung erlauben (Notwendig für Homeoffice / Außendienst etc.)" title="Dies ist nötig, wenn der Mitarbeiter die Erlaubnis haben sollte im HomeOffice, im Außendienst oder ähnlichem die Arbeitszeit zu erfassen." />
                        </FormGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Neuen Mitarbeiter anlegen" onClick={handleSubmit} />
                </Modal.Footer>
            </Modal>
        )
    }

    function createErrorSnackBar() {
        return (
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Maximalanzahl an Administratoren erreicht. Deaktivieren Sie einige, um neue erstellen zu können.
                </Alert>
            </Snackbar>
        )
    }

    function createCatchSnackBar() {
        return (
            <Snackbar open={openCatch} autoHideDuration={6000} onClose={handleCloseError} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Die Personalnummer existiert bereits. Wählen Sie eine andere Personalnummer.
                </Alert>
            </Snackbar>
        )
    }


    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway')
            return;

        setOpenCatch(false);
        setOpenError(false);
    }

    function sendPostRequest() {
        axios.post('employee/new', {
            "last_name": document.getElementById("EmployeeName").value,
            "first_name": document.getElementById("EmployeeFirstName").value,
            "id": document.getElementById("EmployeePersonalNumber").value,
            "employment_type": employeeRelationship,
            "city": "---",
            "plz": "---",
            "street_address": "---",
            "mobile_login_enabled": isMobileTrackingEnabled
            /*
                "city": document.getElementById("EmployeeCity").value,
                "plz": document.getElementById("EmployeePLZ").value,
                "street_address": document.getElementById("EmployeeStreetNumber").value,
            */
        }).then(() => {
            handleClose();
            window.location.reload();
        }).catch(() => {    //nicht ganz korrekt. Wenn id schon existiert, und man wieder abschickt, wird der Text entfernt
            setIdError(true);
            setIdErrorText("ID existiert bereits.")
            setOpenCatch(true)
        })
    }

    return (
        <>
            <Fab color="primary" onClick={handleShow} className="add-mui-icon" aria-label="add" size="small" style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}>
                <Add />
            </Fab>
            {createModal()}
            {createErrorSnackBar()}
            {createCatchSnackBar()}
        </>
    )
}

export default EmployeeCreation;