export default function parseDuration(durationStr) {
    if(durationStr === null) return { hours: 0, minutes: 0};
    const match = durationStr.match(/PT(-?(\d+)H)?(-?(\d+)M)?/);
    
    if (match) {
        const hoursSign = match[1] && match[1].startsWith('-') ? -1 : 1;
        const minutesSign = match[3] && match[3].startsWith('-') ? -1 : 1;
        const hours = parseInt(match[2]) || 0;
        const minutes = parseInt(match[4]) || 0;
        return { hours: hoursSign * hours, minutes: minutesSign * minutes };
    } else {
        return null;
    }
}