import "./CreateShiftHeader.css";
import { Link } from "react-router-dom";
import BackButton from "../../../../../../../sharedComponents/backButton/BackButton";

function CreateShiftHeader(props) {

    return (
        <>
            <Link to={"/home/employees/employeeView?employeeId=" + sessionStorage.getItem("employeeId")}>
                <BackButton text="Zurück" />
            </Link>
            <h3>Neue Arbeitszeit für {props.firstName + " " + props.lastName} erstellen</h3>
        </>
    )
}

export default CreateShiftHeader;