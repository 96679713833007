import "./EmployeeView.css";
import EmployeeInformation from "./information/EmployeeInformation";
import EmployeeExport from "./export/EmployeeExport";
import WorktimeAccount from "./worktimeAccount/WorktimeAccount";
import axios from "axios";
import {useEffect, useState} from "react";
import LoadingSpinner from "../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import ShiftsOverview from "./shiftsOverview/ShiftsOverview";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from "react-router-dom";
import Access from "./access/Access";
import Logout from "../../../../sharedComponents/Logout";
import BackButton from "../../../../sharedComponents/backButton/BackButton";
import EmployeeAbsence from "./absence/EmployeeAbsence";
import EmployeeHoliday from "./holiday/EmployeeHoliday";

function EmployeeView() {

    const [isLoading, setIsLoading] = useState(true);

    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [, setIsActive] = useState(true);

    const [selectedTab, setSelectedTab] = useState(sessionStorage.getItem("emplyoeeViewIndex")|0);

    const handleChange = (event, value) => {
        setSelectedTab(value);
        sessionStorage.setItem("emplyoeeViewIndex", value);
        sessionStorage.setItem("emplyoeeAccessOption", "App Zugang");
    };

    useEffect(() => {
        const token = localStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken");
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        getEmployeeData();
    })

    function getEmployeeData() {
        axios.get('employee/getById?id=' + new URLSearchParams(window.location.search).get("employeeId")).then(results => {
            setLastName(results.data.last_name);
            setFirstName(results.data.first_name);
            setIsActive(results.data.is_active);
            sessionStorage.setItem("employeeId", new URLSearchParams(window.location.search).get("employeeId"));
            sessionStorage.setItem("employeeFirstName", results.data.first_name);
            setIsLoading(false);
        }).catch(() => { Logout()})
    }

    function getCurrentTabContainer() {
        switch (selectedTab) {
            case 0:
                return (
                    <div className="shifts-overview-container">
                        <ShiftsOverview/>
                    </div>
                )
            case 1:
                return (
                    <div className="a">
                        <EmployeeAbsence />
                    </div>
                )
            case 2:
                return (
                    <div>
                        <WorktimeAccount/>
                    </div>                    
                )
            case 3:
                return (
                    <div className="employee-information-container">
                        <EmployeeInformation/>
                    </div>                    
                )
            case 4:
                return (                    
                    <div>
                        <EmployeeHoliday />
                    </div>
                )
            case 5:
                return (
                    <div className="employee-app-container">
                        <Access />
                    </div> 
                )
            case 6:
                return (
                    <div className="employee-csv-container">
                        <EmployeeExport/>
                    </div>
                )
            default:
                return <p className="red">Ein Fehler ist aufgetreten.</p>
        }
    }

    return isLoading ?

        (<LoadingSpinner/>)
        :
        (
            <>
                <Link to={'/home/employees'}>
                    <div className="employee-information-header-container">
                        <BackButton text="Zurück" />
                    </div>
                </Link>
                
                <h1 className="employee-detail-headline">{firstName} {lastName}</h1>

                {/* Ändern das eigene Box gibt */}
                <Box sx={{ width: '100%', marginTop: '4em' }}>
                    {/* For Desktop */}
                    <Tabs className="tab-group-container desktop" value={selectedTab} onChange={handleChange} centered >
                        <Tab label="Arbeitszeiten" />
                        <Tab label="Abwesenheiten" />
                        <Tab label="Arbeitszeitkonto" />
                        <Tab label="Informationen" />
                        <Tab label="Feiertage" /> 
                        <Tab label="Zugang" />
                        <Tab label="Export" />
                    </Tabs>
                    {/* For Mobile */}
                    <Tabs className="tab-group-container mobile" value={selectedTab} onChange={handleChange} orientation="vertical">
                        <Tab label="Arbeitszeiten" />
                        <Tab label="Abwesenheiten" />
                        <Tab label="Arbeitszeitkonto" />
                        <Tab label="Informationen" />
                        <Tab label="Feiertage" />   
                        <Tab label="Zugang" />
                        <Tab label="Export" />  
                    </Tabs>
                </Box>
                {getCurrentTabContainer()}
            </>
        )
}

export default EmployeeView;