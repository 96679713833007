import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { CloseButton } from 'react-bootstrap';
import axios from 'axios';
import { useState } from 'react';
import "./CreateShiftSummary.css";
import { useNavigate } from 'react-router';
import PrimaryButton from '../../../../../../../../sharedComponents/primaryButton/PrimaryButton';

function CreateShiftSummary(props) {

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);

    const navigate = useNavigate();

    const handleClick = () => setOpen(true);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway')
            return;

        setOpen(false);
    }

    const handleClickError = () => setOpenError(true);
    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway')
            return;

        setOpenError(false);
    }
    
    function sumWorkTime() {
        let currentActivities = JSON.parse(sessionStorage.getItem("new-shift-current-activities"));
        let times = [];

        for (let activity of currentActivities) {
            if (activity.type !== "pseudo")
                times.push(activity.time);
        }

        return calculateWork(times);
    }

    function calculateWork(times) {
        let index = 0;
        let durationInMilliseconds = 0;

        while(index < times.length) {
            durationInMilliseconds += calculateDurationInMilliseconds(times[index], times[index + 1]);
            index += 2;
        }

        return calculateDurationInHours(durationInMilliseconds) + " Stunden und " + calculateDurationInMinutes(durationInMilliseconds) + " Minuten";
    }

    function calculateDurationInHours(milliseconds) {
        let durationInHours = Math.floor(milliseconds / (1000 * 60 * 60));

        if (durationInHours < 0)
            durationInHours = 24 + durationInHours;

        return durationInHours;
    }

    function calculateDurationInMinutes(milliseconds) {
        let durationInMinutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));

        if (durationInMinutes < 0)
            durationInMinutes = 60 + durationInMinutes;

        return durationInMinutes;
    }

    function calculateDurationInMilliseconds(time1, time2) {
        let [hour1, minute1] = time1.split(':');
        let [hour2, minute2] = time2.split(':');

        let date1 = new Date();
        date1.setHours(hour1, minute1, 0);

        let date2 = new Date();
        date2.setHours(hour2, minute2, 0);

        return date2.getTime() - date1.getTime();
    }

    function sumPauseTime() {
        let currentActivities = JSON.parse(sessionStorage.getItem("new-shift-current-activities"));
        let times = [];

        for (let activity of currentActivities) {
            if (activity.type !== "pseudo")
                times.push(activity.time);
        }

        return calculatePause(times);
    }

    function calculatePause(times) {
        let index;

        if (times.length <= 2) 
            return "0 Stunden und 0 Minuten";
        else 
            index = 1;

        let durationInMilliseconds = 0;

        while(index < times.length - 1) {
            durationInMilliseconds += calculateDurationInMilliseconds(times[index], times[index + 1]);
            index += 2;
        }

        return calculateDurationInHours(durationInMilliseconds) + " Stunden und " + calculateDurationInMinutes(durationInMilliseconds) + " Minuten";
    }

    function createBeginAndEndTime() {
        let activities = JSON.parse(sessionStorage.getItem("new-shift-current-activities"));

        return (
            <>
                <h5>Start: <span className="green">{activities[0].date + " - " + activities[0].time + " Uhr"}</span></h5>
                <h5>Ende: <span className="green">{activities[activities.length - 1].date + " - " + activities[activities.length - 1].time + " Uhr"}</span></h5>
            </>
        )
    }

    function sendCreateShiftRequest() {
        if (sessionStorage.getItem("full-completed-new-shift") !== null) {
            axios.post('shift/asynchronousCreateFromActivities', {
                "employee_id": sessionStorage.getItem("employeeId"),
                "location_id": sessionStorage.getItem("new-shift-location-id"),
                "activities": getActivitiesForPostRequest(),
                "reason": "Nachgetragen"
            }).then(response => {
                handleClick();
                navigate('/home/employees/employeeView?employeeId=' + sessionStorage.getItem("employeeId"));
            }).catch(error => {
                handleClickError();
            })
        }
    }

    function getActivitiesForPostRequest() {
        let preparedActivitiesToSend = [];
        let currentActivities = JSON.parse(sessionStorage.getItem("new-shift-current-activities"));

        for (let activity of currentActivities) {
            if (activity.type !== "pseudo") {
                let [day, month, year] = activity.date.split(".");
                let [hours, minutes] = activity.time.split(":");
                let date = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);

                let formattedTimestamp = date.toISOString();

                preparedActivitiesToSend.push({
                    "activity_type": activity.type,
                    "timestamp": formattedTimestamp
                })
            }
        }

        return preparedActivitiesToSend;
    }

    const action = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleClose}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseButton fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

      const actionError = (
        <React.Fragment>
          <Button color="secondary" size="small" onClick={handleCloseError}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseError}
          >
            <CloseButton fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    function createSuccessfulSnackBar() {
        return (
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} action={action}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Arbeitszeit wurde erfolgreich erstellt!
                </Alert>
            </Snackbar>
        )
    }

    function createErrorSnackBar() {
        return (
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError} action={actionError}>
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    Arbeitszeit wurde nicht erstellt!
                </Alert>
            </Snackbar>
        )
    }
    
    return (
        <>
            <div className="new-shift-summary-container">
                <h5>Mitarbeiter: <span className="green">{props.firstName + " " + props.lastName}</span></h5>
                <h5>Ort: <span className="green">{sessionStorage.getItem("new-shift-location")}</span></h5>
                {createBeginAndEndTime()}
                <h5>Arbeitszeit: <span className="green">{sumWorkTime()}</span></h5>
                <h5>Pausenzeit: <span className="green">{sumPauseTime()}</span></h5>
            </div>
            <PrimaryButton text="Neue Arbeitszeit erstellen!" onClick={() => sendCreateShiftRequest()}/>
            {createSuccessfulSnackBar()}
            {createErrorSnackBar()}
        </>
    )
}

export default CreateShiftSummary;