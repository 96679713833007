import "./EmployeeAbsence.css";
import { Fragment, useEffect, useState } from "react";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select } from "@mui/material";
import CancelButton from "../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";
import { Modal } from "react-bootstrap";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import axios from "axios";
import Logout from "../../../../../sharedComponents/Logout";
import { Avatar } from "rsuite";
import EmployeeCreationAbsence from "./creation/EmployeeCreationAbsence";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import ClearIcon from '@mui/icons-material/Clear';

const weekDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]

function EmployeeAbsence(){
    /** 
     * when creating vacation always on the first of month;
     * check in what month the absence-period is 
     * hours in one absence-period is same 
     * ignore specific day = true
     * 
     * Darstellung: in Tabelle mit vier Listen (ähnlich wie Footer) um bezahlten/ unbezahlten Urlaub/Krankheit
    */

    const [refresh, setRefresh] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [year, setYear] = useState(new Date().getFullYear());
    const [monthList] = useState(['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']);
    const [absences, setAbsences] = useState(null);

    const handleYearChange = (event) => {
        setYear(event.target.value);
    }

    useEffect(() => {
        getEmployeeData();
    }, [])

    useEffect(() => {
        setIsLoading(true);
        getEmployeeData();
    }, [refresh])
    
    // Removing Modal
    const [openRemovingModal, setOpenRemovingModal] = useState(false);
    const handleCloseRemovingModal = () => {
        setRemovingAbsenceError(false);
        setOpenRemovingModal(false);
    }

    const [removingAbsenceError, setRemovingAbsenceError] = useState(false);

    const [selectedDateArray, setSelectedDateArray] = useState([]);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const handleCloseDetailModal = () => {
        setOpenDetailModal(false);
    }

    const [selectedId, setSelectedId] = useState("");

    function getEmployeeData(){
        axios.get('absence/getAllAbsencePeriodsForEmployeeWithId?id=' + new URLSearchParams(window.location.search).get("employeeId")).then(results => {
            setAbsences(results.data);
            setIsLoading(false)
        }).catch(() => { Logout()});
    }

    function sendDeleteRequest() {
        axios.delete('absence/deleteAbsencePeriodWithId?id=' + selectedId, {
        }).then(() => {
            handleCloseRemovingModal();
            setRemovingAbsenceError(false);
            setRefresh(!refresh);
        }).catch(() => setRemovingAbsenceError(true)); 
    }

    function monthListItems(){
        let months = [];
        monthList.forEach((monthList, index) => {
            months.push(
                <Fragment key={index}>
                    <ListItemText primary={monthList} primaryTypographyProps={{fontSize: '24px'}}/>
                    <Divider/>
                    <div className="month-details">
                        <List>
                            {createAbsenceItems(index)}
                        </List>
                    </div>
                </Fragment>
            )
        })
        return months;
    } 

    function createAbsenceItems(index) {
        let absenceshtml = [];

        absences.forEach((element) => {
            element.absence_days.sort((a, b) => a.date.localeCompare(b.date));
            let dateObject = new Date(element.absence_days[0].date);

            let hourObject;
            let minuteObject;
            if(element.absence_days[0].duration.split('H')[1] === undefined){
                hourObject = 0;
                minuteObject = element.absence_days[0].duration.match(/\d+/);
            } else {
                hourObject = element.absence_days[0].duration.match(/\d+/);
                minuteObject = element.absence_days[0].duration.split('H')[1].match(/\d+/)
            }            
            let length = element.absence_days.length;
            let isTerminatedTime = !element.absence_days[0].ignore_specific_date;

            if(dateObject.getFullYear() === year && dateObject.getMonth() === index) {
                if (element.paid && element.absence_type === "vacation"){
                    if (isTerminatedTime) {
                        absenceshtml.push(
                            <ListItem key={element.id}
                                secondaryAction={
                                <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(element.id)}}>
                                    <ClearIcon color="error" />
                                </IconButton>
                            }
                            disablePadding
                            >
                                <ListItemButton key={element.id + length + year} onClick={() => handleAbsenceClick(element.absence_days)}>
                                        <ListItemAvatar>
                                            <Avatar>
                                            <BeachAccessIcon fontSize="small" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            {createAbsenceInformation("Bezahlter Urlaub: ", length, hourObject, minuteObject, isTerminatedTime)}
                                        </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                    } else {
                        absenceshtml.push(
                            <ListItem key={element.id + length + year}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(element.id)}}>
                                        <ClearIcon color="error" />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                    <BeachAccessIcon fontSize="small" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    {createAbsenceInformation("Bezahlter Urlaub: ", length, hourObject, minuteObject, isTerminatedTime)}
                                </ListItemText>
                            </ListItem>
                        )
                    }
                } else if (element.paid && element.absence_type === "illness"){
                    if (isTerminatedTime) {
                        absenceshtml.push(
                            <ListItem key={element.id}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(element.id)}}>
                                        <ClearIcon color="error" />
                                    </IconButton>
                                }
                                disablePadding
                            >
                                <ListItemButton key={element.id + length + year} onClick={() => handleAbsenceClick(element.absence_days)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                        <HealthAndSafetyIcon className="healthIcon" fontSize="small"/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        {createAbsenceInformation("Bezahlte Krankheitstage: ", length, hourObject, minuteObject, isTerminatedTime)}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                    } else {
                        absenceshtml.push(
                            <ListItem key={element.id + length + year}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(element.id)}}>
                                        <ClearIcon color="error" />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                    <HealthAndSafetyIcon className="healthIcon" fontSize="small"/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    {createAbsenceInformation("Bezahlte Krankheitstage: ", length, hourObject, minuteObject, isTerminatedTime)}
                                </ListItemText>
                            </ListItem>
                        )
                    }
                } else if (!element.paid && element.absence_type === "vacation"){
                    if (isTerminatedTime) {
                        absenceshtml.push(
                            <ListItem key={element.id}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(element.id)}}>
                                        <ClearIcon color="error" />
                                    </IconButton>
                                }
                                disablePadding
                            >
                                <ListItemButton key={element.id + length + year} onClick={() => handleAbsenceClick(element.absence_days)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                        <HealthAndSafetyIcon className="healthIcon" fontSize="small"/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        {createAbsenceInformation("Unbezahlter Urlaub: ", length, hourObject, minuteObject, isTerminatedTime)}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                    } else {
                        absenceshtml.push(
                            <ListItem key={element.id + length + year}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(element.id)}}>
                                        <ClearIcon color="error" />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                    <BeachAccessIcon fontSize="small" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    {createAbsenceInformation("Unbezahlter Urlaub: ", length, hourObject, minuteObject, isTerminatedTime)}
                                </ListItemText>
                            </ListItem>
                        )
                    }
                } else if (!element.paid && element.absence_type === "illness"){
                    if (isTerminatedTime) {
                        absenceshtml.push(
                            <ListItem key={element.id}
                                secondaryAction={
                                <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(element.id)}}>
                                    <ClearIcon color="error" />
                                </IconButton>
                            }
                            disablePadding
                            >
                                <ListItemButton key={element.id + length + year} onClick={() => handleAbsenceClick(element.absence_days)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                        <HealthAndSafetyIcon fontSize="small" color="action"/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        {createAbsenceInformation("Unbezahlte Krankheitstage: ", length, hourObject, minuteObject, isTerminatedTime)}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                    } else {
                        absenceshtml.push(
                            <ListItem key={element.id + length + year}
                                secondaryAction={
                                    <IconButton edge="end" onClick={() => {setOpenRemovingModal(true); setSelectedId(element.id)}}>
                                        <ClearIcon color="error" />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                    <HealthAndSafetyIcon fontSize="small" color="action"/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    {createAbsenceInformation("Unbezahlte Krankheitstage: ", length, hourObject, minuteObject, isTerminatedTime)}
                                </ListItemText>
                            </ListItem>
                        )
                    }
                }
            }
        })

        return absenceshtml;
    }

    function createAbsenceInformation(typ, length, hourObject, minuteObject, isTerminatedTime) {
        let minuteText;
        if(minuteObject === null){
            minuteText = " ";
        } else minuteText = minuteObject + "min ";

        let totalTimeText;
        if(minuteObject === null){
            totalTimeText = length * hourObject + "h"
        } else {
            let totalInMinutes = (hourObject * 60 + parseInt(minuteObject)) * length;
            let newTotalHours = Math.floor(totalInMinutes / 60);
            let newTotalMinutes = totalInMinutes % 60;

            totalTimeText = newTotalHours + "h" + newTotalMinutes + "min";
        }

        if (isTerminatedTime)
            return "Terminiert: " + typ + length + " Tag(e) à " + hourObject + "h" + minuteText + "(" + totalTimeText + ")";
        else
            return "Nicht terminiert: " + typ + length + " Tag(e) à " + hourObject + "h" + minuteText + "(" + totalTimeText + ")";
    }

    const handleAbsenceClick = (dateArray) => {
        const dates = dateArray.map(item => {
            const dateObj = new Date(item.date);
            const day = dateObj.getDate().toString().padStart(2, '0');
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const year = dateObj.getFullYear();
            return `${day}.${month}.${year}`;
        })

        setSelectedDateArray(dates);
        setOpenDetailModal(true);
    }

    function terminatedAbsenceModal() {
        return (
            <Modal
                show={openDetailModal}
                onHide={handleCloseDetailModal}
                keyboard={true}
            >
                <Modal.Header>
                        <Modal.Title>Abwesenheit</Modal.Title>
                        <button type="button" className="close" aria-label="Close" onClick={handleCloseDetailModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="detail-view-calendar">
                        <Calendar 
                            multiple
                            format="DD.MM.YYYY"
                            readOnly
                            weekDays={weekDays}
                            months={months}
                            highlightToday={false}
                            value={selectedDateArray} 
                            weekStartDayIndex={1}
                            plugins={[
                                <DatePanel sort="date" header="Ausgewählt" removeButton={false}/>
                            ]}
                        /> 
                    </Modal.Body>
                    <Modal.Footer>
                        <PrimaryButton text="Okay" onClick={handleCloseDetailModal} />
                    </Modal.Footer>
            </Modal>
        )
    }

    function removeAbsenceModal() {
        return (
            <Modal
                show={openRemovingModal}
                onHide={handleCloseRemovingModal}
                keyboard={true}
            >
                <Modal.Header>
                        <Modal.Title>Abwensenheit löschen</Modal.Title>
                        <button type="button" className="close" aria-label="Close" onClick={handleCloseRemovingModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Möchten Sie wirklich diese Abwesenheit löschen?</p>  
                        {removingAbsenceError && <p className="red">Ein Fehler ist aufgetreten.</p>}              
                    </Modal.Body>
                    <Modal.Footer>
                        <CancelButton text="Abbrechen" onClick={handleCloseRemovingModal} />
                        <PrimaryButton text="Abwesenheit löschen" onClick={() => sendDeleteRequest()} />
                    </Modal.Footer>
            </Modal>
        )
    }

    return isLoading?
        <LoadingSpinner/>
        :
        (
            <>
                <EmployeeCreationAbsence />
                {removeAbsenceModal()}
                <div className="year-select">
                    <FormControl className="year-select-formcontrol">
                        <InputLabel>Jahr</InputLabel>
                        <Select 
                            value={year}
                            label="Jahr"
                            onChange={handleYearChange}
                            fullWidth
                        >
                            <MenuItem value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</MenuItem>
                            <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                            <MenuItem value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="month-container">    
                    <List className="parent-list-all-months" sx={{width: "50%"}}>
                        {monthListItems()}
                    </List>
                </div>                
                {terminatedAbsenceModal()}
            </>
        )
}

export default EmployeeAbsence;