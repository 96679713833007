import React, { useEffect, useState } from "react";
import "./HolidayView.css";
import ConfigureHolidayModal from "../configureHolidayModal/ConfigureHolidayModal";
import { ButtonGroup, Divider, FormControl, InputLabel, List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select, TextField, FormControlLabel, Checkbox } from "@mui/material";
import CelebrationIcon from '@mui/icons-material/Celebration';
import { Edit } from "@mui/icons-material";
import axios from "axios";
import LoadingSpinner from "../../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { Modal } from "react-bootstrap";
import CancelButton from "../../../../../../sharedComponents/cancelButton/CancelButton";
import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import formatDuration from "../../../../../../sharedComponents/FormatDuration";
import parseDuration from "../../../../../../sharedComponents/ParseDuration";
import HolidayChanges from "./changes/HolidayChanges";

let options = { day: '2-digit', month: '2-digit', year: 'numeric' };

function HolidayView(props) {
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [absenceDays, setAbsenceDays] = useState(null); 
    const [selectedAbsence, setSelectedAbsence] = useState(null); 
    const [year, setYear] = useState(new Date().getFullYear());

    const [countHours, setCountHours] = useState(8);
    const [requestParameterHourMinute, setRequestParameterHourMinute] = useState({});
    const [paidCheckbox, setPaidCheckbox] = useState(true);
    const [updateHolidayError, setUpdateHolidayError] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const handleOpen = (absence) => {
        setPaidCheckbox(absence.paid);
        setSelectedAbsence(absence);
        if(absence.absence_days[0].duration.split("T")[1].split("H")[1] === undefined){ //example PT0H30M; no hour only minute
            setCountHours((absence.absence_days[0].duration.match(/\d+/)/60).toLocaleString("de-DE"));
            setRequestParameterHourMinute({hour: 0, minute: absence.absence_days[0].duration.match(/\d+/)});
        } else {
            let timeSum;
            if(absence.absence_days[0].duration.split("H")[1].split("M")[0].length === 0){ //example PT1H; hour no minute
                timeSum = parseInt(absence.absence_days[0].duration.split("T")[1].split("H")[0])
                setRequestParameterHourMinute({hour: absence.absence_days[0].duration.split("T")[1].split("H")[0], minute: 0})
            } else {
                timeSum = (parseInt(absence.absence_days[0].duration.split("T")[1].split("H")[0]) + (parseInt(absence.absence_days[0].duration.split("H")[1].split("M")[0])/60)).toLocaleString("de-DE");
                setRequestParameterHourMinute({hour: parseInt(absence.absence_days[0].duration.split("T")[1].split("H")[0]), minute: parseInt(absence.absence_days[0].duration.split("H")[1].split("M")[0])})
            }
            setCountHours(timeSum);
        }
        
        setOpenModal(true);
        setUpdateHolidayError(false);
    }
    const handleClose = () => {
        setOpenModal(false);
    }

    useEffect(() => {
        getCurrentHolidayDays();
    }, [])

    useEffect(() => {
        setIsLoading(true);
        getCurrentHolidayDays();
    }, [refresh])

    function getCurrentHolidayDays() {
        axios.get('absence/getAllHolidayAbsencePeriodsForEmployeeWithId?id=' + new URLSearchParams(window.location.search).get("employeeId")).then(results => {
            setAbsenceDays(results.data);
            setIsLoading(false);
        }).catch(() => {})
    }

    const handleYearChange = (event) => setYear(event.target.value);

    const handleHourChange = (e) => {
        const regex = /^\d*,?\d{0,2}$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setCountHours(e.target.value);

            let valueArray = e.target.value.split(",");
            let timeObject;
            if(valueArray.length === 1 || valueArray[1] === ""){
                timeObject = {hour: valueArray[0], minute: 0};
                setRequestParameterHourMinute(timeObject);    
            } else {
                timeObject = {hour: valueArray[0], minute: Math.round((parseInt(valueArray[1])/Math.pow(10, valueArray[1].length))*60)};
                setRequestParameterHourMinute(timeObject);
            }
        }
    }

    const handleCheckbox = (event) => setPaidCheckbox(event.target.checked);

    function createAbsenceDays() {
        let absenceDayHtml = [];

        absenceDays.forEach((item, index) => {
            let firstAbsence = item.absence_days[0];
            let date = new Date(firstAbsence.date);
            if (date.getFullYear() === year) {
                if (item.paid) {
                    absenceDayHtml.push(
                        <React.Fragment key={index}>
                            <ListItemButton disableRipple onClick={() => handleOpen(item)}>
                                <ListItemIcon>
                                    <CelebrationIcon />
                                </ListItemIcon>
                                <ListItemText primary={date.toLocaleDateString('de-DE', options) + " -" + item.comment.split(":")[1]} secondary={formatDuration(parseDuration(firstAbsence.duration)) + " bezahlt"} />
                                <ListItemSecondaryAction>
                                    <Edit />
                                </ListItemSecondaryAction>
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>
                    )
                } else {
                    absenceDayHtml.push(
                        <React.Fragment key={index}>
                            <ListItemButton disableRipple className="gray" onClick={() => handleOpen(item)} >
                                <ListItemIcon>
                                    <CelebrationIcon />
                                </ListItemIcon>
                                <ListItemText primary={date.toLocaleDateString('de-DE', options) + " -" + item.comment.split(":")[1]} secondary={"unbezahlt"} />
                                <ListItemSecondaryAction>
                                    <Edit />
                                </ListItemSecondaryAction>
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>
                    )
                }
            }
        })

        return absenceDayHtml;
    }

    const sendHolidayUpdate = () => {
        axios.put("absence/updateHolidayAbsencePeriodWithId", {
            "absence_period_id": selectedAbsence.id,
            "new_duration": "PT"+ requestParameterHourMinute.hour + "H" + requestParameterHourMinute.minute + "M",
            "new_paid": paidCheckbox,
        }).then(() => {
            handleClose();
            setRefresh(!refresh);
        }
        ).catch(error => {
            setUpdateHolidayError(true);
        })
    }

    function createPayHolidayModal() {
        return (
            <Modal
                show={openModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Feiertag ändern</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {selectedAbsence && <FormControlLabel label="Bezahlt" control={<Checkbox checked={paidCheckbox} onChange={handleCheckbox} />} />}
                    <div className="set-hours-minutes">
                        <InputLabel>Anzahl Stunden</InputLabel>
                        <ButtonGroup>
                            {/* <Button
                                onClick={() => setCountHours((prev) => prev - 1)}
                                disabled={countHours === 0}
                            >
                                <RemoveIcon fontSize="small" />
                            </Button> */}
                            <TextField size="small" onChange={handleHourChange} value={countHours} sx={{width: "120px"}}/>
                            {/* <Button onClick={() => setCountHours((prev) => prev + 1)}>
                                <AddIcon fontSize="small" />
                            </Button> */}
                        </ButtonGroup>
                        <p>Umgerechnet {requestParameterHourMinute.hour}Std {requestParameterHourMinute.minute}min</p>
                    </div>
                    {updateHolidayError && <p className="red">Ein Fehler ist aufgetreten.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleClose} />
                    <PrimaryButton text="Feiertag ändern" onClick={sendHolidayUpdate} />
                </Modal.Footer>
            </Modal>
        )
    }

    return isLoading ?
        <LoadingSpinner />
        :
        <div className="holiday-view-container">
            <ConfigureHolidayModal withNoneSelection={true} holidays={props.holidays}/>
            <HolidayChanges holidays={props.holidays}/>
            <div className="year-select">
                    <FormControl className="year-select-formcontrol">
                        <InputLabel>Jahr</InputLabel>
                        <Select 
                            value={year}
                            label="Jahr"
                            onChange={handleYearChange}
                            fullWidth
                        >
                            <MenuItem value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</MenuItem>
                            <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                            <MenuItem value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            <List className="holiday-view-list">
                <ListSubheader>Feiertage</ListSubheader>
                <Divider />
                {createAbsenceDays()}
                {createPayHolidayModal()}
            </List>
        </div>
}

export default HolidayView;