import { Add } from "@mui/icons-material";
import { TextField, ToggleButton, ToggleButtonGroup, Fab } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import BackButton from "../../../../../sharedComponents/backButton/BackButton";
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";
import "./CoreTimeCreation.css";

function CoreTimeCreation() {

    const [name, setName] = useState('');

    const [nameError, setNameError] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [weekdays] = useState([
        {day: 'Montag', dayValue:"MONDAY", startTime1: '', endTime1: '', startTime2: '', endTime2: '', selected: true, secondTimeActivated: false}, 
        {day: 'Dienstag', dayValue:"TUESDAY", startTime1: '', endTime1: '', startTime2: '', endTime2: '', selected: true, secondTimeActivated: false}, 
        {day: 'Mittwoch', dayValue:"WEDNESDAY", startTime1: '', endTime1: '', startTime2: '', endTime2: '', selected: true, secondTimeActivated: false}, 
        {day: 'Donnerstag', dayValue:"THURSDAY", startTime1: '', endTime1: '', startTime2: '', endTime2: '', selected: true, secondTimeActivated: false}, 
        {day: 'Freitag', dayValue:"FRIDAY", startTime1: '', endTime1: '', startTime2: '', endTime2: '', selected: true, secondTimeActivated: false}, 
        {day: 'Samstag', dayValue:"SATURDAY", startTime1: '', endTime1: '', startTime2: '', endTime2: '', selected: false, secondTimeActivated: false}, 
        {day: 'Sonntag', dayValue:"SUNDAY", startTime1: '', endTime1: '', startTime2: '', endTime2: '', selected: false, secondTimeActivated: false}
    ]);
    const [selectedWeekdays, setSelectedWeekdays] = useState(['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag']);

    const [weekdayError, setWeekdayError] = useState(false);

    const handleWeekdays = (event, newWeekdayArray) => {
        setSelectedWeekdays(newWeekdayArray);
        
        weekdays.forEach(weekday => {
            weekday.selected = false;
            newWeekdayArray.forEach(newWeekday => {
                if (newWeekday === weekday.day) {
                    weekday.selected = true;
                }
            })
        })
    }

    function generateWeekdaysWithTimeSelection() {
        let weekdaysWithTimeSelection = [];

        weekdays.forEach((weekday, index) => {
            if (weekday.selected && weekday.secondTimeActivated) {
                weekdaysWithTimeSelection.push(
                    <>
                        <div className="weekday-time-selection-container" key={index}>
                            <h5>{weekday.day}</h5>
                            <div className="weekday-time-input-container">
                                <div className="first-input">
                                    <p>Von:</p>
                                    <input type="time" onChange={(event) => weekday.startTime1 = event.target.value} className="modal-input form-control"/>
                                    <p>Bis:</p>
                                    <input type="time" onChange={(event) => weekday.endTime1 = event.target.value} className="modal-input form-control"/>
                                    <strong>Weitere Zeit: </strong>
                                </div>
                                <div className="second-input">
                                    <p>Von:</p>
                                    <input type="time" onChange={(event) => weekday.startTime2 = event.target.value} className="modal-input form-control"/>
                                    <p>Bis:</p>
                                    <input type="time" onChange={(event) => weekday.endTime2 = event.target.value} className="modal-input form-control"/>    
                                </div>
                            </div>
                        </div>
                    </>
                )
            } else if (weekday.selected) {
                weekdaysWithTimeSelection.push(
                    <>
                        <div className="weekday-time-selection-container" key={index}>
                            <h5>{weekday.day}</h5>
                            <div className="weekday-time-input-container">
                                <p>Von:</p>
                                <input type="time" onChange={(event) => weekday.startTime1 = event.target.value} className="modal-input form-control"/>
                                <p>Bis:</p>
                                <input type="time" onChange={(event) => weekday.endTime1 = event.target.value} className="modal-input form-control"/>
                                <Fab color="primary" onClick={() => {weekday.secondTimeActivated = true; setRefresh(!refresh);}} className="add-mui-icon" aria-label="add" size="small" style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}>
                                    <Add />
                                </Fab>
                            </div>
                        </div>
                    </>
                )
            }
        })
        return weekdaysWithTimeSelection;
    }

    function prepareArrayToSendTimePeriods() {
        let preparedArray = [];

        weekdays.forEach((weekday) => {
            if (weekday.selected) {
                if ((weekday.starTime1 !== '') && (weekday.endTime1 !== '')) {
                    preparedArray.push(prepareArrayObject(weekday.dayValue, weekday.startTime1, weekday.endTime1));
                }
                if ((weekday.starTime2 !== '') && (weekday.endTime2 !== '')) {
                    preparedArray.push(prepareArrayObject(weekday.dayValue, weekday.startTime2, weekday.endTime2));
                }
            }
        })

        if (preparedArray.length < selectedWeekdays.length) {
            setWeekdayError(true);
            preparedArray = [];
            return preparedArray;
        }
            
        return preparedArray;
    }

    function prepareArrayObject(day, startTime1, endTime1) {
        return {
            "day_of_week": day,
            "start_time": startTime1,
            "end_time": endTime1
        }
    }

    const saveNewCoreTimeConfiguration = () => {
        if (name === '') {
            setNameError(true);
        } else {
            axios.post('coreWorkingHoursPolicy/create', {
                "name": name,
                "periods": prepareArrayToSendTimePeriods()
            }).then((response) => {
                window.location.assign("/home/settings/coretime");
            }).catch(() => {
                setWeekdayError(true);
            })
        }
    }

    return (
        <div className="create-core-time-form">
            <Link to={"/home/settings/coretime"}><BackButton /></Link>
            <h4>Neue Kernarbeitszeit Regelung anlegen</h4>
            <TextField 
                required 
                error={nameError} 
                label="Bezeichnung" 
                variant="outlined" 
                helperText={nameError ? "Feld darf nicht leer sein" : false} 
                onChange={e => setName(e.target.value)}
            />
            <Divider />
            <ToggleButtonGroup
                value={selectedWeekdays}
                onChange={handleWeekdays}
                color={"primary"}
            >
                {weekdays.map((weekday) => (
                    <ToggleButton key={weekday.day} value={weekday.day}>
                    {weekday.day.substring(0, 2)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            {generateWeekdaysWithTimeSelection()}
            {weekdayError && <p className="red-color">Regelung konnte nicht erstellt werden. Prüfe deine Einstellungen.</p>}
            <PrimaryButton text="Neue Regelung speichern" onClick={saveNewCoreTimeConfiguration}/>
        </div> 
    )
}

export default CoreTimeCreation;