import "./WorktimeAccount.css"
import { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { List } from "@mui/material";
import { Add, DoDisturb, Delete, EditNote } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import axios from "axios";
import WorktimeModals from "./worktimeModals/WorktimeModals";
import WorktimeDashboard from "./worktimeDashboard/WorktimeDashboard";
import WorktimeList from "./worktimeList/WorktimeList";
import ScrollRecovery from "../../../../../sharedComponents/ScrollRecovery";
import parseDuration from "../../../../../sharedComponents/ParseDuration";

function WorktimeAccount(){
    const [isLoading, setIsLoading] = useState(true);
    const [hasActiveAccount, setHasActiveAccount] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalClosingOpen, setModalClosingOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [modalCorrectionOpen, setModalCorrectionOpen] = useState(false);
    const [modalStartValueChangeOpen, setModalStartValueChangeOpen] = useState(false);

    const [firstMonthDatePicker, setFirstMonthDatePicker] = useState();
    const [timeAccountId, setTimeAccountId] = useState("");
    const [lastMonthDatePicker, setlastMonthDatePicker] = useState();

    const [correctionDescription, setCorrectionDescription] = useState("");
    const [currentAddCorrectionId, setCurrentAddCorrectionId] = useState("");
    
    const [allMonths, setAllMonths] = useState([])
    const [allMonthsLength, setAllMonthsLength] = useState(0);
    const isInitialRender = useRef(true);
    const [hasCorrections, setHasCorrections] = useState(false);
    const [changeTargetWorkingTime, setChangeTargetWorkingTime] = useState([])
    const [changeFollowing, setChangeFollowing] = useState([]);

    const [accountIsClosing, setAccountIsClosing] = useState(false);

    useEffect(() => {
        getEmployeeData();
    }, [])

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;            
        } else {
            const newTargetTime = [];
            allMonths.forEach((item) => {
                newTargetTime.push(parseDuration(item.target_working_hours))            
            })
            setChangeTargetWorkingTime(newTargetTime);

            setIsLoading(false);
        }
    }, [allMonths])

    function getEmployeeData(){
        resetAllStates();
        axios.get('workingTimeAccount/getAllForEmployeeWithId?id=' + new URLSearchParams(window.location.search).get("employeeId")).then(results => {
            if(Object.keys(results.data).length === 0){ // check if result is an empty JSON
                setHasActiveAccount(false);
                setIsLoading(false);
            } else {
                const element = results.data[0];

                setHasActiveAccount(true);
                setTimeAccountId(element.id)
                setAllMonthsLength(element.months.length)
                setAllMonths(element.months)
                setAccountIsClosing(element.last_month !== null);
                element.months.forEach((item) => {
                    if(item.corrections.length !== 0){
                        setHasCorrections(true);
                    }
                });

                //without this setTimeout the Soll-Zeit wont be displayed in the inputfield, even though the code doesnt make sense
                //adjusted time to 100 ms, because of not displaying correct time in Safari Browser
                setTimeout(function(){  
                    setIsLoading(false);                    
                }, 100);
                console.log(results.data);
                
            }            
        })
    }

    function sendCreatePostRequest(){
        if(firstMonthDatePicker === null || firstMonthDatePicker === undefined){
            setOpenErrorSnackbar(true);
        } else {
            let startResultValueHour = document.getElementById("startResultValueHour").value;
            let startResultValueMinute;
            if(parseInt(startResultValueHour) < 0) startResultValueMinute = '-' + document.getElementById("startResultValueMinute").value;
                else startResultValueMinute = document.getElementById("startResultValueMinute").value;

            let newTargetTimeSplit = document.getElementById("monthlyPlannedWorkTime").value.split(",");
            let timeObject;
            if(newTargetTimeSplit.length === 1 || newTargetTimeSplit[1] === ""){
                timeObject = {hour: newTargetTimeSplit[0], minute: 0};
            } else {
                timeObject = {hour: newTargetTimeSplit[0], minute: Math.round((parseInt(newTargetTimeSplit[1])/Math.pow(10, newTargetTimeSplit[1].length))*60)};
            }

            axios.post('workingTimeAccount/create', {
                "employee_id": new URLSearchParams(window.location.search).get("employeeId"), 
                "first_month":  firstMonthDatePicker.toISOString().substring(0,7),
                "standard_monthly_target_hours": "PT" + timeObject.hour + "H" + timeObject.minute + "M",
                "start_result_value": "PT" + startResultValueHour + "H" + startResultValueMinute + "M"
            }).then(() => {
                setIsLoading(true);
                getEmployeeData();
            })
        }        
    }

    const deleteWorkingTimeAccount = () => {
        if(hasCorrections){
            allMonths.forEach((item) => {
                if(item.corrections.length !== 0){
                    item.corrections.forEach((element) => {
                        deleteTimeCorrection(element.id)
                    })
                }
            })
        }


        axios.delete('workingTimeAccount/delete?id=' + timeAccountId).then(() => {
            setHasActiveAccount(false);
            setIsLoading(true);
            getEmployeeData();
        })
    }

    const deleteTimeCorrection = (id) => {
        axios.delete('workingTimeAccount/deleteTimeCorrection?id=' + id).then(() => {
            setIsLoading(true);
            getEmployeeData();
        })
    }

    function sendPutRequestClosing(){
        if(lastMonthDatePicker === null || lastMonthDatePicker === undefined){
            setOpenErrorSnackbar(true);
        } else {
            axios.put('workingTimeAccount/close', {
                "id" : timeAccountId,
                "last_month" : lastMonthDatePicker.toISOString().substring(0,7)
            }).then(() => {
                setIsLoading(true);
                getEmployeeData();
            })
        }        
    }

    function sendCreateCorrectionPostRequest(){
        axios.post('workingTimeAccount/createNewTimeCorrection', {
            "working_time_account_month_id" : currentAddCorrectionId, 
            "working_time_correction" : "PT-" + document.getElementById("correctionTimeHour").value + "H-" + document.getElementById("correctionTimeMinute").value + "M", 
            "description" : "Überstunden ausgezahlt"
        }).then(() => {
            setIsLoading(true);
            getEmployeeData();
        })
    }

    const closeWorkingTimeAccount = () => {
        setModalClosingOpen(true);
        setlastMonthDatePicker(null);
    }

    const handleSave = (index) => {
        if(changeFollowing[index]){
            axios.put('workingTimeAccount/updateTargetWorkingHoursIncludingFollowingMonths', {
                "working_time_account_id" : timeAccountId, 
                "monthly_target_hours" : "PT" + changeTargetWorkingTime[index].hours + "H" + changeTargetWorkingTime[index].minutes + "M", 
                "month" : allMonths[index].month
            }).then(() => {
                setIsLoading(true);
                getEmployeeData();
            })
        } else {
            axios.put('workingTimeAccount/updateTargetWorkingHoursForSingleMonth', {
                "working_time_account_id" : timeAccountId, 
                "monthly_target_hours" : "PT" + changeTargetWorkingTime[index].hours + "H" + changeTargetWorkingTime[index].minutes + "M", 
                "month" : allMonths[index].month
            }).then(() => {
                setIsLoading(true);
                getEmployeeData();
            })
        }
    }

    function sendChangeStartValuePutRequest(){
        let startResultValueHour = document.getElementById("startResultChangeValueHour").value;
        let startResultValueMinute;
        if(parseInt(startResultValueHour) < 0) startResultValueMinute = '-' + document.getElementById("startResultChangeValueMinute").value;
            else startResultValueMinute = document.getElementById("startResultChangeValueMinute").value;

        axios.put('workingTimeAccount/updateStartResultValue', {
            "id" : timeAccountId, 
            "new_start_result_value" : "PT" + startResultValueHour + "H" + startResultValueMinute + "M"
        }).then(() => {
            setIsLoading(true);
            getEmployeeData();
        })
    }

    function resetAllStates(){
        setModalOpen(false);
        setModalClosingOpen(false);
        setModalCorrectionOpen(false);
        setModalDeleteOpen(false);
        setModalStartValueChangeOpen(false);

        setFirstMonthDatePicker(null);
        setlastMonthDatePicker(null);
    }

    const handleTargetTimeChange = (event, index) => {
        const newTargetTime = [...changeTargetWorkingTime]
        let splitEventString = event.target.value.split(",")
        let newTimeObject;
        if(splitEventString.length !== 1 && splitEventString[1] !== ""){
            newTimeObject = {hours: parseInt(splitEventString[0]), minutes: Math.round((parseInt(splitEventString[1])/Math.pow(10, splitEventString[1].length))*60)};
        } else newTimeObject = {hours: parseInt(splitEventString[0]), minutes: 0};
        
        newTargetTime[index] = newTimeObject;
        setChangeTargetWorkingTime(newTargetTime)
    }

    const handleCloseErrorSnackbar = (event, reason) => {
        if (reason === 'clickaway')
            return;

        setOpenErrorSnackbar(false);
    }

    const handleAddCorrection = (monthObject) => {
        setModalCorrectionOpen(true);
        setCurrentAddCorrectionId(monthObject.id)
    }

    /*function pastWorkTimeAccounts(){
        return (
            <div className="past-worktime">
                <List sx={{width: '50%'}} >
                    <ListItem>fdsf</ListItem>
                </List>
            </div>
        )
    }*/

    return isLoading ? <LoadingSpinner/> :
        !hasActiveAccount ? (
        <>
            <ScrollRecovery setCondition={!isLoading} scrollKey={"WorktimeAccountScroll"}/>
            <WorktimeModals
                newAccountPostRequest={() => sendCreatePostRequest()}
                newAccountOpen={modalOpen}
                newAccountClose={() => {setModalOpen(false); setFirstMonthDatePicker(null)}}
                newAccountDatePickerValue={firstMonthDatePicker}
                newAccountDatePickerOnChange={(value) => setFirstMonthDatePicker(value)}

                datePickerError={openErrorSnackbar}
                datePickerErrorClose={handleCloseErrorSnackbar}
            />
            <div className="no-active-worktime-account-container">
                Es existiert noch kein Arbeitszeitkonto. Möchten Sie ein neues anlegen?
                <Button variant="dark" onClick={() => setModalOpen(true)} style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}>
                    <Add/>Arbeitszeitkonto hinzufügen
                </Button>
            </div>
            {/* <div className="past-worktime-container">
                {pastWorkTimeAccounts()}
            </div> */}
        </>) : 
        <>
            <ScrollRecovery setCondition={!isLoading} scrollKey={"WorktimeAccountScroll"}/>
            <WorktimeModals 
                createCorrectionPostRequest={() => sendCreateCorrectionPostRequest()}
                correctionOpen={modalCorrectionOpen} 
                correctionClose={() => setModalCorrectionOpen(false)} 
                correctionDescription={correctionDescription}
                correctionDescriptionOnChange={(value) => setCorrectionDescription(value)}

                closeAccountPutRequest={() => sendPutRequestClosing()}
                closingShow={modalClosingOpen}
                closingClose={() => setModalClosingOpen(false)}
                closingDatePickerValue={lastMonthDatePicker}
                closingDatePickerOnChange={(value) => setlastMonthDatePicker(value)}

                deleteAccountDeleteRequest={deleteWorkingTimeAccount}
                deleteOpen={modalDeleteOpen}
                deleteClose={() => setModalDeleteOpen(false)}

                changeStartValuePutRequest={() => sendChangeStartValuePutRequest()}
                changeStartValueShow={modalStartValueChangeOpen}
                changeStartValueClose={() => setModalStartValueChangeOpen(false)}

                datePickerError={openErrorSnackbar}
                datePickerErrorClose={handleCloseErrorSnackbar}
            />
            <div className="dashboard-card-container">
                <WorktimeDashboard allMonths={allMonths}/>
            </div>
            <div className="button-container">
                <Button variant="dark" onClick={() => setModalStartValueChangeOpen(true)} style={{ backgroundColor: 'var(--tictrack-secondary-color)' }}><EditNote/> Starttransferstunden verändern</Button>
                {/* !accountIsClosing && <Button variant="warning" color="warning" onClick={closeWorkingTimeAccount} ><DoDisturb/> Arbeitszeitkonto schließen</Button> */}
                <Button variant="danger" color="error" onClick={()=> setModalDeleteOpen(true)} ><Delete/></Button>         
            </div>            
            <div className="month-list-container">    
                <List className="parent-list-all-months">
                    <WorktimeList
                        allMonths={allMonths}
                        save={handleSave}
                        targetTimeArray={changeTargetWorkingTime}
                        targetTimeChange={handleTargetTimeChange}
                        addCorrection={handleAddCorrection}
                        deleteTimeCorrection={deleteTimeCorrection}
                        allMonthsLength={allMonthsLength}

                        changeFollowing={changeFollowing}
                        setChangeFollowing={(value) => setChangeFollowing(value)}
                    />
                </List>
            </div>
            {/* <div className="past-worktime-container">
                {pastWorkTimeAccounts()}
            </div> */}
        </>
}

export default WorktimeAccount;