import "./EmployeeExport.css";
import {useEffect, useState} from "react";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import {CSVLink} from "react-csv";
import axios from "axios";
import "react-toggle/style.css";
import PrimaryButton from "../../../../../sharedComponents/primaryButton/PrimaryButton";

function EmployeeCSV() {

    const [csvDurationData, setCsvDurationData] = useState("");
    const [csvDurationHeader, setCsvDurationHeader] = useState({});

    const [fileValue, setFileValue] = useState("");

    const [currentEmployee] = useState(new URLSearchParams(window.location.search).get("employeeId"))

    const currentMonth = new Date().toLocaleString('en', { month: 'long' });
    const currentYear = new Date().getFullYear();
    const [exportMonth, setExportMonth] = useState(currentMonth);
    const [exportYear, setExportYear] = useState(currentYear);

    // PDF Options
    const [changeTimeToDecimalFormat, setChangeTimeToDecimalFormat] = useState(false);
    const [includeSignatureLine, setIncludeSignatureLine] = useState(false);
    const [includeWorkingTimeAccount, setIncludeWorkingTimeAccount] = useState(false);

    const [includeSumAndUnpaidAbsences, setIncludeSumAndUnpaidAbsences] = useState(false);
    const [downloadAllShifts, setDownloadAllShifts] = useState(false);

    useEffect(() => {
        getDurationCsvData();
    }, [exportMonth, exportYear, includeSumAndUnpaidAbsences, downloadAllShifts])

    const handleChange = (event) => {
        setDownloadAllShifts(false)
        switch (event.target.value) {
            case "PDF":
                setFileValue("PDF");
                break;
            case "CSV":
                setFileValue("CSV");
                break;
            case "erweiterte PDF":
                setFileValue("erweiterte PDF");
                break;
            default:
                break;
        }
    }

    const csvTimeDataLink = {
        filename: csvDurationHeader.filedescription,
        data: csvDurationData
    }

    function getDurationCsvData() {
        if(downloadAllShifts){
            axios.get('shift/getAllByEmployeeId/csv?id=' + currentEmployee + '&includeSumAndUnpaidAbsences=' + includeSumAndUnpaidAbsences)
            .then(results => {
                setCsvDurationData(results.data);
                setCsvDurationHeader(results.headers);
            })
        } else {
            axios.get('/shift/getByEmployeeIdInDuration/csv?id=' + currentEmployee
            + '&month='+ exportMonth + '&year='+ exportYear + '&includeSumAndUnpaidAbsences=' + includeSumAndUnpaidAbsences)
            .then(results => {
                setCsvDurationData(results.data);
                setCsvDurationHeader(results.headers);
            })
        }
    }

    const handleDownload = () => {        
        axios.get('shift/getByEmployeeIdInDuration/pdf?id='+ currentEmployee
        + '&month='+ exportMonth + '&year='+ exportYear 
        + '&decimal='+ changeTimeToDecimalFormat + '&signature='+ includeSignatureLine + '&includeWorkingTimeAccount=' + includeWorkingTimeAccount,
        {
            responseType: 'blob',
            headers: {'Content-Type': 'application/json'},
        }
        ).then((results) => 
            createPDF(results, 'Arbeitszeiten von '+ currentEmployee + ' im ' + exportMonth + ' ' + exportYear)
        ) 
    }

    const requestConfig = {
        url: `shift/getByEmployeeIdInDuration/advancedPdf?id=${currentEmployee}&month=${exportMonth}&year=${exportYear}`,
        data: {
            bundesland: 'nordrhein_westfalen',
            complex_working_hours_configuration: {
                begin_of_normal_work_hours: "06:00:00",
                begin_of_evening_work_hours: "18:00:00",
                begin_of_night_work_hours: "20:00:00"
            },
            include_paid_absences: true,
            include_unpaid_absences: true
        },
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json'
        }
    };   

    const handleAdvancedDownload = () => {
        axios.post(requestConfig.url, requestConfig.data, {
            responseType: requestConfig.responseType,
            headers: requestConfig.headers
        })
        .then((results) => createPDF(results, 'Arbeitszeiten von '+ currentEmployee + ' im ' + exportMonth + ' ' + exportYear));        
    }

    function createPDF(results, filename){
        const data = results.data
        const blob = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    function getAccordingDownloadButton() {
        switch(fileValue){
            case "CSV": 
                return (<CSVLink {...csvTimeDataLink}><PrimaryButton text="Download" /></CSVLink>)
            case "PDF":
                return (<PrimaryButton text="Download" onClick={handleDownload} />)
            case "erweiterte PDF":
                return (<PrimaryButton text="Download" onClick={handleAdvancedDownload} />)
            default:
                break;
        }
        
    }

    const handleExportYearChange = (event) => {
        setExportYear(event.target.value)
    }

    const handleExportMonthChange = (event) => {
        setExportMonth(event.target.value)
    }

    return (
        <>
            <div className="export-switch-container">
                <FormControl className="export-form-selection" sx={{width: '40em'}}>
                    <InputLabel id="export-select-label">Dateiformat auswählen</InputLabel>
                    <Select
                        labelId="export-select-label"
                        value={fileValue}
                        label="Dateiformat auswählen"
                        onChange={handleChange}
                    >
                        <MenuItem value={"CSV"}>CSV</MenuItem>
                        <MenuItem value={"PDF"}>PDF</MenuItem>
                        <MenuItem value={"erweiterte PDF"}>erweiterte PDF</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {fileValue !== "" &&<div className="year-select">
                <FormControl className="export-year-select-formcontrol">
                    <InputLabel>Monat</InputLabel>
                    <Select 
                        value={exportMonth}
                        label="Monat"
                        onChange={handleExportMonthChange}
                        fullWidth
                        disabled={downloadAllShifts}
                    >
                        <MenuItem value={"January"}>Januar</MenuItem>
                        <MenuItem value={"February"}>Februar</MenuItem>
                        <MenuItem value={"March"}>März</MenuItem>
                        <MenuItem value={"April"}>April</MenuItem>
                        <MenuItem value={"May"}>Mai</MenuItem>
                        <MenuItem value={"June"}>Juni</MenuItem>
                        <MenuItem value={"July"}>Juli</MenuItem>
                        <MenuItem value={"August"}>August</MenuItem>
                        <MenuItem value={"September"}>September</MenuItem>
                        <MenuItem value={"October"}>Oktober</MenuItem>
                        <MenuItem value={"November"}>November</MenuItem>
                        <MenuItem value={"December"}>Dezember</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className="export-year-select-formcontrol">
                    <InputLabel>Jahr</InputLabel>
                    <Select 
                        value={exportYear}
                        label="Jahr"
                        onChange={handleExportYearChange}
                        fullWidth
                        disabled={downloadAllShifts}
                    >
                        <MenuItem value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</MenuItem>
                        <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
                        <MenuItem value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</MenuItem>
                    </Select>
                </FormControl>
            </div>}
            {fileValue === "PDF" && <div className="pdf-options-checkbox-container">
            <FormControlLabel control={<Checkbox onChange={(event) => setIncludeWorkingTimeAccount(event.target.checked)} />} label="Arbeitszeitkontoinformationen beifügen"/>
                <FormControlLabel control={<Checkbox onChange={(event) => setChangeTimeToDecimalFormat(event.target.checked)} />} label="Dezimaldarstellung verwenden"/>
                <FormControlLabel control={<Checkbox onChange={(event) => setIncludeSignatureLine(event.target.checked)} />} label="Unterschriftzeile einfügen"/>
            </div>}
            {fileValue === "CSV" && <div className="checkbox-switch-container">
                <div className="csv-checkbox-switch">
                    <FormControlLabel control={<Switch onChange={() => setDownloadAllShifts(!downloadAllShifts)} />} label="Alle Arbeitszeiten herunterladen"/>
                    <FormControlLabel control={<Checkbox onChange={(event) => setIncludeSumAndUnpaidAbsences(event.target.checked)} />} label="Übersicht mit Summe und unbezahlten Abwesenheiten herunterladen"/>
                </div>
            </div>}
            {fileValue === "erweiterte PDF" && <p className="manual-pause-export-warning">Warnung: Wenn Sie manuelle Pausenzeiten (Pausen, die keine Anfangs- und Endzeit besitzen) eingetragen haben, kann die Einteilung in die verschiedenen Zeitfenster fehlerhaft sein.</p>}
            <div className="download-button-container">
                {getAccordingDownloadButton()}
            </div>
        </>
    )
}

export default EmployeeCSV;