import "./CreateShiftGenerator.css";
import FullGeneratedActivity from "./activity/FullGeneratedActivity";
import {useEffect, useState} from "react";
import React from "react";
import LoadingSpinner from "../../../../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import CreateShiftRemoveButton from "./removeButton/CreateShiftRemoveButton";

function CreateShiftGenerator(props) {

    let [activities, setActivities] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [countOfActivities, setCountOfActivities] = useState(null);

    const [filledFirstActivity, setFilledFirstActivity] = useState(false);

    const [reloadKey, setReloadKey] = useState(1);

    useEffect(() => {
        let currentActivities = JSON.parse(sessionStorage.getItem("new-shift-current-activities"));
        loadInitialShifts(currentActivities);
        setIsLoading(false);
    }, [])

    useEffect(() => {
        sessionStorage.setItem("new-shift-current-activities", JSON.stringify(activities));
        setReloadKey(reloadKey + 1);
    }, [countOfActivities])

    useEffect(() => {
        sessionStorage.setItem("new-shift-current-activities", JSON.stringify(activities));
        setReloadKey(reloadKey + 1);
        if (activities !== null && activities[0].time !== "")
            setFilledFirstActivity(true);
    }, [activities])

    function loadInitialShifts(currentShifts) {
        if (currentShifts !== null) {
            setActivities(currentShifts);
            setCountOfActivities(currentShifts.length + 1);
        } else {
            setActivities(([{id: 'Shift 1', type: 'shift_begin', time: "", date: sessionStorage.getItem("new-shift-begin-date")},
                        {id: 'Shift 2', type: 'pseudo', time: "", date: sessionStorage.getItem("new-shift-begin-date")},
                        {id: 'Shift 3', type: 'shift_end', time: "", date: sessionStorage.getItem("new-shift-begin-date")}]));
            
            setCountOfActivities(3);
        }
    }

    return isLoading ?
        <LoadingSpinner />
        :
        <div className="create-shift-generator-container" key={reloadKey}>
            {loadShifts()}
        </div>

    function loadShifts() {
        let generatedActivities = [];

        activities.map((item, index) => {
            if (index === 0) 
                generatedActivities.push(generateActivity(item.type, item.id, item.time, item.date, true))
            else if (item.type === "pseudo")
                generatedActivities.push(createPseudoActivity(index))
            else
                generatedActivities.push(generateActivity(item.type, item.id, item.time, item.date, false))
        })

        return generatedActivities;
    }

    function generateActivity(type, key, time, date, firstActivity) {
        if (firstActivity || filledFirstActivity) {
            if (type === "pause_begin") {
                return (
                    <React.Fragment key={key}>
                        <div className={"generated-shift " + props.noSelection} key={key}>
                        <FullGeneratedActivity id={key} type={type} time={time} date={date} setFilledFirstActivity={setFilledFirstActivity} checkActivitiesToChangeDate={checkActivitiesToChangeDate} saveActivityChanges={saveActivityChanges} />
                        </div>
                        <CreateShiftRemoveButton text="Pause löschen" connectedPause={key} removePause={removePause} />
                    </React.Fragment>
                )
            }
            return (
                <div className={"generated-shift " + props.noSelection} key={key}>
                    <FullGeneratedActivity id={key} type={type} time={time} date={date} setFilledFirstActivity={setFilledFirstActivity} checkActivitiesToChangeDate={checkActivitiesToChangeDate} saveActivityChanges={saveActivityChanges} />
                </div>
            )
        }
    }

    function createPseudoActivity(index) {
        if (filledFirstActivity) {
            return (
                <div className="generated-pseudo-shift" onClick={() => generatePause(index)} key={index}>
                    <h5>Pause hinzufügen</h5>
                </div>
            )
        }
    }

    function generatePause(index) {
        sessionStorage.removeItem("full-completed-new-shift");

        activities.splice((index + 1), 0, {id: 'Shift ' + (activities.length + 1), type:"pause_begin", time:"", date:sessionStorage.getItem("new-shift-begin-date")});
        activities.splice(index + 2, 0, {id: 'Shift ' + (activities.length + 1), type:"pause_end", time:"", date:sessionStorage.getItem("new-shift-begin-date")});
        activities.splice(index + 3, 0, {id: 'Shift ' + (activities.length + 1), type:"pseudo", time:"", date:sessionStorage.getItem("new-shift-begin-date")});
        setCountOfActivities(countOfActivities + 1);  
    }

    function checkActivitiesToChangeDate() {
        let currentActivities = JSON.parse(sessionStorage.getItem("new-shift-current-activities"));
        let updatedActivities = JSON.parse(JSON.stringify(currentActivities));

        props.setNoSelection("");

        let startTime;

        for (let activity of currentActivities) {
            if (activity.id === "Shift 1") {
                startTime = activity.time;
            } else if (activity.time !== "") {
                let currentTime = activity.time;

                let splittedDate = activity.date.split(".");

                let splittedTime = currentTime.split(":");
                let fullDateWithTime = new Date(parseInt(splittedDate[2]), parseInt(splittedDate[1] - 1), parseInt(splittedDate[0]), splittedTime[0], splittedTime[1]);

                let splittedStartTime = startTime.split(":");
                let fullDateWithStartTime = new Date(parseInt(splittedDate[2]), parseInt(splittedDate[1] - 1), parseInt(splittedDate[0]), splittedStartTime[0], splittedStartTime[1]);

                if (fullDateWithTime.getTime() <= fullDateWithStartTime.getTime()) {
                    let day;
                    
                    if (updatedActivities[0].date === activity.date) {
                        fullDateWithTime.setDate(fullDateWithTime.getDate() + 1);
                        day = fullDateWithTime.getDate();
                    } else {
                        day = fullDateWithTime.getDate();
                    }   

                    let month = fullDateWithTime.getMonth() + 1;
                    let year = fullDateWithTime.getFullYear();

                    let formatedDay = ("0" + day).slice(-2);
                    let formatedMonth = ("0" + month).slice(-2);

                    let foundActivity = updatedActivities.find(item => item.id === activity.id);

                    foundActivity.date = formatedDay + "." + formatedMonth + "." + year;
                } else {
                    let foundActivity = updatedActivities.find(item => item.id === activity.id);

                    foundActivity.date = updatedActivities[0].date;
                }
            }
        }

        return updatedActivities;
    }

    function saveActivityChanges(currentActivities) {
        if (currentActivities.find(item => item.time === "" && item.type !== "pseudo") === undefined)
            sessionStorage.setItem("full-completed-new-shift", true);
        else
            sessionStorage.removeItem("full-completed-new-shift");

        setActivities(currentActivities);
    }

    function removePause(connectedPause) {
        let splittedConnectedPause = connectedPause.split(" ");
        let connectedPauseEndValue = parseInt(splittedConnectedPause[1]) + 1;
        let connectedPseudoValue = parseInt(splittedConnectedPause[1]) + 2;

        let connectedPauseEndKey = splittedConnectedPause[0] + " " + connectedPauseEndValue;
        let connectedPseudoKey = splittedConnectedPause[0] + " " + connectedPseudoValue;
        setActivities(activities.filter(item => item.id !== connectedPause && item.id !== connectedPauseEndKey && item.id !== connectedPseudoKey));
    }
}

export default CreateShiftGenerator;