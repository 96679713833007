import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import CancelButton from "../../../../../../sharedComponents/cancelButton/CancelButton";
import LoadingSpinner from "../../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import PrimaryButton from "../../../../../../sharedComponents/primaryButton/PrimaryButton";
import "./CoreTimeChangeAssignment.css";

function CoreTimeChangeAssignment() {

    const [isLoading, setIsLoading] = useState(true);
    const [id] = useState(sessionStorage.getItem("core-time-id"));

    const [showAssignmentModal, setShowAssignmentModal] = useState(false);
    const [showAllAssignmentModal, setShowAllAssignmentModal] = useState(false);

    const handleShowAssigmentModal = () => setShowAssignmentModal(true);
    const handleCloseAssigmentModal = () => setShowAssignmentModal(false);

    const handleShowAllAssigmentModal = () => setShowAllAssignmentModal(true);
    const handleCloseAllAssigmentModal = () => setShowAllAssignmentModal(false);

    const [employees, setEmployees] = useState([]);

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedAllEmployees, setSelectedAllEmployees] = useState(true);

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        getAssignedEmployees();
    }, [])

    function getAssignedEmployees() {
        axios.get('coreWorkingHoursPolicy/getAllEmployeesAssignedToPolicy?id=' + id).then(results => {
            axios.get('employee/getActive')
            .then(allResults => {
                const assignedEmployees = results.data;
                const allEmployees = allResults.data;

                const employeesWithAssignmentStatus = allEmployees.map(employee => {
                    const isAssigned = assignedEmployees.some(assignedEmployee => assignedEmployee.id === employee.id);
                    return {
                        ...employee,
                        isAssigned: isAssigned
                    };
                });

                employeesWithAssignmentStatus.forEach((employee) => {
                    if (employee.isAssigned === false) {
                        setSelectedAllEmployees(false);
                    }
                })

                setEmployees(employeesWithAssignmentStatus);
                setIsLoading(false);
            })
            .catch(error => {});
        }).catch(() => {})
    }

    function generateEmployeeSelection() {
        let employeeSelection = [];
        employees.map((employee, index) => {
            if (!employee.isAssigned && employee.assigned_core_working_hours_policy_id !== null) {
                employeeSelection.push(
                    <div className="core-time-employee-item" key={index} onClick={() => { handleShowAssigmentModal(); setSelectedEmployee(employee)}} >
                        <Tooltip placement="right" arrow title="Anderer Regelung zugewiesen. Zum Überschreiben klicken" >
                            <FormControlLabel control={<Checkbox checked={false} disabled={true} />} label={employee.first_name + " " + employee.last_name} />
                        </Tooltip>
                    </div>
                )
            } else {
                employeeSelection.push(
                    <div className="core-time-employee-item" key={index}>
                        <FormControlLabel control={<Checkbox checked={employee.isAssigned} onChange={event => { employee.isAssigned=event.target.checked; employee.assigned_core_working_hours_policy_id = null; setSelectedAllEmployees(employees.filter(item => item.isAssigned === true).length === employees.length); setRefresh(!refresh);}} />} label={employee.first_name + " " + employee.last_name} />
                    </div>
                )
            }
        })

        return employeeSelection;
    }

    const selectAllEmployees = () => {
        const removingEmployeeIds = employees
            .filter(employee => !employee.isAssigned && employee.assigned_core_working_hours_policy_id !== null)
            .map(employee => employee.id);

        axios.put('coreWorkingHoursPolicy/removeListOfEmployees', {
            "employee_id_list": removingEmployeeIds
        }).then(response => {
            employees.forEach((employee) => {employee.isAssigned = true; employee.assigned_core_working_hours_policy_id = null});
            setSelectedAllEmployees(true);
            handleCloseAllAssigmentModal();
            setRefresh(!refresh);
        }).catch(error => {
            console.log("Fehler beim alle Löschen!");
        })
    }

    const activateBlockedEmployee = () => {
        axios.delete('coreWorkingHoursPolicy/removeEmployeeFromPolicy?id=' + selectedEmployee.id).then(response => {
            let isSelectedAllEmployees = true;
            employees.forEach((employee) => {
                if (employee.isAssigned === false) {
                    isSelectedAllEmployees = false;
                }
            })
            setSelectedAllEmployees(isSelectedAllEmployees);
            selectedEmployee.isAssigned = true; 

            handleCloseAssigmentModal();
            setRefresh(!refresh);
        }).catch(error => {
            console.log("Fehler beim Löschen!");
        })
    }

    function createAssigmentModal() {
        return (
            <Modal
                show={showAssignmentModal}
                onHide={handleCloseAssigmentModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Mitarbeiter auswählen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleCloseAssigmentModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Möchten Sie den Mitarbeiter auswählen?</p>
                    <p className="red-color">Achtung: Mitarbeiter ist einer anderen Regel zugewiesen. Bei Bestätigung wird dieser aus seiner aktuellen Regelung entfernt.</p>
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleCloseAssigmentModal} />
                    <Button variant="danger" onClick={activateBlockedEmployee}>Aus Regelung entfernen</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function generateBlockedEmployees() {
        let blockedEmployeeArray = [];

        employees.forEach((employee) => {
            if ((employee.assigned_core_working_hours_policy_id !== null) && (employee.assigned_core_working_hours_policy_id !== id)) {
                blockedEmployeeArray.push(
                    <p className="blocked-employee-item">{employee.first_name + " " + employee.last_name}</p>
                )
            }
        })
        return blockedEmployeeArray;
    }

    function createAllAssignmentModal() {
        return (
            <Modal
                show={showAllAssignmentModal}
                onHide={handleCloseAllAssigmentModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Alle Mitarbeiter auswählen</Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={handleCloseAllAssigmentModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Möchten Sie alle Mitarbeiter auswählen?</p>
                    <p className="red-color">Achtung: Einige Mitarbeiter sind noch einer anderen Regelung zugeordnet. Bei Bestätigung werden diese aus der alten Regelung entfernt.</p>
                    <strong>Betroffene Mitarbeiter:</strong>
                    {generateBlockedEmployees()}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton text="Abbrechen" onClick={handleCloseAllAssigmentModal} />
                    <Button variant="danger" onClick={selectAllEmployees}>Alle Regelungen entfernen</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const saveEmployeeAssignment = () => {
        const assignedEmployeeIds = employees
            .filter(employee => employee.isAssigned)
            .map(employee => employee.id)

        const notAssignedEmployeeIds = employees
            .filter(employee => !employee.isAssigned && employee.assigned_core_working_hours_policy_id === null)
            .map(employee => employee.id)

        axios.post("coreWorkingHoursPolicy/assignToListOfEmployees", {
            "employee_id_list": assignedEmployeeIds,
            "policy_id": id
        }).then(() => {
            axios.put("coreWorkingHoursPolicy/removeListOfEmployees", {
                "employee_id_list": notAssignedEmployeeIds
            }).then(() => {
                window.location.assign("/home/settings/coretime/detailView?id=" + id);
            })
        })
    }

    const handleAllEmployeeSelection = () => {
        if (selectedAllEmployees) {
            employees.forEach(employee => {
                employee.isAssigned = false;
                employee.assigned_core_working_hours_policy_id = null;
            })
            setSelectedAllEmployees(false);
        } else {
            let isSomeEmployeeBlocked = false;
            employees.forEach(employee => {
                if ((employee.assigned_core_working_hours_policy_id !== null) && (employee.assigned_core_working_hours_policy_id !== id)) {
                    isSomeEmployeeBlocked = true;
                }
            })
            isSomeEmployeeBlocked ? handleShowAllAssigmentModal() : selectAllEmployees();
        }
    }

    return isLoading ?
        <LoadingSpinner />
        :
        <>
            <div className="change-assignment-page">
                <div className="change-assignment-header">
                    <Link to={"/home/settings/coretime/detailView?id=" + id}><CancelButton text="Abbrechen" /></Link>
                    <PrimaryButton onClick={saveEmployeeAssignment} text="Speichern" />
                </div>
                <Divider />
                <h4>Mitarbeiter zuweisen</h4>
                <div className="core-time-employee-item">
                    <FormControlLabel control={<Checkbox checked={selectedAllEmployees} onChange={handleAllEmployeeSelection} />} label="Alle auswählen" />
                </div>
                <Divider />
                <div className="create-core-employee-form">
                    {generateEmployeeSelection()}
                </div>
            </div>
            {createAssigmentModal()}
            {createAllAssignmentModal()}
        </>
}

export default CoreTimeChangeAssignment;