import "./ClearBanner.css";

function ClearBanner() {
    return (
        <header>
            <div className="clear-banner-header">
            
            </div>
        </header>
    )
}

export default ClearBanner;