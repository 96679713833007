import "./Access.css";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import AppAccess from "./app/AppAccess";
import CardAccess from "./card/CardAccess";

function Access() {

    const [accessValue, setAccessValue] = useState(sessionStorage.getItem("emplyoeeAccessOption")||"App Zugang")

    useEffect(() => {

    }, [accessValue])

    function getCurrentAccessPage() {
        switch (accessValue) {
            case "App Zugang":
                return <AppAccess />
            case "Karten Zugang":
                return <CardAccess />
            default:
                break;
        }
    }

    const handleChange = (event) => {
        switch (event.target.value) {
            case "App Zugang":
                sessionStorage.setItem("emplyoeeAccessOption", "App Zugang");
                setAccessValue("App Zugang");
                break;
            case "Karten Zugang":
                sessionStorage.setItem("emplyoeeAccessOption", "Karten Zugang");
                setAccessValue("Karten Zugang");
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className="access-switch-container">
                <FormControl className="access-form-selection">
                    <InputLabel id="access-select-label">Welchen Zugang möchten Sie konfigurieren?</InputLabel>
                    <Select
                        labelId="access-select-label"
                        value={accessValue}
                        label="Welchen Zugang möchten Sie konfigurieren?"
                        onChange={handleChange}
                    >
                        <MenuItem value={"App Zugang"}>App Zugang</MenuItem>
                        <MenuItem value={"Karten Zugang"}>Karten Zugang</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {getCurrentAccessPage()}
        </>
    )

}

export default Access;