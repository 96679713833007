import "./LocationList.css";
import {useEffect, useState} from "react";
import axios from "axios";
import LocationItem from "../locationItem/LocationItem";
import LoadingSpinner from "../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
import Logout from "../../../../sharedComponents/Logout";

function LocationList(props) {

    const [activeIsLoading, setActiveIsLoading] = useState(true);
    const [inActiveIsLoading, setInActiveIsLoading] = useState(true);
    const [limitIsLoading, setLimitIsLoading] = useState(true)

    const [activeLocations, setActiveLocations] = useState(null);
    const [inActiveLocations, setInActiveLocations] = useState(null);

    const [limitLocation, setLimitLocation] = useState(null);
    const [limitLocationIsActive, setLimitLocationIsActive] = useState(false);
    const [currentLocationNumber, setCurrentLocationNumber] = useState(null)

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("jwtToken");
        axios.get('location/getActive').then(results => {
            setActiveLocations(results.data);
            setActiveIsLoading(false);
        }).catch(() => { Logout()})

        axios.get('location/getInactive').then(results => {
            setInActiveLocations(results.data);
            setInActiveIsLoading(false);
        })
    }, [props.locationUpdate]);

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("jwtToken");
        axios.get('info').then(results => {
            if (results.data.max_locations !== 99999) {
                setLimitLocationIsActive(true);
                setLimitLocation(results.data.max_locations);
            } 
            
            if(activeLocations != null) {
                for (let location of activeLocations) {
                    if (location.id === '999999') {
                        setCurrentLocationNumber(activeLocations.length - 1);
                        setLimitIsLoading(false);
                        return;
                    }
                }
                setCurrentLocationNumber(activeLocations.length)
                setLimitIsLoading(false)
            }
        }).catch(() => { Logout()})
    }, [activeLocations])

    function createActiveLocations() {
        let activeProfiles = [];

        // Search 'no location' -> Yes then push first
        for (let location of activeLocations) {
            if (location.id === '999999') {
                if (props.searchInputText === '' || location.name.toUpperCase().includes(props.searchInputText.toUpperCase()) || location.id.toUpperCase().includes(props.searchInputText.toUpperCase())) {
                    activeProfiles.push(
                        <LocationItem key={'Mobile Location'} name={location.name} id={location.id}/>
                    )
                }
            }
        }

        activeLocations.map((item, index) => {
            if (item.id !== '999999') {
                if (props.searchInputText === '' || item.name.toUpperCase().includes(props.searchInputText.toUpperCase()) || item.id.toUpperCase().includes(props.searchInputText.toUpperCase())) {
                    activeProfiles.push(
                        <Link to={'locationView?locationId=' + item.id} key={'Location ' + index}>
                            <LocationItem name={item.name} id={item.id}/>
                        </Link>
                    )
                }
            }
        })

        return activeProfiles;
    }

    function createInactiveLocations() {
        let inactiveProfiles = [];

        inActiveLocations.map((item, index) => {
            if (props.searchInputText === '' || item.name.toUpperCase().includes(props.searchInputText.toUpperCase()) || item.id.toUpperCase().includes(props.searchInputText.toUpperCase())) {
                inactiveProfiles.push(
                    <Link to={'locationView?locationId=' + item.id} key={'Location ' + index}>
                        <LocationItem name={item.name} id={item.id}/>
                    </Link>
                )
            }
        })

        return inactiveProfiles;
    }

    if (activeIsLoading || inActiveIsLoading || limitIsLoading) {
        return <LoadingSpinner/>;
    } else {
        return (
            <div className="location-list-container">
                {limitLocationIsActive && <p className="active-profile-header"> Aktiviert <span className="text-gray">({currentLocationNumber}/{limitLocation})</span></p>}
                {!limitLocationIsActive && <p className="active-profile-header"> Aktiviert <span className="text-gray">({currentLocationNumber})</span></p>}
                {createActiveLocations()}
                <p className="inactive-profile-header">Deaktiviert</p>
                {createInactiveLocations()}
                {/*<Button variant='outlined' onClick={() => localStorage.setItem("jwtToken", '000')}>change jwtToken</Button>           to test logout if Token invalid   */}
            </div>
        )
    }

}

export default LocationList;