import React, { useState } from "react";
import "./AdminLogin.css";
import {Form} from "react-bootstrap";
import axios from "axios";
import {useNavigate} from "react-router";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner/LoadingSpinner";
import Footer from "../../sharedComponents/footer/Footer";
import { TextField, Button, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CookieBanner from "../../sharedComponents/CookieBanner";

function AdminLogin() {

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);
    const [benutzername, setBenutzername] = useState('');

    const [usernameEmpty, setUsernameEmpty] = useState(false);
    const [passwordEmpty, setPasswordEmpty] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false)
    const handlePasswordShow = () => setPasswordShown(!passwordShown);

    const handleSubmit = (event) => {
        event.preventDefault();
        setUsernameEmpty(benutzername === '')
        setPasswordEmpty(document.getElementById("PasswordInput").value === "")

        if(!usernameEmpty && !passwordEmpty){
            setIsLoading(true);
            logIn();
        }
    }

    const handleBenutzername = (event) => {
        setBenutzername(event.target.value);
        setUsernameEmpty(event.target.value === "")
    }

    function getErrorMessage() {
        if (showLoginErrorMessage) {
            return (
                <div className="login-error-message">
                    <p>Fehler bei der Anmeldung. <br />Prüfe deine Eingaben und probiere es erneut.</p>
                </div>
            )
        }
    }

    function logIn() {
        const form = document.getElementById("Form");

        const headers = {
            "username" : form.elements[0].value,
            "password" : document.getElementById("PasswordInput").value
        }

        axios.post('login', null, {headers}).then(result => {
            localStorage.setItem("jwtToken", result.data.access_token);
            navigate('/home/dashboard');
        }).catch(() => {
            setIsLoading(false);
            setShowLoginErrorMessage(true);
        })
    }

    function loginForm(){
        return isLoading ? 
            <LoadingSpinner/>
            :
            <>
                <Form id="Form" className="login-form-container" onSubmit={handleSubmit}>
                    <TextField 
                        error={usernameEmpty}
                        sx={{ width:'90%'}} 
                        label="Benutzername" 
                        variant="outlined" 
                        value={benutzername} 
                        onChange={handleBenutzername} 
                    />
                    <TextField 
                        error={showLoginErrorMessage}
                        sx={{ width:'90%'}}
                        id="PasswordInput" 
                        type={passwordShown ? "text" : "password"}  
                        label="Passwort" 
                        variant="outlined" 
                        onChange={e => {setPasswordEmpty(e.target.value === "")}}
                        InputProps={{
                            endAdornment: 
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handlePasswordShow}
                                >
                                {passwordShown ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>                                
                        }}
                    />
                    <Button className="login-button" variant="contained" onSubmit={handleSubmit} type="submit">
                        Anmelden
                    </Button>
                </Form>
                {getErrorMessage()}
            </>        
    }

    return (
        <>
            <div className="login-container"
                style={{backgroundImage: "url(/Webseiten-BG.png)", backgroundSize: "100% 100%"}}
            >
                <div className="login-field">
                    <h3>TicTrack</h3>
                    <h2 style={{fontWeight : "bold"}}>Zugang für</h2>
                    <h2 style={{fontWeight : "bold"}}>Administratoren</h2>
                    <h6 style={{color : "gray", textAlign: "center"}}>Willkommen zurück! Bitte loggen Sie sich ein.</h6>
                    {loginForm()}
                </div>
            </div>            
            <Footer />
            <CookieBanner/>
        </>
    )
    
}

export default AdminLogin;