import "./CreateShift.css";
import CreateShiftHeader from "./header/CreateShiftHeader";
import {useEffect, useState} from "react";
import axios from "axios";
import LoadingSpinner from "../../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import CreateShiftGenerator from "./content/generator/CreateShiftGenerator";
import StepItem from "rsuite/cjs/Steps/StepItem";
import {Steps} from "rsuite";
import CreateShiftBeginInput from "./content/startinput/CreateShiftBeginInput";
import CreateShiftStepNavigation from "./navigation/CreateShiftStepNavigation";
import CreateShiftLocationInput from "./content/locationinput/CreateShiftLocationInput";
import CreateShiftSummary from "./content/summary/CreateShiftSummary";

function CreateShift() {

    const [isLoading, setIsLoading] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [step, setStep] = useState(0);
    const [locations, setLocations] = useState(null);

    const [noSelection, setNoSelection] = useState("");


    const onChange = nextStep => {
        if (nextStep < 4) {
            changeStep(nextStep)
        }
    };

    const onNext = () => onChange(step + 1);

    useEffect(() => {
        clearSessionStorage();
        getLocations();
        getEmployeeData();
    }, [])

    function clearSessionStorage() {
        sessionStorage.removeItem("new-shift-location");
        sessionStorage.removeItem("new-shift-begin-date");
        sessionStorage.removeItem("new-shift-current-activities");
        sessionStorage.removeItem("full-completed-new-shift");
    }

    function getEmployeeData() {
        axios.get('employee/getById?id=' + sessionStorage.getItem("employeeId")).then(results => {
            setLastName(results.data.last_name);
            setFirstName(results.data.first_name);

            setIsLoading(false);
        })
    }

    function getSelectedLocation() {
        if (sessionStorage.getItem("new-shift-location") !== null) {
            return sessionStorage.getItem("new-shift-location");
        } else {
            return "Standort der Arbeit einstellen";
        }
    }

    function getSelectedDate() {
        if (sessionStorage.getItem("new-shift-begin-date") !== null) {
            return sessionStorage.getItem("new-shift-begin-date");
        } else {
            return "Arbeitstag festlegen";
        }
    }

    function createSteps() {
        let vertical = true;
        if (window.innerWidth < 1200)
            vertical = false;
        

        return (
            <Steps current={step} vertical={vertical} className="new-shift-step-container">
                <StepItem title={getSelectedLocation()}
                          description="Hier können Sie angeben, wo die Arbeitszeit stattgefunden hat." onClick={() => changeStep(0)} />
                <StepItem title={getSelectedDate()}
                          description="Legen Sie hier fest, an welchem Tag die Arbeitszeit des Mitarbeitenden begonnen hat." onClick={() => changeStep(1)}/>
                <StepItem title={"Arbeitszeit erstellen"}
                          description="Zum Abschluss können Sie die Arbeitszeit so konfigurieren, wie sie stattgefunden hat." onClick={() => changeStep(2)}/>
                <StepItem title={"Zusammenfassung"}
                          description="Hier ist eine Zusammenfassung Ihrer konfigurierten Arbeitszeit." onClick={() => changeStep(3)}/>
            </Steps>
        )
    }

    function changeStep(currentStep) {
        switch(currentStep) {
            case 0:
                if (currentStep !== step) {
                    setNoSelection("");
                    setStep(currentStep);
                }
                    
                break;
            case 1:
                if (currentStep !== step && sessionStorage.getItem("new-shift-location") !== null) {
                    setNoSelection("");
                    setStep(currentStep);
                } else {
                    setNoSelection("no-selection");
                }
                    
                break;
            case 2:
                if (currentStep !== step && sessionStorage.getItem("new-shift-begin-date") !== null) {
                    setNoSelection("");
                    setStep(currentStep);
                } else {
                    setNoSelection("no-selection");
                }
                break;
            
            case 3:
                if (currentStep !== step && sessionStorage.getItem("full-completed-new-shift") !== null) {
                    setNoSelection("");
                    setStep(currentStep);
                } else {
                    setNoSelection("no-selection");
                }
                break;
            default:
                break;
        }
    }

    function loadCurrentStepContent() {
        switch (step) {
            case 0:
                return (<CreateShiftLocationInput locations={locations} noSelection={noSelection} setNoSelection={setNoSelection} />);
            case 1:
                return (<CreateShiftBeginInput noSelection={noSelection} setNoSelection={setNoSelection} />);
            case 2:
                return (<CreateShiftGenerator noSelection={noSelection} setNoSelection={setNoSelection} />)
            case 3:
                return (<CreateShiftSummary firstName={firstName} lastName={lastName} />)
            default:
                break;
        }
    }

    function getLocations() {
        axios.get('location/getActive').then(results => {
            setLocations(results.data);
            setIsLoading(false);
        })
    }

    function isStepNavigationAvailable() {
        return step !== 3;
    }

    return isLoading ?
        <LoadingSpinner/>
        :
        <>
            <div className="create-shift-header-container">
                <CreateShiftHeader firstName={firstName} lastName={lastName}/>
            </div>
            <div className="create-shift-content-container">
                {createSteps()}
                <div className="create-shift-begin-input-container">
                    {loadCurrentStepContent()}
                    {isStepNavigationAvailable() && <div className="create-shift-begin-input-navigation-container">
                        <CreateShiftStepNavigation step={step} nextOnClick={onNext}/>
                    </div>}
                </div>
            </div>
        </>
}

export default CreateShift;