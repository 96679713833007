import "./LiveList.css";
import {useEffect, useState} from "react";
import axios from "axios";
import LiveItem from "../liveItem/LiveItem";
import LoadingSpinner from "../../../../../sharedComponents/LoadingSpinner/LoadingSpinner";
import Logout from "../../../../../sharedComponents/Logout";

function LiveList() {

    const [isLoading, setIsLoading] = useState(true);

    const [liveItems, setLiveItems] = useState(null);

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("jwtToken");
        axios.get('live').then(results => {
            setLiveItems(results.data);
            setIsLoading(false);
        }).catch(() => { Logout()})
    }, [])

    function createLiveItems() {
        let currentLiveItems = [];

        liveItems.map((liveItem, index) => {
            if (liveItem.active_employees_count > 0) {
                currentLiveItems.push(
                    <LiveItem key={index} locationName={liveItem.location_name} count={liveItem.active_employees_count} employees={liveItem.active_employees}/>
                )
            }
        })

        return currentLiveItems;
    }

    return isLoading ?
        <LoadingSpinner/>
        :
        <div className="live-list-container">
            {/*<Button variant='outlined' onClick={() => localStorage.setItem("jwtToken", '000')}>change jwtToken</Button>           to test logout if Token invalid   */}
            {createLiveItems()}
        </div>
        

}

export default LiveList;